import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';

export const LaunchPad: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className='launchpad'>
      <p className='new-title'>Never want to miss a sale?</p>
      <p className='new-desc'>Want to launch your project on BSCS</p>
      <div onClick={() => navigate('/apply-launch')} className='new-btn'>Apply to Launchpad</div>
    </div>
  )
}