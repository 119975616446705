/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { Progress, message, Steps, Checkbox, Radio, Modal, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useContract } from "../../hooks/useContract";
import { ADDRESS_BSCS_ADDRESS } from "../../constants";
import abiBSCS from "../../abi/abiBSCS.json";
import web3 from "web3";
import Action from "./action";
import abiStake from "./utils/abiStake.json"
// import { SC_STAKE_BSCS } from "../../constants";
import { isMobile } from "react-device-detect";
import { useHookStaked } from './storeStaked'
import { useAccount, useChainId } from "wagmi";
import { useParams } from "react-router-dom";

const provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
declare const window: Window & typeof globalThis & { ethereum: any };
const UnStake = (props: any) => {

    const [state, actions] = useHookStaked()
    const { address } = useAccount();
    const { poolContract } = useParams();

    const chainId = useChainId()
    let account = address
    const bscsContract: any = useContract(ADDRESS_BSCS_ADDRESS, abiBSCS);
    const contractStake: any = useContract(poolContract, abiStake);
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [bnbBalance, setBnbBalance] = useState(0);
    const [current, setCurrent] = useState(0);
    const [amountBscs, setAmountBscs] = useState<any>(0);
    const [amountBscsPool, setAmountBscsPool] = useState<any>(0);
    const [hashUnStake, setHashUnStake]: any = useState('');
    const [isCheck, setIsCheck] = useState(false);
    const { Step } = Steps;
    const [showNext, setShowNext] = useState(false);
    const [amountValue, setAmountValue] = useState<any>();
    const [unstakeAmount, setUnstakeAmount] = useState(0);
    const [isSuccess, setIsSuccess]: any = useState(false)

    const [childData, setchildData]: any = useState(false)

    const formatNumber = (x: any, max: any) => {
        if (x) {
            return x.toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: max,
            });
        } else return 0;
    };

    const checkFlag = async () => {
        try {
            await contractStake.userMap(account).then((res: any) => {
                if (res) {
                    setUnstakeAmount(res.inUnStakeAmount / 1e18)
                } else {
                    setUnstakeAmount(0)
                }
            })
                .catch((err: any) => {
                    console.log('err', err)
                })
        } catch (error) {
            console.log('error', error)
        }
    }

    useLayoutEffect(() => {
        if (contractStake) {
            checkFlag()
        }

    }, [account, contractStake, childData, poolContract])



    const next = () => {


        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const onChange = (e: any) => {
        setIsCheck(e.target.checked)
    };

    const onChangeValue = (event: any) => {
        let x = event.target.value.replace(/[^0-9.]/g, "");
        setAmountValue(x)
    };

    const steps = [
        {
            title: '1',
            content: 'First-content',
        },
        {
            title: '2',
            content: '2-content',
        },
        {
            title: '3',
            content: '3-content',
        },
        {
            title: '4',
            content: '4-content',
        },
        {
            title: '5',
            content: 'Last-content',
        },
    ];

    const renderImg1 = () => {
        return (
            <img className="img-step-stake" src="/images/img/unstake1.png" alt="" />
        )
    }
    const renderImg2 = () => {
        if (current < 1) {
            return <img className="img-step-stake" src="/images/img/unstake2.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/img/unstake2-ac.png" alt="" />
        }
    }
    const renderImg3 = () => {
        if (current < 2) {
            return <img className="img-step-stake" src="/images/img/unstake3.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/img/unstake3-ac.png" alt="" />
        }
    }
    const renderImg4 = () => {
        if (current < 3) {
            return <img className="img-step-stake" src="/images/img/unstake4.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/img/unstake4-ac.png" alt="" />
        }
    }
    const renderImg5 = () => {
        if (current < 4) {
            return <img className="img-step-stake" src="/images/img/unstake5.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/img/unstake5-ac.png" alt="" />
        }
    }

    const renderText1 = () => {
        return <span className="text-ac">Warning</span>
    }
    const renderText2 = () => {
        if (current < 1) {
            return "Checkpoints"
        } else {
            return <span className="text-ac">Checkpoints</span>
        }

    }

    const renderText3 = () => {
        if (current < 2) {
            return "Amount to Unstake"
        } else {
            return <span className="text-ac">Amount to Unstake</span>
        }

    }
    const renderText4 = () => {
        if (current < 3) {
            return "Initialize Unstake"
        } else {
            return <span className="text-ac">Initialize Unstake</span>
        }

    }
    const renderText5 = () => {
        if (current < 4) {
            return "Confirmation"
        } else {
            return <span className="text-ac">Confirmation</span>
        }

    }

    const [isModalOpen, setIsModalOpen] = useState(false);



    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };

    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };

    const getBscs = async () => {
        if (bscsContract) {
            const amount = await bscsContract.balanceOf(account);
            setAmountBscs(amount.toString() / 1e18);
        }
    };

    useLayoutEffect(() => {
        if (account) {
            getBscs();
        } else {
            setAmountBscs(0)
        }
    }, [account, childData, bscsContract, poolContract]);

    const getBscsPool = async () => {
        if (contractStake) {
            const amount = await contractStake.balanceOf(account);
            let temp = Math.floor((Number(amount) / 1e18) * 100000) / 100000;
            setAmountBscsPool(Number(temp));
        }
    };

    useLayoutEffect(() => {
        if (account) {
            getBscsPool();
        } else {
            setAmountBscsPool(0)
        }
    }, [account, childData, bscsContract, poolContract]);

    useLayoutEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance: any = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.floor(parseFloat(_balance) * 100000) / 100000;
                    setBnbBalance(bnbBl);
                }
            });
        } else {
            setBnbBalance(0)
        }
    }, [account, chainId, childData, poolContract]);

    useLayoutEffect(() => {
        // if (account && amountBscs > 0 && bnbBalance > 0 && isCheck && unstakeAmount <= 0 && amountBscsPool) {
        if (account && bnbBalance > 0 && isCheck && unstakeAmount <= 0 && amountBscsPool) {
            if (current === 2) {
                if (amountValue <= amountBscsPool) {
                    setShowNext(true)
                } else {
                    setShowNext(false)
                }
            } else {
                setShowNext(true)
            }

        } else {
            setShowNext(false)
        }

    }, [account, bnbBalance, isCheck, unstakeAmount, amountValue, current, childData, bscsContract, contractStake, poolContract])

    const handleMax = () => {
        setAmountValue(amountBscsPool)
    }

    const handleUnstake = async () => {
        try {
            setIsModalOpen(true);
            let amount = web3.utils.toWei(amountValue.toString(), "ether");
            await contractStake.unstake(amount)
                .then(async (res: any) => {
                    if (Number(amountValue) > 50000) {
                        const timestamp = new Date().getTime();
                        const date1 = new Date(timestamp);
                        let timeStake = date1.toLocaleString('en-GB')
                        let paramNoti = {
                            projectName: "BSCS",
                            amount: amountValue,
                            actionType: 2,
                            userAddress: account,
                            tokenName: "BSCS",
                            actionTime: timeStake,
                            hash: res?.hash,
                            status: "Waiting"
                        }
                        await actions.callNoti(paramNoti)
                    }

                    let params = {
                        ownerAddress: account,
                        poolContract: poolContract
                    }

                    let resapi = await actions.updateStaked(params)
                    if (resapi.data.data) {
                        res.wait()
                            .then(async (resWait: any) => {
                                if (resWait?.status === 1) {
                                    const timestamp = new Date().getTime();
                                    const date1 = new Date(timestamp);
                                    let timeStake = date1.toLocaleString('en-GB')
                                    let paramNoti = {
                                        projectName: "BSCS",
                                        amount: amountValue,
                                        actionType: 2,
                                        userAddress: account,
                                        tokenName: "BSCS",
                                        actionTime: timeStake,
                                        hash: resWait?.transactionHash,
                                        status: "Success"
                                    }
                                    await actions.callNoti(paramNoti)

                                    setHashUnStake(resWait.transactionHash)
                                    message.success({
                                        type: "success",
                                        content: "UnStake successfully",
                                        className: "custom-class",
                                        duration: 3,
                                    });
                                    props.setIsCheckAction(!props.isCheckAction)

                                    setIsModalOpen(false);
                                    setCurrent(current + 1);
                                    setIsSuccess(!isSuccess)
                                    //-----

                                } else {
                                    message.error({
                                        type: "error",
                                        content: "Unknown error (status hash fail)",
                                        className: "custom-class",
                                        duration: 3,
                                    });
                                    setIsModalOpen(false);
                                }

                            })
                            .catch((err: any) => {
                                message.error({
                                    type: "error",
                                    content: "Unknown error",
                                    className: "custom-class",
                                    duration: 3,
                                });
                                setIsModalOpen(false);
                            })
                    } else {
                        message.error({
                            type: "error",
                            content: "API update status stake fail",
                            className: "custom-class",
                            duration: 3,
                        });
                        setIsModalOpen(false);
                    }


                })
                .catch((err: any) => {
                    console.log('err', err)
                    message.error({
                        type: "error",
                        content: err.reason || err?.message,
                        className: "custom-class",
                        duration: 3,
                    });
                    setIsModalOpen(false);
                })
        } catch (error: any) {
            console.log('err catch-->', error)
            message.error({
                type: "error",
                content: error,
                className: "custom-class",
                duration: 3,
            });
            setIsModalOpen(false);

        }
    }

    const callbackFunction = (data: any) => {
        console.log('data', data)
        setchildData(data)
    };

    const onChangeAll = () => {
        setIsCheck(!isCheck)
    };

    return (
        <div className="wrap-stake">
            <div className="stake">
                <div className="wrap-all">

                    <div className="stake-your-token">Unstake your BSCS</div>
                    <div className="content-stake">

                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Steps current={current}>
                            <Step
                                title={renderText1()}
                                icon={renderImg1()}
                            />
                            <Step
                                title={renderText2()}
                                icon={renderImg2()}
                            />
                            <Step
                                title={renderText3()}
                                icon={renderImg3()}
                            />
                            <Step
                                title={renderText4()}
                                icon={renderImg4()}
                            />
                            <Step
                                title={renderText5()}
                                icon={renderImg5()}
                            />
                        </Steps>


                        {/* <div className="steps-content">{steps[current].content}</div> */}
                        {current === 0 ? (
                            <div className="content-step1">
                                <div className="unstake-warning">
                                    <div className="img-warning">
                                        <img src="/images/img/unstake-warning.png" alt="" />
                                    </div>
                                    <div className="content-warning1">
                                        After Unstaking, you must wait 7 days before you can withdraw your BSCS and rewards.
                                    </div>
                                    <div className="content-warning2">
                                        The amount of tokens you Unstake will not count towards your tier level for upcoming
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {current === 1 ? (
                                    <div className="content-step2">
                                        <div className="title">Checkpoints</div>
                                        <div className="des">The following conditions must be met to proceed:</div>
                                        <div className="item-step1">
                                            <div className="card-item">
                                                <div className={`${account ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/img/check-before.png" alt="" />
                                                    <img src="/images/img/check-after.png" alt="" />
                                                </div>
                                                <div className="title-card">Connected with MetaMask</div>
                                                <div className="content-card">If not connected, click the "Connect Wallet" button in the top right corner</div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${amountBscsPool > 0 ? 'check-card acive' : 'check-card'}`}>
                                                    {/* <div className="check-card acive"> */}
                                                    <img src="/images/img/check-before.png" alt="" />
                                                    <img src="/images/img/check-after.png" alt="" />

                                                </div>
                                                <div className="title-card">Have an active BSCS stake</div>
                                                <div className="content-card">You currently have {formatNumber(amountBscsPool, 4)} BSCS staked</div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${bnbBalance > 0 ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/img/check-before.png" alt="" />
                                                    <img src="/images/img/check-after.png" alt="" />
                                                </div>
                                                <div className="title-card">BNB available in wallet</div>
                                                <div className="content-card">BNB is required to pay transaction fees on the BNB Smart Chain network.<br />BNB Balance: {formatNumber(bnbBalance, 4)}</div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${unstakeAmount === 0 && account ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/img/check-before.png" alt="" />
                                                    <img src="/images/img/check-after.png" alt="" />
                                                </div>
                                                <div className="title-card">Eligible to initiate unstake</div>
                                                <div className="content-card">You cannot unstake if you already have an active BSCS unstake/withdrawal request</div>
                                            </div>
                                        </div>
                                        <div className="check-real" onClick={onChangeAll}>
                                            <Checkbox checked={isCheck} onChange={onChange} /> <span>I have read the <a target="_blank" href="/TermOfUse" className="terms">Terms and Conditions</a></span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {current === 2 ? (
                                            <div className="content-step3">
                                                <div className="title title-unstake">Please enter the amount of BSCS you want to unstake</div>

                                                <div className="content-input unstake">
                                                    <div className="amount-stake-title">
                                                        <div className="title-left">Amount</div>
                                                        {/* <div className="title-right"><span>50,000</span> BSCS available</div> */}
                                                    </div>
                                                    <div className="input-amount">
                                                        <input type="text" className="amount-stake" placeholder="0" onChange={onChangeValue} value={amountValue} min="0" max={amountBscsPool} />
                                                        {/* <input className="amount-stake" placeholder="0.0" onChange={onChangeValue} value={amountValue} type="text" /> */}
                                                        <div className="posi-unstake">
                                                            <span className="palce-token">BSCS</span>
                                                            <button className="btn-max-stake" onClick={handleMax}>Max</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {current === 3 ? (
                                                    <div className="content-step4">
                                                        <div className="title">Confirm Unstaking Process</div>
                                                        <div className="confirm-content">
                                                            <div className="cf-content1">In this step, you initiate the unstaking process.</div>
                                                            <div className="cf-content1">After a 7 day waiting period, you will be allowed to withdraw your BSCS.</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {current === 4 ? (
                                                            <div className="content-step5">
                                                                <div className="title">Confirmation</div>
                                                                <div className="des">You have initiated the unstaking process and started the 7 day timer.</div>
                                                                <div className="success-content">
                                                                    <div className="img-success">
                                                                        <img src="/images/img/stake-success.png" alt="" />
                                                                    </div>
                                                                    <div className="mid-content">Confirmed</div>
                                                                    <div className="mid-content1">You have initiated the unstaking process and started the 7 day timer.</div>
                                                                    <div className="mid-content2">If desired, you may check BNB Smart Chain to confirm the transaction.</div>
                                                                    {isMobile ? (
                                                                        <a className="mid-content3" target="_blank" rel="noreferrer" href={`https://bscscan.com/tx/${hashUnStake}`}>
                                                                            {hashUnStake.slice(0, 10)}...{hashUnStake.slice(-10)}
                                                                        </a>
                                                                    ) : (
                                                                        <a className="mid-content3" target="_blank" rel="noreferrer" href={`https://bscscan.com/tx/${hashUnStake}`}>{hashUnStake}</a>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        ) : ('')}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>
                <Action isSuccess={isSuccess} tabBox={props.tabBox} parentCallback={callbackFunction} />

            </div>
            <div className="steps-action">
                {current > 0 && current !== steps.length - 1 && (
                    <button className="btn-control" onClick={() => prev()}> Previous</button>
                )}
                {current === 0 ?
                    <>
                        <button className="btn-control" onClick={() => next()}> Next</button>
                    </>
                    :
                    <>
                        {current < steps.length - 1 && (
                            <>
                                {showNext ? (
                                    <>
                                        {current === 3 ?
                                            <>
                                                <button className="btn-control" onClick={handleUnstake}> Next</button>
                                            </>
                                            :
                                            <>
                                                <button className="btn-control" onClick={() => next()}> Next</button>
                                            </>
                                        }
                                    </>
                                ) : (
                                    <button className="btn-control dis"> Next</button>
                                )}
                            </>
                        )}
                    </>
                }
                {current === steps.length - 1 && (
                    <button className="btn-control" onClick={() => window.location.reload()}> Done</button>

                )}
            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title="Unstaking"
                visible={isModalOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="modal-text1">Your request is being processed. Please wait...</p>
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
                </p>

            </Modal>
        </div>


    )
}

export default UnStake;