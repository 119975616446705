import React, { useEffect } from "react";
import styled from "styled-components";
import MakesOur from "pages/Home/MakesOur";
import "./style.scss"

const IncubatorPage = () => {
    return (
        <>
            <Wrapper>
                <Fullwidth>
                    <Container>
                       <div className="wrap-incubaror">
                            <div className="title-page">
                                BSCS <span>Incubator</span>
                            </div>
                            <div className="desc-page">
                                An intensive accelerator program that empowers crypto startups to shape Web3 through combined expertise in AI, blockchain, and DeFi, with a focus on collaboration, L1 & L2 solutions, and Web3 engagement.
                            </div>
                            <div className="row-incubator">
                                <div className="title">
                                    What are we looking for?
                                </div>
                                <div className="wrap-colum">
                                    <div className="columns">
                                        <div className="colum w-3">
                                            <div className="guide-wrap">
                                                <div className="box-img">
                                                    <img src="./images/incu-1.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Project Validation & Advisory
                                                </div>
                                                <div className="desc">
                                                    Rigorousv project evaluation and strategic advisory services from industry experts
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-3">
                                            <div className="guide-wrap">
                                                <div className="box-img">
                                                    <img src="./images/incu-2.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Fundraising & Tokenomics
                                                </div>
                                                <div className="desc">
                                                    Guidance on developing tokenomics and navigating token-based fundraising through public sales, launchpads, and exchanges
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-3">
                                            <div className="guide-wrap">
                                                <div className="box-img">
                                                    <img src="./images/incu-3.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Community Building & Marketing
                                                </div>
                                                <div className="desc">
                                                    Guidance on developing tokenomics and navigating token-based fundraising through public sales, launchpads, and exchanges
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-3">
                                            <div className="guide-wrap">
                                                <div className="box-img">
                                                    <img src="./images/incu-4.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Investor & Partner Connections
                                                </div>
                                                <div className="desc">
                                                    Facilitating connections with crypto investors, VC firms, and essential partners for development, legal, and marketing needs
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-incubator">
                                <div className="title">
                                    What BSCS offers?
                                </div>
                                <div className="wrap-colum">
                                    <div className="columns">
                                        <div className="colum w-3">
                                            <div className="guide-wrap">
                                                <div className="box-img">
                                                    <img src="./images/incu-5.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Project Validation & Advisory
                                                </div>
                                                <div className="desc">
                                                    Rigorousv project evaluation and strategic advisory services from industry experts
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-3">
                                            <div className="guide-wrap">
                                                <div className="box-img">
                                                    <img src="./images/incu-5.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Fundraising & Tokenomics
                                                </div>
                                                <div className="desc">
                                                    Guidance on developing tokenomics and navigating token-based fundraising through public sales, launchpads, and exchanges
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-3">
                                            <div className="guide-wrap">
                                                <div className="box-img">
                                                    <img src="./images/incu-5.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Community Building & Marketing
                                                </div>
                                                <div className="desc">
                                                    Guidance on developing tokenomics and navigating token-based fundraising through public sales, launchpads, and exchanges
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-3">
                                            <div className="guide-wrap">
                                                <div className="box-img">
                                                    <img src="./images/incu-5.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    Investor & Partner Connections
                                                </div>
                                                <div className="desc">
                                                    Facilitating connections with crypto investors, VC firms, and essential partners for development, legal, and marketing needs
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MakesOur />
                       </div>
                    </Container>
                </Fullwidth>
            </Wrapper>
        </>
    )
}
export default IncubatorPage

const Wrapper = styled.div`
    padding-top: 92px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;

    @media (max-width: 768px) {
        padding-top: 80px;
    }
`;
const Fullwidth = styled.div`
    width: 100%;
`;
const Container = styled.div`
    width: 1160px;
    padding: 0 10px;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100%;
    }
`;