/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import web3 from "web3";

import { Modal, Dropdown, message, Skeleton, Select } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useConnectWallet, useActiveWeb3React } from "../../hooks";
import {
    NEED_A_PLACEHOLDER,
    WALLET_LIST,
    ICON_BNB_URL,
    ICON_ETH_URL,
    ICON_POLY_URL,
    ICON_OKEX,
    ICON_ARB,
    ICON_AVAX,
    ICON_OP,
    ICON_DOGE,
    ICON_FMT,
    ICON_BASE,
    ICON_OPBNB_URL,
    ICON_ZK,
    ICON_BLAST
} from "../../constants/index";
import { useContract } from "../../hooks/useContract";
import { ADDRESS_BSCS_ADDRESS } from "../../constants";
import abiBSCS from "../../abi/abiBSCS.json";
import WalletItem from "./WalletItem";
import { useWeb3React } from "@web3-react/core";
import Launchpool from "../SVG/Launchpool";
import Rank from "../SVG/Rank";
import Rocket from "../SVG/Rocket";
import MyNFT from "../SVG/MyNFT";
import "./style.css";
import { useHookPrice } from "../common/Store";
import switchNetworkChain from "../../utils/walletChain";
import { MAPPING_CHAINID } from "../../constants";
import { useConnectWalletSol } from "../../pages/Solana/index";
import { useConnectWalletApt } from "../../pages/APT/ConnectAPT/index";
import { PublicKey, LAMPORTS_PER_SOL, Connection, Keypair, clusterApiUrl } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const SID = require("@siddomains/sidjs").default;
const SIDfunctions = require("@siddomains/sidjs");

declare const window: Window & typeof globalThis & { ethereum: any };
const Web3 = new web3(window.ethereum);
const ens = Web3.eth.ens;

const ConnectWallet = (props: any) => {
    const [state1, actions1] = useHookPrice();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [domand, setDomand]: any = useState();
    const [isWaiting, setIsWaiting] = useState(true);
    const { walletLogin, walletLogout } = useConnectWallet();
    const {
        handleConnectSol,
        handleDisconnectSol,
        curentAddress,
        balance,
        loadBalance,
        accountEllipsisMobieSol,
        accountEllipsisSol,
        menuSol,
    } = useConnectWalletSol();

    const {
        connectAptos,
        disConnectAptos,
        menuApt,
        accountEllipsisApt,
        isAcc,
        isBalanceAPT,
    } = useConnectWalletApt();

    const { Option } = Select;
    let navigate = useNavigate();
    const [showDetails, setShowDetail] = useState(false);
    const walletItemClass = "w-1/3 flex-auto mb-0.5 p-0.5 last:bg-transparent";
    const { account } = useActiveWeb3React();
    const { chainId }: any = useWeb3React();
    const bscsContract: any = useContract(ADDRESS_BSCS_ADDRESS, abiBSCS);
    const context = useWeb3React();
    const { active } = context;
    const provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
    const [amountBscs, setAmountBscs] = useState(0);

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [bnbBalance, setBnbBalance] = useState(0);

    //--------------------------------------------
    let sid;
    const getDomainBNB = async (address: any) => {
        const rpc =
            "https://bsc-mainnet.nodereal.io/v1/d0c3ef1cdb0247f4b6fae228aa76c8b8";
        const provider = new web3.providers.HttpProvider(rpc);
        const chainId = "56";
        sid = new SID({
            provider,
            sidAddress: SIDfunctions.getSidAddress(chainId),
        });

        await sid
            .getName(address)
            .then((res: any) => {
                if (res) {
                    setIsWaiting(false);
                    setDomand(res.name);
                }
            })
            .catch((err: any) => {
                setIsWaiting(false);
            });
    };
    const getDomainARB = async (address: any) => {
        const rpc = "https://arb1.arbitrum.io/rpc";
        const provider = new web3.providers.HttpProvider(rpc);
        const chainId = 42161; //Arbitrum One chain id
        sid = new SID({
            provider,
            sidAddress: SIDfunctions.getSidAddress(chainId),
        });

        await sid
            .getName(address)
            .then((res: any) => {
                if (res) {
                    setIsWaiting(false);
                    setDomand(res.name);
                }
            })
            .catch((err: any) => {
                setIsWaiting(false);
                setDomand(null);
            });
    };
    const getDomainETH = async (address: any) => {
        const rpc = "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
        const provider = new web3.providers.HttpProvider(rpc);
        const chainId = "1";
        sid = new SID({
            provider,
            sidAddress: SIDfunctions.getSidAddress(chainId),
        });

        await sid
            .getName(address)
            .then((res: any) => {
                if (res) {
                    setIsWaiting(false);
                    setDomand(res.name);
                }
            })
            .catch((err: any) => {
                setIsWaiting(false);
            });
    };

    let chain = localStorage.getItem("chain");
    useEffect(() => {
        if (chain === "bep") {
            getDomainBNB(account);
        } else if (chain === "arb") {
            getDomainARB(account);
        } else if (chain === "erc") {
            getDomainETH(account);
        } else {
            setDomand(null);
            setIsWaiting(false);
            setDomand(null);
        }
    }, [domand, account, chain, chainId]);

    //---------------------------------------------------------------------
    const [initialValue, setInitialValue]: any = useState("bep");
    function handleChain(value: any, e: any) {
        if (account !== undefined || curentAddress || isAcc) {
            if (value === "apt") {
                actions1.updateChain("apt");
                setInitialValue(value); /*  */
                localStorage.setItem("chain", value);
                handleDisconnect();
                handleDisconnectSol();
                handleConnectPontem();
            } else if (value === "tron") {
                window.localStorage.removeItem("accountStatus");
                window.localStorage.removeItem("connectorId");
                window.localStorage.removeItem("walletconnect");
                window.localStorage.removeItem("currentAddress");
                actions1.updateChain("tron");
                setInitialValue(value);
                localStorage.setItem("chain", value);
            } else if (value === "polka") {
                window.localStorage.removeItem("accountStatus");
                window.localStorage.removeItem("connectorId");
                window.localStorage.removeItem("walletconnect");
                window.localStorage.removeItem("currentAddress");
                actions1.updateChain("polka");
                setInitialValue(value);
                localStorage.setItem("chain", value);
            } else if (value === "sol") {
                actions1.updateChain("sol");
                setInitialValue(value);
                localStorage.setItem("chain", value);
                handleDisconnect();
                disConnectAptos();
                // handleConnectPhantom();
            } else {
                handleConnect("1");
                handleDisconnectSol();
                disConnectAptos();

                const _chainId = MAPPING_CHAINID[value];
                switchNetworkChain(_chainId, value).then((res) => {
                    actions1.updateChain(value);
                    if (res !== false) {
                        setInitialValue(value);
                        actions1.updateChain(value);
                    } else {
                        setInitialValue(localStorage.getItem("chain"));
                        actions1.updateChain(localStorage.getItem("chain"));
                    }
                });
            }
        } else {
            if (value === "apt") {
                handleConnectPontem();
            } else if (value === "sol") {
                // handleConnectPhantom();
            } else {
                handleConnect("1");
            }

            localStorage.setItem("chain", value);
            setInitialValue(value);
            actions1.updateChain(value);
            // todo
        }
    }

    useEffect(() => {
        setInitialValue(localStorage.getItem("chain"));
    }, [localStorage.getItem("chain")]);

    useEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance: any = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
            });
        }
    }, [account, chainId]);

    const getBscs = async () => {
        if (bscsContract) {
            const amount = await bscsContract.balanceOf(account);
            setAmountBscs(amount.toString() / 1e18);
        }
    };

    useEffect(() => {
        if (account) {
            getBscs();
        }
    }, [account]);

    // --- ACTION IN MODAL ---
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setShowDetail(true);
    };

    const gotoIdoPool = () => {
        navigate("/IDOPools");
    };
    const gotoLaunchPool = () => {
        navigate("/launch-pool");
    };
    const gotoMyNft = () => {
        navigate("/MyArtwork");
    };
    const gotoMyAccount = () => {
        navigate("/my-account");
    };

    const accountEllipsis = active ? (
        domand !== null ? (
            <>
                {domand?.length > 15
                    ? `${domand?.substring(0, 4)}...${domand?.substring(
                        domand.length - 6
                    )}`
                    : domand}
            </>
        ) : (
            `${account?.substring(0, 4)}...${account?.substring(account.length - 4)}`
        )
    ) : (
        "Connect Wallet "
    );

    const accountEllipsisRow = active
        ? `${account?.substring(0, 8)}...${account?.substring(account.length - 8)}`
        : "Connect Wallet ";

    // ---- HANDLE DISCONNECT ----
    const handleDisconnect = () => {
        walletLogout();
        setIsModalVisible(false);
    };

    // ---- HANDLE CONNECT ----

    const handleConnect = async (connectorId: string) => {
        try {
            if (connectorId === "bsc") {
                if (!window.BinanceChain) {
                    setShowDetail(!showDetails);
                    setIsModalVisible(true);
                } else {
                    const rs = await walletLogin(connectorId);
                    setIsModalVisible(false);
                    setShowDetail(true);
                }
            } else {
                const rs = await walletLogin(connectorId);
                setIsModalVisible(false);
                setShowDetail(true);
            }
        } catch (e) {
            console.error("Login failed------", e);
        }
    };

    const handleConnectPontem = async () => {
        if (initialValue === "apt") {
            try {
                const rs = await connectAptos();
                setIsModalVisible(false);
            } catch (e) {
                console.error("Login failed");
            }
        } else {
            const rs = await connectAptos();
            setIsModalVisible(false);
            localStorage.setItem("chain", "apt");
            setInitialValue("apt");
        }
    };

    // const handleConnectPhantom = async () => {
    //     if (initialValue === "sol") {
    //         try {
    //             const rs = await handleConnectSol();
    //             setIsModalVisible(false);
    //         } catch (e) {
    //             console.error("Login failed");
    //         }
    //     } else {
    //         const rs = await handleConnectSol();
    //         setIsModalVisible(false);
    //         localStorage.setItem("chain", "sol");
    //         setInitialValue("sol");
    //     }
    // };

    // var myClasses = document.getElementsByClassName("wallet-adapter-button wallet-adapter-button-trigger");
    // console.log('myClasses', myClasses)
    // useEffect(() => {
    //     for (var i = 0; i < myClasses.length; i++) {
    //         myClasses[i].innerHTML = "Connect Wallet";

    //     }
    // }, [myClasses])

    const icon_network = (chainId: any) => {
        switch (chainId) {
            case 1:
                return ICON_ETH_URL;
                break;
            case 137:
                return ICON_POLY_URL;
                break;
            case 43114:
                return ICON_AVAX;
                break;
            case 10:
                return ICON_OP;
                break;
            // case 66:
            //   return ICON_OKEX;
            //   break;
            case 2000:
                return ICON_DOGE;
                break;
            case 250:
                return ICON_FMT;
                break;
            case 42161:
                return ICON_ARB;
                break;
            case 204:
                return ICON_OPBNB_URL;
                break;
            case 8453:
                return ICON_BASE;
                break;
            case 13371:
                return ICON_ZK;
                break;
            case 81457:
                return ICON_BLAST;
                break;
            default:
                return ICON_BNB_URL;
        }
    };

    const token_network = (chainId: any) => {
        switch (chainId) {
            case 1:
                return "ETH";
                break;
            case 137:
                return "MATIC";
                break;
            // case 66:
            //   return "OKT";
            //   break;
            case 42161:
                return "ETH";
                break;
            case 204:
                return "BNB";
                break;
            case 8453:
                return "ETH";
                break;
            case 43114:
                return "AVAX";
                break;
            case 10:
                return "OP";
                break;
            case 2000:
                return "DOGE";
                break;
            case 250:
                return "FMT";
                break;
            case 13371:
                return "IMX";
                break;
            case 81457:
                return "ETH";
                break;
            default:
                return "BNB";
        }
    };
    const menu = (
        <>
            <div className="main-dropdown-wl">
                <div className="top-dropdown">
                    <div className="box-balance">
                        <div className="icon">
                            <img src="/images/icon-bscs-menu.svg" alt="" />
                        </div>
                        <div className="balance">
                            <span className="name-bl">BSCS Balance</span>
                            <span className="number-bl">
                                {amountBscs.toFixed(2) ? amountBscs.toFixed(2) : "0.00"} BSCS
                            </span>
                        </div>
                    </div>
                    <div className="box-address">
                        <div className="address-name">Wallet address :</div>
                        <div className="account-name">
                            {accountEllipsisRow}
                            <CopyToClipboard
                                text={`${account}`}
                                onCopy={() => {
                                    message.success("Copied", 0.4);
                                }}
                            >
                                <span className="img-copy">
                                    <img src="/images/imgido/copy.png" alt="" />
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="bottom-dropdown">
                    <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoMyAccount()}>
                            <Rank />
                            <span className="name-rs">My Account</span>
                        </div>
                    </div>
                    {/* <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoIdoPool()}>
                            <Launchpool />
                            <span className="name-rs">IDO Pool</span>
                        </div>
                    </div> */}
                    <div className="item-rs" onClick={() => gotoLaunchPool()}>
                        <div className="icon-rs">
                            <Rocket />
                            <span className="name-rs">Launch Pool</span>
                        </div>
                    </div>
                    <div className="item-rs" onClick={() => gotoMyNft()}>
                        <div className="icon-rs">
                            <MyNFT />
                            <span className="name-rs">My NFT</span>
                        </div>
                    </div>
                </div>
                <div className="footer-dropdown">
                    <button type="button" className="btn-dis" onClick={handleDisconnect}>
                        Disconnect Wallet
                    </button>
                </div>
            </div>
        </>
    );

    return (
        <>
            {isMobile ? (
                <div className="filter-chain mobile">
                    <Select
                        value={initialValue}
                        onChange={handleChain}
                        style={{ width: 175, marginRight: 10 }}
                        placeholder="Select network"
                    >
                        <>
                            <Option key="bep" value="bep">
                                <img src="/images/bnb.png" alt="" />
                                BNB Chain
                            </Option>
                            <Option key="erc" value="erc">
                                <img src="/images/ethe.png" alt="" />
                                Ethereum
                            </Option>
                            <Option key="blast" value="blast">
                                <img src="/images/blast.jpeg" alt="" />
                                Blast
                            </Option>
                            <Option key="opbnb" value="opbnb">
                                <img src="/images/bnb.png" alt="" />
                                opBNB
                            </Option>
                            <Option key="avax" value="avax">
                                <img src="/images/avax.png" alt="" /> Avax Chain
                            </Option>
                            <Option key="op" value="op">
                                <img src="/images/op.png" alt="" /> Optimism
                            </Option>
                            <Option key="poly" value="poly">
                                <img src="/images/poly.png" alt="" /> Polygon
                            </Option>
                            <Option key="zkevm" value="zkevm">
                                <img src="/images/zk.jpeg" alt="" />Immutable zkEVM
                            </Option>
                            {/* <Option key="okc" value="okc">
                <img src="/images/okx.png" alt="" /> OKC Chain
              </Option> */}
                            <Option key="doge" value="doge">
                                <img src="/images/doge.png" alt="" /> Doge Chain
                            </Option>
                            <Option key="fmt" value="fmt">
                                <img src="/images/fmt.png" alt="" /> Fantom
                            </Option>
                            <Option key="arb" value="arb">
                                <img src="/images/arb.png" alt="" /> Arbitrum
                            </Option>
                            <Option key="base" value="base">
                                <img src="/images/base.png" alt="" /> Base
                            </Option>
                            <Option key="sol" value="sol">
                                <img src="/images/sol.png" alt="" /> Solana
                            </Option>
                            {/* <Option disabled={true} key="tron" value="tron">
                <img src="/images/tron.png" alt="" /> Tron
                <span className="net-soon-apt">Soon</span>
              </Option> */}

                            {/* <Option disabled={true} key="apt" value="apt">
                <img src="/images/apt.png" alt="" /> APT
                <span className="net-soon-apt">Soon</span>
              </Option> */}
                            <Option key="apt" value="apt">
                                <img src="/images/apt.png" alt="" /> APT
                            </Option>
                        </>
                    </Select>
                </div>
            ) : (
                <div className="filter-chain">
                    <Select
                        value={initialValue}
                        onChange={handleChain}
                        style={{ width: 220, marginRight: 10 }}
                        placeholder="Select network"
                    >
                        <>
                            <Option key="bep" value="bep">
                                <img src="/images/bnb.png" alt="" />
                                BNB Chain
                            </Option>
                            <Option key="erc" value="erc">
                                <img src="/images/ethe.png" alt="" />
                                Ethereum
                            </Option>
                            <Option key="blast" value="blast">
                                <img src="/images/blast.jpeg" alt="" />
                                Blast
                            </Option>
                            <Option key="opbnb" value="opbnb">
                                <img src="/images/bnb.png" alt="" />
                                opBNB
                            </Option>
                            <Option key="avax" value="avax">
                                <img src="/images/avax.png" alt="" /> Avax Chain
                            </Option>
                            <Option key="op" value="op">
                                <img src="/images/op.png" alt="" /> Optimism
                            </Option>
                            <Option key="poly" value="poly">
                                <img src="/images/poly.png" alt="" /> Polygon
                            </Option>
                            <Option key="zkevm" value="zkevm">
                                <img src="/images/zk.jpeg" alt="" />Immutable zkEVM
                            </Option>

                            {/* <Option key="okc" value="okc">
                <img src="/images/okx.png" alt="" /> OKX Chain
              </Option> */}
                            <Option key="doge" value="doge">
                                <img src="/images/doge.png" alt="" /> Doge Chain
                            </Option>
                            <Option key="fmt" value="fmt">
                                <img src="/images/fmt.png" alt="" /> Fantom
                            </Option>
                            <Option key="arb" value="arb">
                                <img src="/images/arb.png" alt="" /> Arbitrum
                            </Option>
                            <Option key="base" value="base">
                                <img src="/images/base.png" alt="" /> Base
                            </Option>
                        </>

                        <Option key="apt" value="apt">
                            <img src="/images/apt.png" alt="" /> Aptos
                        </Option>
                        {/* <Option key="polka" value="polka">
                   <img src="/images/polka.png" alt="" /> PolkaDot
                 </Option> */}
                        <Option key="sol" value="sol">
                            <img src="/images/sol.png" alt="" /> Solana
                        </Option>
                        {/* <Option key="tron" value="tron">
              <img src="/images/tron.png" alt="" /> Tron
            </Option> */}
                    </Select>
                </div>
            )}

            {active ? (
                <>
                    {/* @ts-ignore this lib is incompatible with react18  */}
                    <Dropdown overlay={menu} placement="bottom">
                        <button className="btn-account" type="button">
                            <div className="img-bnb">
                                <img src={icon_network(chainId)} alt="" />{" "}
                                <div className="price">
                                    {bnbBalance} {token_network(chainId)}
                                </div>
                            </div>

                            {isWaiting ? (
                                <span className="account-style">
                                    <Skeleton.Button active className="btn-waiting-domain" />
                                </span>
                            ) : (
                                <>
                                    {domand !== null ? (
                                        <span className="account-style-domain">
                                            <img src="/images/default_domain.png" alt="" />
                                            {accountEllipsis}
                                        </span>
                                    ) : (
                                        <span className="account-style">
                                            <img src="/images/meta-wl.png" alt="" />
                                            {accountEllipsis}
                                        </span>
                                    )}
                                </>
                            )}
                        </button>
                    </Dropdown>
                </>
            ) : curentAddress ? (
                <>
                    {/* @ts-ignore this lib is incompatible with react18  */}
                    <Dropdown overlay={menuSol} placement="bottom">
                        <button className="btn-account" type="button">
                            <div className="img-bnb">
                                <img
                                    src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/6d4d28c5bcad090ae08e30b8e993bd942b4b71bc15b2885b05.png"
                                    alt=""
                                />{" "}
                                {loadBalance ? (
                                    <Skeleton.Button active />
                                ) : (
                                    <div className="price">{balance.toFixed(2)} SOL</div>
                                )}
                            </div>
                            <span className="account-style">
                                <img src="/images/phantom-logo.png" alt="" />
                                {isMobile ? accountEllipsisMobieSol : accountEllipsisSol}
                            </span>
                        </button>
                    </Dropdown>
                </>
            ) : isAcc && isAcc !== "undefined" ? (
                <>
                    {/* @ts-ignore this lib is incompatible with react18  */}
                    <Dropdown overlay={menuApt} placement="bottom">
                        <button className="btn-account" type="button">
                            <div className="img-bnb">
                                <img src="/images/apt.png" alt="" />{" "}
                                <div className="price">{isBalanceAPT} APT</div>
                            </div>
                            <span className="account-style">
                                <img
                                    src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/2bf2c69b6ee7f607e8ea256f1ed2c7ba5f442d85a7b8c301f6.png"
                                    alt=""
                                />
                                {accountEllipsisApt}
                            </span>
                        </button>
                    </Dropdown>
                </>
            ) : (
                <>
                    {initialValue === "sol" ?
                        <>
                            <WalletMultiButton />

                        </>
                        :
                        <>
                            <button className="btn-connect" onClick={showModal}>
                                Connect Wallet <img src="/images/arrow.png" alt="" />
                            </button>
                        </>
                    }

                </>
            )}
            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                className="connect-wallet"
            >
                <div className="round-connect-v3">
                    <div className="columns">
                        <div className="colum w-6">
                            <div className="content-left">
                                <div className="title">Select Wallet</div>
                                <div className="desc">
                                    Start by connecting with one of the wallets below. Be sure to
                                    store your private keys or seed phrase securely. Never share
                                    them with anyone.
                                </div>
                                {!active ? (
                                    <>
                                        <div className="flex flex-wrap">
                                            {isMobile ? (
                                                <>
                                                    {WALLET_LIST.map((wallet) => {
                                                        return (
                                                            <>
                                                                {wallet.connectorId === "-1" ? (
                                                                    <WalletItem
                                                                        // className={`wallet-item disabled`}
                                                                        className={`wallet-item`}
                                                                        key={wallet.title}
                                                                        onClick={() => handleConnectPontem()}
                                                                        icon={<wallet.icon width="48px" />}
                                                                        title={wallet.title}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {wallet.connectorId === "-2" ? (
                                                                            ''

                                                                            // <WalletItem
                                                                            //     className={`wallet-item`}
                                                                            //     key={wallet.title}
                                                                            //     onClick={() => handleConnectPhantom()}
                                                                            //     icon={<wallet.icon width="48px" />}
                                                                            //     title={wallet.title}
                                                                            // />
                                                                        ) : (
                                                                            <WalletItem
                                                                                className={`wallet-item`}
                                                                                key={wallet.title}
                                                                                onClick={() =>
                                                                                    handleConnect(wallet.connectorId)
                                                                                }
                                                                                icon={<wallet.icon width="48px" />}
                                                                                title={wallet.title}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {WALLET_LIST.map((wallet) => {
                                                        return (
                                                            <>
                                                                {wallet.connectorId === "-1" ? (
                                                                    <WalletItem
                                                                        className={`wallet-item`}
                                                                        key={wallet.title}
                                                                        onClick={() => handleConnectPontem()}
                                                                        icon={<wallet.icon width="48px" />}
                                                                        title={wallet.title}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {wallet.connectorId === "-2" ? (
                                                                            ''
                                                                            // <WalletItem
                                                                            //     className={`wallet-item`}
                                                                            //     key={wallet.title}
                                                                            //     onClick={() => handleConnectPhantom()}
                                                                            //     icon={<wallet.icon width="48px" />}
                                                                            //     title={wallet.title}
                                                                            // />
                                                                        ) : (
                                                                            <WalletItem
                                                                                className={`wallet-item`}
                                                                                key={wallet.title}
                                                                                onClick={() =>
                                                                                    handleConnect(wallet.connectorId)
                                                                                }
                                                                                icon={<wallet.icon width="48px" />}
                                                                                title={wallet.title}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </>
                                            )}

                                            {NEED_A_PLACEHOLDER && (
                                                <div className={walletItemClass} />
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className="colum w-6">
                            <div className="content-right">
                                {!showDetails ? (
                                    <>
                                        <div className="title">Haven’t got a wallet yet?</div>
                                        <div className="box-img">
                                            <img src="./images/meta-v3-in.png" alt="" />
                                        </div>
                                        <div className="learn-more">
                                            <a
                                                href="https://docs.bscs.finance/getting-started/create-a-wallet"
                                                target="blank"
                                            >
                                                <img src="/images/help-circle-1.png" alt="" /> Learn how
                                                to connect
                                            </a>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="title">Binance Wallet is not installed</div>
                                        <div className="desc">
                                            Please install the Binance Wallet browser extension to
                                            connect the Binance Wallet.
                                        </div>
                                        <div className="button-install">
                                            <button
                                                type="button"
                                                className="btn-install-row"
                                                onClick={() =>
                                                    window.open(
                                                        "https://chrome.google.com/webstore/detail/binance-wallet/fhbohimaelbohpjbbldcngcnapndodjp",
                                                        `_blank`
                                                    )
                                                }
                                            >
                                                Install
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ConnectWallet;
