import "./style.css";

const Why = () => {
  const gotoMore = () => {
    window.open(
      "https://docs.bscs.finance/defi/launchpad/apply-to-launch"
    );
  };
  const gotoApply = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSetpx_GHq1x_F1ZbircVoDm8HlHoDNLvj58pzRH-FY6EzVO5Q/viewform"
    );
  };
  return (
    <div className="why-bscs">
      <div className="container">
        <div className="why-text">WHAT WE OFFER</div>
        <div className="why-title">Why BSCS?</div>
        <div className="box-list">
          <div className="box public">
            <div className="gradient-shadow1">
            </div>
            <div className="box-ins">
              <div className="icon-multi-chain">
                <img src="./images/apply/img-multi-chain.png" alt="" />
              </div>
              <div className="title-w pub">
                <img className="icon-w" src="./images/apply/people.png" alt="" />
                Multichain Support
              </div>
              <div className="des-w">
                Burst into a global community of enthusiastic investors in a
                decentralized environment.
              </div>
            </div>
          </div>

          <div className="wrap-box-why">
            <div className="box security">
              <div className="gradient-shadow1">
              </div>
              <div className="box-ins box-ins-cus">
                <div className="icon-secu">
                  <img src="./images/apply/img-secu.png" alt="" />
                </div>
                <div className="wrap-secu">
                  <div className="title-w sec">
                    <img className="icon-w" src="./images/apply/tick.png" alt="" />
                    Security
                  </div>
                  <div className="des-w">
                    Our contract is audited by Certik, PeckShield and SolidProof
                    which ensures high security.
                  </div>
                </div>
              </div>
            </div>
            <div className="box security">
              <div className="gradient-shadow1">
              </div>
              <div className="box-ins box-ins-cus">
                <div className="icon-com">
                  <img src="./images/apply/img-com.png" alt="" />
                </div>
                <div className="wrap-secu">
                  <div className="title-w sec">
                    <img className="icon-w" src="./images/apply/Star.png" alt="" />
                    Community-Oriented
                  </div>
                  <div className="des-w">
                    Grow and nurture your most loyal supporters from the very beginning. You never walk alone!
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="get-button why">
          <button
            type="button"
            className="btn btn-trade why app"
            onClick={gotoApply}
          >
            Apply to launch
          </button>
          <button
            type="button"
            className="btn btn-trade app"
            onClick={gotoMore}
          >
            Learn more
          </button>
        </div>
      </div>
    </div>
  );
};

export default Why;
