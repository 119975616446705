/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
//@ts-ignore
import React, { useEffect, useState, memo } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message, Modal, Spin } from "antd";
import { isMobile } from "react-device-detect";
import TopIdoDetails from "./TopIdoDetails";
import BottomIdoDetails from "./BottomIdoDetails";
import { useHookIDO } from "../StoreIdo";
// import { MAPPING_CHAINID, CHAINID_CONVERT } from "../../../constants";
// import switchNetworkChain from "../../../utils/walletChain";
import { useHookPrice } from "../../../components/common/Store";
import AffiliateClaim from "../Abi/AffiliateClaim.json";
import { useContract } from "../../../hooks/useContract";
import ErrorPage from "../../ErrorPage/index";
import ModalDisClaimer from "../Disclaimer";
import { _claimTokenRef, _isClaimedRef } from "../utilsIDO";
import { useAccount, useChainId, useSwitchChain } from "wagmi";


const IdoDetails = memo(() => {
    const [state, actions]: any = useHookIDO();
    const { address } = useAccount();
    const chainId = useChainId()
    const [state1, actions1]: any = useHookPrice();
    let navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [objDetail, setObjDetail]: any = useState();
    const { switchChain } = useSwitchChain()
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const scContractRef = state?.listRefInfo?.yourRewards?.claimContract;
    const claimContractRef = useContract(scContractRef, AffiliateClaim);
    const [isClaimed, setIsClaimed] = useState(false);
    const pathSymbol = location.search.split("?");
    const pathHash = pathSymbol[1];
    const urlRef = pathSymbol[2];

    const params = {
        address: address,
        symbol: pathHash,
    };
    const params1 = {
        address: '',
        symbol: pathHash,
    };

    const paramsRef = {
        ownerAddress: address,
        symbol: pathHash,
    };

    const paramsTop = {
        symbol: pathHash,
        ownerAddress: address,
    };

    useEffect(() => {
        if (address) {
            actions.getDetailIDO(params);
        }
        return () => {
            actions.resetDetail();
        };
    }, [address]);

    const getObjDetail = async () => {
        try {
            await actions.getDetailIDO1(params1).then((res: any) => {
                setObjDetail(res)
            })
                .catch((err: any) => {
                    setObjDetail()
                })
        } catch (error) {

        }
    }
    useEffect(() => {
        getObjDetail()
        return () => {
            setObjDetail()
        };
    }, []);

    useEffect(() => {
        if (address) {
            actions.getRefInfo(paramsRef);
            actions.getRefTop(paramsTop);
            localStorage.setItem("refId", urlRef);
        }
    }, [address, urlRef]);

    const paramsAccount = {
        ownerAddress: address,
        referralAddress: urlRef,
    };

    useEffect(() => {
        if (address && pathHash) {
            actions.getListSignref(paramsAccount);
        }
    }, [actions, address]);

    const refEllipsisRow = `${`https://bscs.finance${state?.listRefInfo?.affiliateLink}`.substring(
        0,
        10
    )}...${`https://bscs.finance${state?.listRefInfo?.affiliateLink}`?.substring(
        `https://bscs.finance${state?.listRefInfo?.affiliateLink}`.length - 10
    )}`;

    useEffect(() => {
        document.title = "Launchpad - BSCS (BSCS)";
        document
            .getElementsByTagName("meta")
            .item(1)
            ?.setAttribute(
                "content",
                "BSCS Launchpad is the innovative decentralized IDO platform across all main blockchain networks."
            );
    }, []);



    let curentChain = localStorage.getItem("chain");
    const { switchChainAsync } = useSwitchChain()

    const chainList = (value: any) => {
        switch (value) {
            case "erc":
                return 1;
            case "bep":
                return 56;
            case "poly":
                return 137;
            case "arb":
                return 42161;
            case "avax":
                return 43114;
            case "op":
                return 10;
            case "doge":
                return 2000;
            case "fmt":
                return 250;
            case "base":
                return 8453;
            case "opbnb":
                return 204;
            default:
                return "-ss--";
        }
    };

    const chainHeader = (value: any) => {
        switch (value) {
            case 1:
                return "erc";
            case 56:
                return "bep";
            case 137:
                return "poly";
            case 42161:
                return "arb";
            case 43114:
                return "avax";
            case 10:
                return "op";
            case 2000:
                return "doge";
            case 250:
                return "fmt";
            case 8453:
                return "base";
            case 204:
                return "opbnb";
            default:
                return "---";
        }
    };

    // const switchChainWagmi = async () => {
    //     switchChain({ chainId: Number(chainList(state.objDetailIDO.network)) })
    // }

    // const switchChainWagmi = async () => {
    //     await switchChainAsync({ chainId: Number(chainList(state.objDetailIDO.network)) }).then((res: any) => {
    //         // console.log('res', res)
    //     })
    // }

    // useEffect(() => {
    //     if (address !== undefined) {
    //         switchChainWagmi()
    //     }
    //     return () => {
    //         // TODO
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [state.objDetailIDO, address, chainId, state1.chain, curentChain, state.objDetailIDO.network]);


    useEffect(() => {
        if (state.objDetailIDO?.isOldIDo !== undefined) {
            if (state.objDetailIDO?.isOldIDo === true) {
                navigate(`/Ido/IdoDetails?${state.objDetailIDO.symbol}`);
            }
        }
    }, [state.objDetailIDO?.isOldIDo]);

    const handleClaim = async () => {
        setIsLoading(true);
        try {
            await _claimTokenRef(
                claimContractRef,
                state?.listRefInfo?.yourRewards?.rewards,
                state?.listRefInfo?.yourRewards?.keyId,
                state?.listRefInfo?.yourRewards?.claimSign,
                state?.listRefInfo?.yourRewards?.tokenDecimal
            ).then((res) => {
                res.wait().then((res1: any) => {
                    if (res1 !== null) {
                        setIsLoading(false);
                        setIsClaimed(true);
                    } else {
                        setIsLoading(false);
                    }
                });
            });
        } catch (error: any) {
            setIsLoading(false);
            if (error) {
                message.error(error?.error?.data?.message);
            }
        }
    };

    useEffect(() => {
        if (address) {
            _isClaimedRef(claimContractRef, address).then((res: any) => {
                setIsClaimed(res);
            });
        }
    }, [address, claimContractRef]);



    return (
        <>
            {/* (objDetail?.network !== chainHeader(chainId) &&
            objDetail?.network !== undefined) || state1.chain !== objDetail?.network ? */}

            <div className="main-body">
                <div className="full-width">
                    <div className="container">
                        <div className="main-content-details">
                            <div className="breadcrum-swap">
                                <ul className="list-breadcrum">
                                    <li>
                                        <Link to={"/Ido"}>Project</Link>
                                    </li>
                                    <li>{">"}</li>
                                    <li>IDO Pool</li>
                                </ul>
                                <TopIdoDetails dataTop={objDetail} />
                                <BottomIdoDetails dataBody={state.objDetailIDO} objDetail={objDetail} />

                                {state?.listRefInfo?.affiliateLink !== null &&
                                    state?.listRefInfo?.affiliateLink !== "" &&
                                    state?.listRefInfo?.affiliateLink !== undefined ? (
                                    <>
                                        <div className="round-affiliate">
                                            <div className="columns">
                                                <div className="colum w-6">
                                                    <div className="content-affiliate">
                                                        <div className="title">Affiliate Program</div>
                                                        <div className="flex-item">
                                                            <div className="txt-l">Affiliate Link:</div>
                                                            <div className="txt-r account">
                                                                {refEllipsisRow}
                                                                <CopyToClipboard
                                                                    text={`https://bscs.finance${state?.listRefInfo?.affiliateLink}`}
                                                                    onCopy={() => {
                                                                        message.success({
                                                                            type: "success",
                                                                            content: "Copied!!",
                                                                            className: "custom-class",
                                                                            duration: 2,
                                                                        });
                                                                    }}
                                                                >
                                                                    <span className="img-copy">
                                                                        <img
                                                                            className="coppy"
                                                                            src="/images/imgido/copy.png"
                                                                            alt=""
                                                                        />
                                                                    </span>
                                                                </CopyToClipboard>
                                                            </div>
                                                        </div>
                                                        <div className="flex-item">
                                                            <div className="txt-l">
                                                                Your Estimated Rewards:
                                                            </div>
                                                            <div className="txt-r">
                                                                {state?.listRefInfo !== null
                                                                    ? state?.listRefInfo?.yourEstReward
                                                                    : "0"}{" "}
                                                                USDT
                                                            </div>
                                                        </div>
                                                        <div className="flex-item">
                                                            <div className="txt-l">
                                                                Claimable Referrals Rewards:
                                                            </div>
                                                            <div className="txt-r claim">
                                                                {isLoading ? (
                                                                    <>
                                                                        <button
                                                                            className="btn-claim-ref dis"
                                                                            type="button"
                                                                            onClick={handleClaim}
                                                                        >
                                                                            Claim <Spin size="small" />
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {state?.listRefInfo?.yourRewards
                                                                            ?.rewards !== null &&
                                                                            state?.listRefInfo?.yourRewards
                                                                                ?.rewards > 0 ? (
                                                                            <>
                                                                                {isClaimed ? (
                                                                                    <>
                                                                                        <button
                                                                                            className="btn-claim-ref dis"
                                                                                            type="button"
                                                                                        >
                                                                                            Claimed
                                                                                        </button>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <button
                                                                                            className="btn-claim-ref"
                                                                                            type="button"
                                                                                            onClick={handleClaim}
                                                                                        >
                                                                                            Claim{" "}
                                                                                            {state?.listRefInfo
                                                                                                ?.yourRewards !== null
                                                                                                ? state?.listRefInfo
                                                                                                    ?.yourRewards?.rewards
                                                                                                : "0"}{" "}
                                                                                            USDT
                                                                                        </button>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <button
                                                                                    className="btn-claim-ref dis"
                                                                                    type="button"
                                                                                >
                                                                                    Claim{" "}
                                                                                    {state?.listRefInfo
                                                                                        ?.yourRewards !== null
                                                                                        ? state?.listRefInfo
                                                                                            ?.yourRewards?.rewards
                                                                                        : "0"}{" "}
                                                                                    USDT
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="flex-item">
                                                            <div className="txt-l">
                                                                Remaining Referrals Rewards:
                                                            </div>
                                                            <div className="txt-r">
                                                                {state?.listRefInfo?.maxRewards -
                                                                    state?.listRefInfo?.yourEstReward}{" "}
                                                                USDT
                                                            </div>
                                                        </div>
                                                        <div className="flex-item">
                                                            <div className="txt-l">
                                                                Pool Referrer Count:
                                                            </div>
                                                            <div className="txt-r">
                                                                {state?.listRefInfo?.poolReffererCount}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="colum w-6">
                                                    <div className="content-affiliate border-rights">
                                                        <div className="title">
                                                            <button
                                                                type="button"
                                                                className="btn-top-reward"
                                                                onClick={showModal}
                                                            >
                                                                List of Top Rewards
                                                            </button>
                                                        </div>
                                                        <div className="flex-item border-row">
                                                            <div className="txt-l">
                                                                Reward Percentage:
                                                            </div>
                                                            <div className="txt-r">
                                                                {state?.listRefInfo?.rewardPercentage}%
                                                            </div>
                                                        </div>
                                                        <div className="flex-item border-row">
                                                            <div className="txt-l">
                                                                Total Estimated Rewards:
                                                            </div>
                                                            <div className="txt-r">
                                                                {state?.listRefInfo?.totalEstReward} USDT
                                                            </div>
                                                        </div>
                                                        <div className="flex-item border-row">
                                                            <div className="txt-l">Max Rewards:</div>
                                                            <div className="txt-r">
                                                                {state?.listRefInfo?.maxRewards} USDT{" "}
                                                                <span className="row-raised">
                                                                    assume fully Raised
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex-item">
                                                            <div className="txt-l">Total Ref Amount:</div>
                                                            <div className="txt-r">
                                                                {state?.listRefInfo?.totalRefAmount} USDT
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>{""}</>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title="List of Top Rewards"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                className="modal-ref"
            >
                <table className="main-table">
                    <tr className="tr-top">
                        <th>Wallet address</th>
                        <th>Rewards</th>
                    </tr>
                    {state.listRefTop.length > 0 ? (
                        <>
                            {state.listRefTop &&
                                state.listRefTop.map((item: any, i: any) => (
                                    <tr className="bottom">
                                        {isMobile ? (
                                            <>
                                                <td>
                                                    {item?.ownerAddress?.substring(0, 4)}...
                                                    {item?.ownerAddress?.substring(
                                                        item?.ownerAddress.length - 4
                                                    )}
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td>{item?.ownerAddress}</td>
                                            </>
                                        )}
                                        <td>{item?.rewards} USD</td>
                                    </tr>
                                ))}
                        </>
                    ) : (
                        <>
                            <tr className="bottom">
                                <td colSpan={2}>
                                    <div className="not-ref">
                                        <img src="/images/imgido/not-ref.png" alt="" />
                                    </div>
                                </td>
                            </tr>
                        </>
                    )}
                </table>
            </Modal>
            {address ? (
                <ModalDisClaimer
                    symbol={state.objDetailIDO.symbol}
                    isShowDisClaim={state.objDetailIDO.showdisclaimer}
                />
            ) : (
                ""
            )}
        </>
    );
});
export default IdoDetails;
