/* eslint-disable no-unreachable */
/* eslint-disable default-case */
/* eslint-disable no-mixed-operators */
import web3 from "web3";
import BigNumber from "bignumber.js";
import { useAccount, useChainId, useSwitchChain } from "wagmi";
import { NETWORK, CHAIN } from "./index.d";
// import * as anchor from "@project-serum/anchor";
// import * as Web3 from "@solana/web3.js";
// import idl from "./bscs_ido.json";

// import { struct, u32, u8 } from "@solana/buffer-layout";
// import { publicKey, u64 } from "@solana/buffer-layout-utils";
// import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { ethers } from "ethers";


export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), "ether");
  if (decimal !== 18)
    amount = new BigNumber(value)
      .times(new BigNumber(10).pow(decimal))
      .toString();
  return amount;
};

export const _approveBUSD = async (
  contractBUSD,
  address,
  amount,
  usdDecimals
) => {
  // const amountTemp = convertTokenToWei(amount, usdDecimals);
  const result = await contractBUSD.approve(
    address,
    ethers.constants.MaxUint256
  );
  return result;
};

// export const _isRefundedRC = async (claimContract, account) => {
//   return claimContract.userRefund(account.toLowerCase());
// };
export const _isRefundedRC = async (
  claimContract,
  account,
  amount,
  usdDecimals
) => {
  const amountRefund = convertTokenToWei(amount, usdDecimals);
  return claimContract.showRefundBtn(account, amountRefund);
};

// export const _claimTokens = async (claimContract, item, tokenDecimals) => {
//   const amountTemp = convertTokenToWei(item.claimToken, tokenDecimals);
//   const args = [amountTemp, item.signToken];
//   const gas = await claimContract.estimateGas.claimTokens(...args);
//   return claimContract.claimTokens(...args, { gasLimit: gas });
// };

export const _claimTokens = async (
  claimContract,
  item,
  tokenDecimals,
  usdDecimals
) => {
  const amountTemp = convertTokenToWei(item.claimToken, tokenDecimals);
  const refundUsd = convertTokenToWei(item.refundUsd, usdDecimals);
  const args = [amountTemp, refundUsd, item?.signToken];
  const gas = await claimContract.estimateGas.claimTokens(...args);
  return claimContract.claimTokens(...args, { gasLimit: gas });
};

export const _claimTokensVangguard = async (
  claimContract,
  item,
  tokenDecimals,
  usdDecimals
) => {
  const amountTemp = convertTokenToWei(item.tokenClaim, tokenDecimals);
  const refundUsd = convertTokenToWei(item.usdtRefund, usdDecimals);
  const args = [amountTemp, refundUsd, item?.signatureClaimToken];
  const gas = await claimContract.estimateGas.claimTokens(...args);
  return claimContract.claimTokens(...args, { gasLimit: gas });
};

export const _claimTokensVangguard1 = async (
  claimContract,
  item
) => {
  const amountTemp = convertTokenToWei(item?.token, item?.tokenDecimal);
  const refundUsd = convertTokenToWei(item.usdt, item?.usdDecimal);
  const args = [amountTemp, refundUsd, item?.signature];
  const gas = await claimContract.estimateGas.claimTokens(...args);
  return claimContract.claimTokens(...args, { gasLimit: gas });
};

export const _refund = async (
  claimContract,
  amountBusd,
  claimToken,
  signBusd,
  decimals,
  usdDecimals,
  idoNative
) => {
  const amount = convertTokenToWei(amountBusd, usdDecimals);
  const claimAmount = convertTokenToWei(claimToken, decimals);
  const args = [amount, claimAmount, signBusd];
  if (idoNative) {
    const estimatedGas = await claimContract.estimateGas.refundBNB(...args);
    return claimContract.refundBNB(...args, {
      gasLimit: estimatedGas
    });
  } else {
    const estimatedGas = await claimContract.estimateGas.refund(...args);
    return claimContract.refund(...args, {
      gasLimit: estimatedGas,
    });
  }

};

export const _refundAll = async (
  claimContract,
  amountBusd,
  claimToken,
  signBusd,
  decimals,
  usdDecimals
) => {
  const amount = convertTokenToWei(amountBusd, usdDecimals);
  const claimAmount = convertTokenToWei(claimToken, decimals);
  const args = [amount, claimAmount, signBusd];
  const estimatedGas = await claimContract.estimateGas.refund(...args);
  return claimContract.refund(...args, {
    gasLimit: estimatedGas,
  });

};

export const _showClaimBtn = async (
  claimContract,
  account,
  amount,
  tokenDecimals
) => {
  const amountTemp = convertTokenToWei(amount, tokenDecimals);
  const result = await claimContract.showClaimBtn(account, amountTemp);
  return result;
};

export const _showBtnRFAll = async (
  claimContract,
  account,
  amount,
  usdDecimals
) => {
  const amountTemp = convertTokenToWei(amount, usdDecimals);
  const result = await claimContract.showRefundAll(account, amountTemp);
  return result;
};

export const _isClaimed = async (poolContract, account) => {
  return poolContract && poolContract.isClaimed(account);
};


export const _joinPoolNew = async (
  joinPoolContract,
  amount,
  projectId,
  signBusd,
  chainId,
  usdDecimals,
  idoNative
) => {
  const amountTemp = await convertTokenToWei(amount, usdDecimals);
  const args = [amountTemp, projectId, projectId, signBusd];
  if (idoNative) {
    const gas = await joinPoolContract.estimateGas.joinBNB(...args, {
      value: amountTemp,
    });

    return joinPoolContract.joinBNB(...args,
      {
        gasLimit: gas,
        value: amountTemp,
      }
    );
  } else {
    const gas = await joinPoolContract.estimateGas.join(...args);
    return joinPoolContract.join(...args, { gasLimit: gas });
  }

};

export const _refundChange = async (
  claimContract,
  amountBusd,
  item,
  usdDecimals,
  idoNative
) => {
  const amount = convertTokenToWei(amountBusd, usdDecimals);
  const args = [amount, item];
  if (idoNative) {
    const estimatedGas = await claimContract.estimateGas.refundBNB(...args);
    return claimContract.refundBNB(...args, {
      gasLimit: estimatedGas
    });
  } else {
    const estimatedGas = await claimContract.estimateGas.refund(...args);
    return claimContract.refund(...args, {
      gasLimit: estimatedGas,
    });
  }

};

export const _isClaimChange = async (claimContract, account) => {
  return claimContract.userRefund(account.toLowerCase());
};

export const convertWeiToToken = (value, decimal) => {
  if (decimal === 18) return web3.utils.fromWei(value.toString(), "ether");
  else if (decimal === 6)
    return web3.utils.fromWei(value.toString(), "picoether");
  else if (decimal === 9)
    return web3.utils.fromWei(value.toString(), "nanoether");
  else if (decimal === 12)
    return web3.utils.fromWei(value.toString(), "microether");
  else if (decimal === 15)
    return web3.utils.fromWei(value.toString(), "milliether");
  else return value.toString() / 10 ** decimal;
};

// export const _totalClaimed = async (poolContract, account, tokenDecimals) => {
//   const res = poolContract && (await poolContract.totalClaimed(account));
//   return convertWeiToToken(res, tokenDecimals);
// };

export const _totalClaimed = async (poolContract, account, tokenDecimals) => {
  const res = poolContract && (await poolContract.realClaimed(account));
  return convertWeiToToken(res, tokenDecimals);
};

export const _totalRefunded = async (poolContract, account, usdDecimals) => {
  const res = poolContract && (await poolContract.realRefunded(account));
  return convertWeiToToken(res, usdDecimals);
};

export const totalDailyClaimed = async (idoTkenClaimSC, account) => {
  return idoTkenClaimSC.userInfo(account);
};

export const totalJoinPool = async (poolJoinContract) => {
  const total = poolJoinContract && (await poolJoinContract.totalJoined());

  return total !== null ? total.toString() : 0;
};

export const _isJoined = async (
  poolJoinContract,
  account,
  projectId,
  roundId
) => {
  try {
    return poolJoinContract.isJoined(account, projectId, projectId);
  } catch (error) {
    return false;
  }
};

export const _claimTokenRef = async (claimContract, reward, keyId, sign, tokenDecimals) => {
  const amount = convertTokenToWei(reward, tokenDecimals)
  let args = [amount, keyId, sign]
  const estimatedGas = await claimContract.estimateGas.claimTokens(...args)
  return claimContract.claimTokens(...args, {
    gasLimit: estimatedGas,
  })
}

export const _isClaimedRef = async (poolContract, account) => {
  return poolContract && poolContract.isClaimed(account);
};


export const getProgressTime = (startTime) => {
  if (!startTime) {
    return Date.now();
  }
  const now = new Date();
  const utcDate = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    0
  );
  const startDate = new Date(startTime);

  const startTS = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
    startDate.getHours(),
    startDate.getMinutes(),
    startDate.getSeconds(),
    0
  );
  if (startTS <= utcDate) {
    return Date.now();
  } else {
    let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;

    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600);
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = Math.floor(delta % 60); // in theory the modulus is not required

    // return `${days} day${days > 1 ? 's' : ''} ${hours}:${minutes}:${seconds}`
    if (days >= 1) {
      return (
        Date.now() +
        days * 24 * 60 * 60 * 1000 +
        hours * 60 * 60 * 1000 +
        minutes * 60 * 1000 +
        seconds * 1000
      );
    } else {
      return (
        Date.now() +
        hours * 60 * 60 * 1000 +
        minutes * 60 * 1000 +
        seconds * 1000
      );
    }
  }
};
export const rendererCountDown = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) => {
  // Render a countdown
  if (completed) {
    return <span>Finish</span>
  }
  if (days > 1) {
    return (
      <span>
        {days}days : {hours}h : {minutes}m : {seconds}s
      </span>
    );
  } else if (days === 1) {
    return (
      <span>
        {days}day : {hours}h : {minutes}m : {seconds}s
      </span>
    );
  } else {
    return (
      <span>
        {hours}h : {minutes}m : {seconds}s
      </span>
    );
  }
};

const ether = (
  <>
    <img style={{ width: "20px" }} className="imgChain" src="/images/imgido/ether.png" alt="" />
  </>
);
const poly = (
  <>
    <img style={{ width: "20px" }} className="imgChain" src="/images/imgido/poly.png" alt="" />
  </>
);
const bsc = (
  <>
    <img style={{ width: "20px" }} className="imgChain" src="/images/imgido/icon-bnb-ido.png" alt="" />
  </>
);
const opbnb = (
  <>
    <img style={{ width: "20px" }} className="imgChain" src="/images/imgido/icon-bnb-ido.png" alt="" />
  </>
);

const avax = (
  <>
    <img style={{ width: "20px" }} className="imgChain" src="/images/imgido/avax.png" alt="" />
  </>
);

const apt = (
  <>
    <img
      className="imgChain"
      src="https://s2.coinmarketcap.com/static/img/coins/64x64/21794.png"
      alt=""
      style={{ width: "20px" }}
    />
  </>
);
const sol = (
  <>
    <img
      src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/6d4d28c5bcad090ae08e30b8e993bd942b4b71bc15b2885b05.png"
      alt=""
      style={{ width: "20px" }}
    />
  </>
);

const okx = (
  <>
    <img style={{ width: "20px" }} src="/images/imgido/okx.png" alt="" />
  </>
);

const arbitrum = (
  <>
    <img style={{ width: "20px" }} src="/images/imgido/arb.png" alt="" />
  </>
);
const base = (
  <>
    <img style={{ width: "20px" }} src="/images/base.png" alt="" />
  </>
);
const op = (
  <>
    <img style={{ width: "20px" }} src="/images/op.png" alt="" />
  </>
);
const doge = (
  <>
    <img style={{ width: "20px" }} src="/images/doge.png" alt="" />
  </>
);
const fantom = (
  <>
    <img style={{ width: "20px" }} src="/images/fmt.png" alt="" />
  </>
);
const tron = (
  <>
    <img style={{ width: "20px" }} src="/images/tron.png" alt="" />
  </>
);
const sui = (
  <>
    <img style={{ width: "20px" }} src="/images/imgido/sui.jpeg" alt="" />
  </>
);
const zkevm = (
  <>
    <img style={{ width: "20px" }} src="/images/zk.jpeg" alt="" />
  </>
);
const ton = (
  <>
    <img style={{ width: "20px" }} src="/images/ton.png" alt="" />
  </>
);

export const fillNetwork = (network) => {
  switch (network) {
    case NETWORK.BSC:
      return "BNB";
      break;
    case NETWORK.Poly:
      return "Polygon";
      break;
    case NETWORK.Ethereum:
      return "Ethereum";
      break;
    case NETWORK.Avax:
      return "Avax";
      break;
    case NETWORK.Aptos:
      return "Aptos";
      break;
    case NETWORK.SOL:
      return "Solana";
      break;
    case NETWORK.OKC:
      return "OKXChain";
      break;
    case NETWORK.ARB:
      return "Arbitrum";
      break;
    case NETWORK.OPBNB:
      return "opBNB";
      break;
    case NETWORK.BASE:
      return "Base";
      break;
    case NETWORK.TRON:
      return "Tron";
      break;
    case NETWORK.Op:
      return "Optimism";
      break;
    case NETWORK.Doge:
      return "Doge";
      break;
    case NETWORK.Fantom:
      return "Fantom";
      break;
    case NETWORK.SUI:
      return "SUI";
      break;
    case NETWORK.ZKEVM:
      return "zkEVM";
      break;
    case NETWORK.TON:
      return "TON";
      break;
  }
};

export const fillIconChain = (network) => {
  switch (network) {
    case NETWORK.BSC:
      return bsc;
      break;
    case NETWORK.Poly:
      return poly;
      break;
    case NETWORK.OKC:
      return okx;
      break;
    case NETWORK.Ethereum:
      return ether;
      break;
    case NETWORK.Avax:
      return avax;
      break;
    case NETWORK.ARB:
      return arbitrum;
      break;
    case NETWORK.OPBNB:
      return opbnb;
      break;
    case NETWORK.BASE:
      return base;
      break;
    case NETWORK.Aptos:
      return apt;
      break;
    case NETWORK.SOL:
      return sol;
      break;
    case NETWORK.Op:
      return op;
      break;
    case NETWORK.Doge:
      return doge;
      break;
    case NETWORK.Fantom:
      return fantom;
      break;
    case NETWORK.TRON:
      return tron;
      break;
    case NETWORK.SUI:
      return sui;
      break;
    case NETWORK.ZKEVM:
      return zkevm;
      break;
    case NETWORK.TON:
      return ton;
      break;

  }
};
