/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-assignment */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-template */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Progress } from "antd";
import { _getTotalLocked, _getTokenAmount } from "../LauchPool/details/utils";
import ErrorPage from "../ErrorPage";
import "./style.scss";
import { useContract } from "../../hooks/useContract";
import abiStake from "./utils/abiStake.json";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useAccount } from "wagmi";
import { useHookInoPool } from "./Store-Ino-Pool";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const CountdownTimer: React.FC<{ targetTimestamp: number }> = ({ targetTimestamp }) => {
  const [timeLeft, setTimeLeft] = useState<string>("");

  useEffect(() => {
    const updateCountdown = () => {
      if (!targetTimestamp || isNaN(targetTimestamp)) {
        setTimeLeft("TBA");
        return;
      }

      const timeDiff = targetTimestamp * 1000 - Date.now(); // Convert to milliseconds
      setTimeLeft(
        timeDiff <= 0
          ? "TBA"
          : `${Math.floor(timeDiff / 86400000)}d ${Math.floor((timeDiff / 3600000) % 24)}h ${Math.floor((timeDiff / 60000) % 60)}m ${Math.floor((timeDiff / 1000) % 60)}s`
      );
    };

    updateCountdown(); // Run once immediately
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [targetTimestamp]);

  return <>{timeLeft}</>;
};

const formatNumber = (x: any, max: any) => {
  if (x) {
    return x.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: max,
    });
  } else return 0;
};

export const LaunchPoolNew = () => {
  const [state, actions] = useHookInoPool();
  const [tab, setTab] = useState(2);
  const [allTabPool, setAllTabPool] = useState<any>([]);
  const [allPool, setAllPool] = useState<any>([]);
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    actions.getMarketInfo();
  }, []);

  const market: any = state?.marketInfo;
  const price: any = market?.price;

  const handleGetData = async () => {
    setLoading(true);
    setAllTabPool([]);
    try {
      let data: any = null;
      if (tab === 4) {
        data = await actions.getListPool(tab, address);
      } else {
        data = await actions.getListPool(tab);
      }
      if (data) {
        setAllTabPool(data);
      } else {
        setAllTabPool([]);
      }
    } catch {
      setAllTabPool([]);
      //
    } finally {
      setLoading(false);
    }
  }
  const handleGetAllData = async () => {
    try {
      const data = await actions.getListPool();
      if (data) {
        const arr: any = [];
        data.length > 0 && data.map((item: any) => {
          arr.push(item?.poolContract);
        })
        setAllPool(arr);
      }
    } catch {
      //
    }
  }
  useEffect(() => {
    handleGetAllData();
  }, []);
  useEffect(() => {
    handleGetData();
  }, [tab, address]);

  let account = address;
  let web3: any = new Web3('https://bsc-dataseed4.defibit.io');

  const [totalStaked, setTotalStaked]: any = useState(0)
  const [numberStaked, setNumberStaked]: any = useState(0)

  const getTotalStaked = async (sc: any) => {
    if (sc) {
      const contractStake1 = new web3.eth.Contract(
        abiStake as unknown as AbiItem,
        sc
      );
      await contractStake1.methods.tokenTotalStaked().call()
        .then((res: any) => {
          setTotalStaked((prev: number) => prev + res / 1e18);
        })
        .catch((err: any) => {
          setTotalStaked((prev: number) => prev);
        });
    }
  };

  const getNumberStaked = async (sc: any) => {
    if (sc) {
      try {
        const contractStake1 = new web3.eth.Contract(
          abiStake as unknown as AbiItem,
          sc
        );
        await contractStake1.methods.stakerCount().call()
          .then((res: any) => {
            setNumberStaked((prev: number) => prev + Number(res));
          })
          .catch(() => {
            setNumberStaked((prev: number) => prev); // Keep previous value if there's an error
          });
      } catch (error) {
        //
      }
    }
  };

  const getAll = async () => {
    try {
      allPool.length > 0 && allPool.map((sc: any) => {
        getTotalStaked(sc);
        getNumberStaked(sc);
      })
    } catch {
      //
    }
  }

  useEffect(() => {
    if (allPool && allPool.length > 0) {
      getAll();
    }
  }, [allPool]);

  const noData = (
    <div className="new-nodata">
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no projects</p>
    </div>
  );

  const noDataOrNotConnect = (
    <div className="new-nodata">
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>{address ? 'Currently there is no projects' : 'Please connect your wallet'}</p>
    </div>
  );

  const ProjectActive: React.FC<{ data: any, index: any }> = ({ data, index }) => {
    const [totalStaked, setTotalStaked] = useState(0);
    const getTotalStaked = async (sc: any) => {
      if (sc) {
        const contractStake1 = new web3.eth.Contract(
          abiStake as unknown as AbiItem,
          sc
        );
        await contractStake1.methods.tokenTotalStaked().call()
          .then((res: any) => {
            setTotalStaked(res / 1e18);
          })
          .catch((err: any) => {
            setTotalStaked(0);
          });
      }
    };
    useEffect(() => {
      if (data) {
        getTotalStaked(data?.poolContract);
      }
    }, [data]);

    return (
      <div onClick={() => navigate(`/launch-pool-details/${data?.poolContract}`)} key={index} className="project">
        <div className="top">
          <div className="left">
            <img src='/images/new/project_logo.svg' />
            <p className="pool-name">{data?.name}</p>
          </div>
          <div className="right">
            <img src='/images/new/live.svg' />
            <p className="pool-name">Live</p>
          </div>
        </div>
        <div className="project-banner">
          <img alt='' src='/images/new/project_bg.png' />
        </div>
        <div className="project-time">
          <p className="name">Ended in:</p>
          <p className="value new-value">
            Infinity
            {/* <CountdownTimer targetTimestamp={data?.endTime} /> */}
          </p>
        </div>
        <div className="project-info">
          <div className="info-pair apy">
            <p className="name">APY</p>
            <p className="value">3%</p>
          </div>
          <div className="info-pair">
            <p className="name">
              Total Staked Amount
            </p>
            <p className="value">
              {totalStaked >= 1_000_000 ? `${Math.floor(totalStaked / 1_000_000)}M` : formatNumber(totalStaked, 0)} BSCS
            </p>
          </div>
          <div className="info-pair">
            <p className="name">Number of Stakers</p>
            <p className="value">{formatNumber(numberStaked + 2000, 2)}</p>
          </div>
        </div>
        {/* <div className="info-cap">
          <div className="name">
            <p>Pool cap:</p>
            <p>Remaining Pool:</p>
          </div>
          <div className="value">
            <p>10,000,000 BSCS</p>
            <p>7,000,000 BSCS</p>
          </div>
          <div className="new-progress">
            <Progress
              strokeColor={{
                "0%": "rgb(249 208 100 / 89%)",
                "100%": "#F9D064",
              }}
              percent={0}
              status="active"
            />
          </div>
        </div> */}
        <div onClick={() => navigate(`/launch-pool-details/${data?.poolContract}`)} className="btn-new-lp">
          View Pool
        </div>
      </div>
    )
  }

  const ProjectUp: React.FC<{ data: any, index: any }> = ({ data, index }) => {
    const [totalStaked, setTotalStaked] = useState(0);
    const getTotalStaked = async (sc: any) => {
      if (sc) {
        const contractStake1 = new web3.eth.Contract(
          abiStake as unknown as AbiItem,
          sc
        );
        await contractStake1.methods.tokenTotalStaked().call()
          .then((res: any) => {
            setTotalStaked(res / 1e18);
          })
          .catch((err: any) => {
            setTotalStaked(0);
          });
      }
    };
    useEffect(() => {
      if (data) {
        getTotalStaked(data?.poolContract);
      }
    }, [data]);

    return (
      <div onClick={() => navigate(`/launch-pool-details/${data?.poolContract}`)} key={index} className="project">
        <div className="top">
          <div className="left">
            <img src='/images/new/project_logo.svg' />
            <p className="pool-name">{data?.name}</p>
          </div>
          <div className="right">
            <img src='/images/new/up.svg' />
            <p className="pool-name up">Upcoming</p>
          </div>
        </div>
        <div className="project-banner">
          <img alt='' src='/images/new/project_bg.png' />
        </div>
        <div className="project-time up">
          <p className="name">Listing in:</p>
          <p className="value"><CountdownTimer targetTimestamp={data?.endTime} /></p>
        </div>
        <div className="project-info">
          <div className="info-pair apy">
            <p className="name">APY</p>
            <p className="value">3%</p>
          </div>
          <div className="info-pair">
            <p className="name">
              Total Staked Amount
            </p>
            <p className="value">
              {totalStaked >= 1_000_000 ? `${Math.floor(totalStaked / 1_000_000)}M` : formatNumber(totalStaked, 0)} BSCS
            </p>
          </div>
          <div className="info-pair">
            <p className="name">Number of Stakers</p>
            <p className="value">{formatNumber(numberStaked + 2000, 2)}</p>
          </div>
        </div>
        {/* <div className="info-cap">
          <div className="name">
            <p>Pool cap:</p>
            <p>Remaining Pool:</p>
          </div>
          <div className="value">
            <p>10,000,000 BSCS</p>
            <p>7,000,000 BSCS</p>
          </div>
          <div className="new-progress">
            <Progress
              strokeColor={{
                "0%": "rgb(249 208 100 / 89%)",
                "100%": "#F9D064",
              }}
              percent={0}
              status="active"
            />
          </div>
        </div> */}
        <div onClick={() => navigate(`/launch-pool-details/${data?.poolContract}`)} className="btn-new-lp">
          View Pool
        </div>
      </div>
    )
  }

  const ProjectEnd: React.FC<{ data: any, index: any }> = ({ data, index }) => {
    const [totalStaked, setTotalStaked] = useState(0);
    const getTotalStaked = async (sc: any) => {
      if (sc) {
        const contractStake1 = new web3.eth.Contract(
          abiStake as unknown as AbiItem,
          sc
        );
        await contractStake1.methods.tokenTotalStaked().call()
          .then((res: any) => {
            setTotalStaked(res / 1e18);
          })
          .catch((err: any) => {
            setTotalStaked(0);
          });
      }
    };
    useEffect(() => {
      if (data) {
        getTotalStaked(data?.poolContract);
      }
    }, [data]);

    return (
      <div onClick={() => navigate(`/launch-pool-details/${data?.poolContract}`)} key={index} className="project">
        <div className="top">
          <div className="left">
            <img src='/images/new/project_logo.svg' />
            <p className="pool-name">{data?.name}</p>
          </div>
          <div className="right">
            <img src='/images/new/end.svg' />
            <p className="pool-name end">Finished</p>
          </div>
        </div>
        <div className="project-banner">
          <img alt='' src='/images/new/project_bg.png' />
        </div>
        <div className="project-time end">
          <p className="name">Ends in:</p>
          <p className="value">Ended</p>
        </div>
        <div className="project-info">
          <div className="info-pair apy">
            <p className="name">APY</p>
            <p className="value">3%</p>
          </div>
          <div className="info-pair">
            <p className="name">Total Staked Amount</p>
            <p className="value">
              {totalStaked >= 1_000_000 ? `${Math.floor(totalStaked / 1_000_000)}M` : formatNumber(totalStaked, 0)} BSCS
            </p>
          </div>
          <div className="info-pair">
            <p className="name">Number of Stakers</p>
            <p className="value">{formatNumber(numberStaked + 2000, 2)}</p>
          </div>
        </div>
        {/* <div className="info-cap">
          <div className="name">
            <p>Pool cap:</p>
            <p>Remaining Pool:</p>
          </div>
          <div className="value">
            <p>10,000,000 BSCS</p>
            <p>7,000,000 BSCS</p>
          </div>
          <div className="new-progress">
            <Progress
              strokeColor={{
                "0%": "rgb(249 208 100 / 89%)",
                "100%": "#F9D064",
              }}
              percent={0}
              status="active"
            />
          </div>
        </div> */}
        <div onClick={() => navigate(`/launch-pool-details/${data?.poolContract}`)} className="btn-new-lp">
          View Pool
        </div>
      </div>
    )
  }

  const ProjectJoined: React.FC<{ data: any, index: any }> = ({ data, index }) => {
    const isFuture = data?.endTime * 1000 > Date.now();
    return (
      <>
        {data?.isActive && <ProjectActive data={data} index={index} />}
        {(!data?.isActive && isFuture) && <ProjectUp data={data} index={index} />}
        {(!data?.isActive && !isFuture) && <ProjectEnd data={data} index={index} />}
      </>
    )
  }


  return (
    <div className="bg-new">
      <div className="new-launch-pool">
        <div className="new-layout">
          <div className="pool-banner">
            <p className="new-title">BSCS <span>Launchpool</span></p>
            <p className="new-desc">Lock to uncover promising new tokens</p>
            <div className="dashboard">
              <div className="new-box">
                <p className="value">{formatNumber(totalStaked, 4)} <span>BSCS</span></p>
                <p className="name">Total Staked Amount</p>
              </div>
              <div className="line"></div>
              <div className="new-box">
                <p className="value">${formatNumber(totalStaked * price, 4)}</p>
                <p className="name">Total Value Staked</p>
              </div>
              <div className="line"></div>
              <div className="new-box">
                <p className="value">{formatNumber(numberStaked + 2000, 2)}</p>
                <p className="name">Total Platform Investors</p>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="tabs">
              <div onClick={() => setTab(1)} className={`tab ${tab === 1 ? 'active' : ''}`}>Upcoming</div>
              <div onClick={() => setTab(2)} className={`tab ${tab === 2 ? 'active' : ''}`}>Active</div>
              <div onClick={() => setTab(3)} className={`tab ${tab === 3 ? 'active' : ''}`}>Ended</div>
              <div onClick={() => setTab(4)} className={`tab ${tab === 4 ? 'active' : ''}`}>Joined</div>
            </div>
          </div>
          <div className="projects">
            {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />}
            {
              (!loading && tab === 1 && allTabPool.length > 0) ?
                allTabPool.map((item: any, index: any) =>
                  <ProjectUp index={index} data={item} />
                )
                :
                (!loading && tab === 1 && <>{noData}</>)
            }
            {
              (!loading && tab === 2 && allTabPool.length > 0) ?
                allTabPool.map((item: any, index: any) =>
                  <ProjectActive index={index} data={item} />
                )
                :
                (!loading && tab === 2 && <>{noData}</>)
            }
            {
              (!loading && tab === 3 && allTabPool.length > 0) ?
                allTabPool.map((item: any, index: any) =>
                  <ProjectEnd index={index} data={item} />
                )
                :
                (!loading && tab === 3 && <>{noData}</>)
            }
            {
              (!loading && tab === 4 && allTabPool.length > 0) ?
                allTabPool.map((item: any, index: any) =>
                  <ProjectJoined index={index} data={item} />
                )
                :
                (!loading && tab === 4 && <>{noDataOrNotConnect}</>)
            }
          </div>
        </div>
      </div>
    </div>
  )
}
