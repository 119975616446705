import Banner from "./Banner/index";
import DataLaunch from "./DataLaunch/index";
import ChainList from "./ChainList/index";
import Why from "./Why/index";
import OurCommunity from "./OurCommunity/index";
import Support from "./Support/index";
import Process from "./Process/index";
import Extensive from "./Extensive/index";
import ATH from "./ATH/index";
import Raise from "./Raise/index";
import Partners from "./Partners/index";
import "./style.css";
import Network from "./Network";
import Average from "./Average";
import Community from "./ComunitySupport";

const ApplyLaunch = () => {
  return (
    <div style={{ background: '#FBFBFC' }}>
      <Banner />
      <DataLaunch />
      <Network />
      {/* <ChainList /> */}
      <Why />
      <OurCommunity />
      <Support />
      <Partners />
      <Average />
      <Process />
      {/* <Extensive /> */}
      <ATH />
      <Community />
      <Raise />
    </div>
  );
};

export default ApplyLaunch;
