/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, {
    Suspense,
    useEffect,
    useState,
    useCallback,
    useRef,
    useMemo
} from "react";
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import {
    Route,
    Routes,
    Navigate,
    BrowserRouter as Router,
    useLocation,
} from "react-router-dom";
import styled from "styled-components";
import Main from "./components/main/Main";
import Home from "./pages/Home/Home";
import Ido from "./pages/Ido/IdoLaunchPad";
import IdoDetails from "./pages/Ido/details/IdoDetails";
import IdoDetailsOld from "./pages/Ido/detailsOld/IdoDetails";
import IdoDetailsAPT from "./pages/Ido/detailsAPT/IdoDetails";
import IdoDetailsSOL from "./pages/Ido/detailsSOL/IdoDetails";
import IdoDetailsTon from "pages/Ido/detailsTON/IdoDetails";
import IdoDetailsVanguard from "pages/Ido/detailsVanguard/IdoDetails";

import BonusClaim from "./pages/Bonus-nv";
import ClaimDetailsBonus from "./pages/Bonus-nv/details";
import Brand from "./pages/Brand/Brand";
import { useCookies } from "react-cookie";
import encodeAddress from "./utils/encodeData";
import FullSearchProject from "./pages/Ido/FullSearchProject/FullSearchProject";
import StartPools from "./pages/StartPools/StartPools";
import IDOPools from "./pages/StartPools/IDOPools";
import PoolManagers from "./pages/Manager/PoolManagers";
import Farms from "./pages/StartPools/FarmPools";
import InoLaunchPad from "./pages/Ino/InoLaunchPad";
import TabsAirdrop from "./pages/Airdrop/TabsAirdrop";
import InoDetails from "./pages/Ino/details/InoDetails";
import LaunchPool from "./pages/LauchPool";
import StakePoolDetails from "./pages/LauchPool/details/StakePoolDetails";
import MarketPlace from "./pages/Nfts/MarketPlace";
import GenesisMarket from "./pages/Nfts/GenesisMarket";
import MarketPlaceDetails from "./pages/Nfts/MarketPlac-Old/details";
import DetailNFTNew from "./pages/Nfts/DetailNFT-New";
import Listing from "./pages/Nfts/Listing";
// import GenesisMarketDetails from "./pages/Nfts/GenesisMarket/details";
import Upload from "./pages/Nfts/Upload";
import PersonalInfo from "./pages/Nfts/PersonalInfo";
import CreateInfo from "./pages/Nfts/CreateInfo";
import StakeNft from "./pages/Nfts/StakeNft/components";

import UserCenter from "./pages/Nfts/UserCenter";
import Artwork from "./pages/Nfts/MyArtwork";
// import GameHub from "./pages/GameHub";
import ErrorPage from "./pages/ErrorPage";
import ReportIDO from "./pages/ReportIdo";
import StakePoolV2 from "./pages/LauchPool-v2";
import IncubatorPage from "pages/Incubator";
import "./App.css";
import "antd/dist/antd.min.css";
// import 'antd/dist/antd.css';
import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";
import { getLibrary, Web3ProviderNetwork } from "./context/web3provider";
import { RefreshContextProvider } from "./context/RefreshContext";
// import { WrapperProvider } from "./context/WrapperContext";
import Providers from "./Providers";
import { Term } from "./pages/Legal/Term";
import { Cookie } from "./pages/Legal/Cookie";
import { Privacy } from "./pages/Legal/Privacy";
import VerifyInfo from "./pages/Verify";

import ProfileUser from "./pages/ProfileUser";
import ApplyLaunch from "./pages/ApplytoLaunch/index";
import Web3Ignite from "./pages/Web3Ignite";
import GetYourOwner from "./pages/Nfts/GetYourOwner";
import { ModalProvider, ModalProviderSticky } from "./components/modal";

import { clusterApiUrl } from "@solana/web3.js";
import {
    ConnectionProvider,
    WalletProvider
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
    CoinbaseWalletAdapter,
    PhantomWalletAdapter,

    SolflareWalletAdapter,
    TorusWalletAdapter
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";


import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { arbitrum, base, avalanche, bsc, mainnet, optimism, polygon, fantom, zkSync, opBNB, dogechain } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LaunchPoolNew } from "pages/LaunchPool";
import {
    getDefaultConfig,
    RainbowKitProvider,
    DisclaimerComponent,
    darkTheme
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
require("@solana/wallet-adapter-react-ui/styles.css");


function App() {
    // 0. Setup queryClient
    const queryClient = new QueryClient()
    // 1. Get projectId at https://cloud.walletconnect.com
    const projectId = '90442772461be13996b4d4766e81fd8f'
    // 2. Create wagmiConfig
    const metadata = {
        name: 'Web3Modal',
        description: 'Web3Modal BSCS',
        url: 'https://web3modal.com', // origin must match your domain & subdomain
        icons: ['https://avatars.githubusercontent.com/u/37784886']
    }
    const customBsc = {
        ...bsc,
        rpcUrls: {
            ...bsc.rpcUrls,
            default: {
                http: ['https://bsc-dataseed.binance.org/'], // RPC tùy chỉnh
            },
        },
    }

    const chains = [customBsc, arbitrum, base, avalanche, mainnet, optimism, polygon, fantom, zkSync, opBNB, dogechain] as const
    const config = defaultWagmiConfig({
        chains, // required
        projectId, // required
        metadata, // required
        enableInjected: false, // Optional - true by default
        enableWalletConnect: true, // Optional - true by default
        enableEIP6963: true, // Optional - true by default
        enableCoinbase: false, // Optional - true by default
    })
    // 3. Create modal
    createWeb3Modal({
        wagmiConfig: config,
        projectId,
        enableAnalytics: true // Optional - defaults to your Cloud configuration
    })
    // over wagmi
    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
        ],
        [network]
    );

    const configRain = getDefaultConfig({
        appName: 'BSCStation',
        projectId: '5f6c18132c6e21af2b276b34ad0af7ec',
        chains: [customBsc, arbitrum, base, avalanche, mainnet, optimism, polygon, fantom, zkSync, opBNB, dogechain],
        ssr: true,
    });

    const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
        <Text>
            Bys connecting your wallet, you agree to the{' '}
            <Link href="https://www.bscs.finance/TermOfUse">Terms of use</Link> and
            acknowledge you have read and understand the protocol{' '}
            <Link href="https://docs.bscs.finance/">Documentation</Link>
        </Text>
    );

    return (
        <TonConnectUIProvider manifestUrl="https://bscs.finance/manifestTon.json">
            <Web3ReactProvider getLibrary={getLibrary}>
                <Web3ProviderNetwork getLibrary={getLibrary}>
                    <RefreshContextProvider>
                        <ModalProvider>
                            <ConnectionProvider endpoint={endpoint}>
                                <WalletProvider wallets={wallets} autoConnect>
                                    <WalletModalProvider>
                                        <WagmiProvider config={configRain}>
                                            <QueryClientProvider client={queryClient}>
                                                <RainbowKitProvider
                                                    appInfo={{
                                                        appName: 'BSCStation',
                                                        disclaimer: Disclaimer,
                                                    }}
                                                    theme={darkTheme({
                                                        accentColor: '#7b3fe4',
                                                        accentColorForeground: 'white',
                                                        borderRadius: 'medium',
                                                        fontStack: 'system',
                                                        overlayBlur: 'small',
                                                    })}>
                                                    <AppClass>
                                                        <Main>
                                                            <Routes>
                                                                <Route path="/" element={<Home />} />
                                                                <Route path="/Ido" element={<Ido />} />
                                                                <Route path="/my-account" element={<ProfileUser />} />
                                                                <Route path="/report-ido" element={<ReportIDO />} />
                                                                <Route
                                                                    path="/Ido/IdoDetails/:network"
                                                                    element={<IdoDetailsAPT />}
                                                                />
                                                                <Route
                                                                    path="/Ido/Detail/:network"
                                                                    element={<IdoDetailsTon />}
                                                                />
                                                                <Route
                                                                    path="/Ido/IdoDetail/:network"
                                                                    element={<IdoDetailsSOL />}
                                                                />
                                                                <Route path="/Ido/IdoDetails/" element={<IdoDetailsOld />} />
                                                                <Route path="/Ido/Details/" element={<IdoDetails />} />
                                                                <Route path="/Ido/details-vanguard/" element={<IdoDetailsVanguard />} />
                                                                <Route
                                                                    path="/Ido/FullSearchProject"
                                                                    element={<FullSearchProject />}
                                                                />
                                                                <Route path="/StartPools" element={<StartPools />} />
                                                                <Route path="/Brand" element={<Brand />} />
                                                                <Route
                                                                    path="/Pools"
                                                                    element={
                                                                        <Providers>
                                                                            <StartPools />
                                                                        </Providers>
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/IDOPools"
                                                                    element={
                                                                        <Providers>
                                                                            <IDOPools />
                                                                        </Providers>
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/manager"
                                                                    element={
                                                                        <Providers>
                                                                            <PoolManagers />
                                                                        </Providers>
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/Farms"
                                                                    element={
                                                                        <Providers>
                                                                            <Farms />
                                                                        </Providers>
                                                                    }
                                                                />
                                                                <Route path="/InoLaunchPad" element={<InoLaunchPad />} />
                                                                <Route path="/Airdrop" element={<TabsAirdrop />} />
                                                                <Route
                                                                    path="/InoLaunchPad/InoDetails/:keywork"
                                                                    element={<InoDetails />}
                                                                />
                                                                <Route path="*" element={<Navigate to="/" replace />} />
                                                                <Route path="/launch-pool-v1" element={<LaunchPool />} />
                                                                <Route
                                                                    path="/LaunchPool/StakePoolDetails/:keywork"
                                                                    element={<StakePoolDetails />}
                                                                />
                                                                <Route path="/mint-nft" element={<Upload />} />
                                                                <Route path="/nft-megamarket" element={<MarketPlace />} />
                                                                <Route path="/nft-genesis" element={<GenesisMarket />} />
                                                                <Route path="/get-your-owner" element={<GetYourOwner />} />
                                                                <Route
                                                                    path="/nft-megamarket-detail/:id"
                                                                    element={<MarketPlaceDetails />}
                                                                />
                                                                {/* <Route path="/CreateProfile" element={<CreateProfile />} /> */}
                                                                <Route path="/create-profile" element={<CreateInfo />} />
                                                                <Route path="/edit-profile" element={<PersonalInfo />} />
                                                                <Route path="/stakeNFT" element={<StakeNft />} />
                                                                <Route path="/usercenter/:author" element={<UserCenter />} />
                                                                <Route path="/MyArtwork" element={<Artwork />} />
                                                                {/* <Route path="/game-hub" element={<GameHub />} /> */}
                                                                <Route path="/CookiePolicy" element={<Cookie />}></Route>
                                                                <Route path="/PrivacyPolicy" element={<Privacy />}></Route>
                                                                <Route path="/TermOfUse" element={<Term />}></Route>
                                                                <Route path="/ErrorPage" element={<ErrorPage />}></Route>
                                                                <Route path="/apply-launch" element={<ApplyLaunch />}></Route>
                                                                <Route path="/web3-ignite" element={<Web3Ignite />}></Route>
                                                                <Route path="/employee-bonus" element={<BonusClaim />}></Route>
                                                                <Route
                                                                    path="/employee-bonus/details"
                                                                    element={<ClaimDetailsBonus />}
                                                                ></Route>
                                                                <Route path="/bscs-verify" element={<VerifyInfo />}></Route>
                                                                <Route path="/listing/:id" element={<Listing />}></Route>
                                                                <Route path="/detailNFT/:id" element={<DetailNFTNew />} />
                                                                <Route path="/launch-pool" element={<LaunchPoolNew />} />
                                                                <Route path="/launch-pool-details/:poolContract" element={<StakePoolV2 />} />
                                                                <Route path="/incubator" element={<IncubatorPage />} />
                                                            </Routes>
                                                        </Main>
                                                    </AppClass>
                                                </RainbowKitProvider>
                                            </QueryClientProvider>
                                        </WagmiProvider>
                                    </WalletModalProvider>
                                </WalletProvider>
                            </ConnectionProvider>
                        </ModalProvider>
                    </RefreshContextProvider>
                </Web3ProviderNetwork>
            </Web3ReactProvider>
        </TonConnectUIProvider>

    );
}

export default App;

const AppClass = styled.section`
  text-align: center;
//   overflow: hidden;
  font-family: Poppins;
  background: #fff;
`;

