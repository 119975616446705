import Marquee from "react-fast-marquee";
import "./style.scss";
import { isMobile } from "react-device-detect";

let listImg1 = [
  "./images/logo-brand-v3.svg",
  "./images/partners/pa-kdg.png",
  "./images/partners/pa-seed.png",
  "./images/partners/pa-red.png",
  "./images/partners/pa-gamefi.png",
  "./images/partners/pa-bis.png",
  "./images/partners/pa-bin.png",
  "./images/partners/pa-lgnition.png",
  // "./images/partners/pa-dao.png",
  "./images/partners/pa-game-starter.png",
  "./images/partners/pa-onus.png",
  "./images/partners/pa-nft.png",
];

let listImg3 = [
  "./images/partners/pa-spark.png",
  "./images/partners/pa-morni.png",
  "./images/partners/pa-basic.png",
  "./images/partners/pa-ngc.png",
  "./images/partners/pa-gate.png",
  "./images/partners/pa-huobi.png",
  "./images/partners/pa-au21.png",

  "./images/partners/pa-dfg.png",
  "./images/partners/pa-gen.png",
  "./images/partners/pa-master.png",
  "./images/partners/pa-m6.png",
  "./images/partners/pa-nebit.png",
  "./images/partners/pa-lbank.png",
  "./images/partners/pa-lotus.png",

  "./images/partners/pa-x21.png",
  "./images/partners/pa-k300.png",
  "./images/partners/pa-ven.png",
  "./images/partners/pa-eve.png",
  "./images/partners/pa-poly.png",
];
let listImg4 = [
  "./images/partners/pa-dfg.png",
  "./images/partners/pa-gen.png",
  "./images/partners/pa-master.png",
  "./images/partners/pa-m6.png",
  "./images/partners/pa-nebit.png",
  "./images/partners/pa-lbank.png",
  "./images/partners/pa-lotus.png",
];
let listImg5 = [
  "./images/partners/pa-x21.png",
  "./images/partners/pa-k300.png",
  "./images/partners/pa-ven.png",
  "./images/partners/pa-eve.png",
  "./images/partners/pa-poly.png",
];
let listImg6 = [
  "./images/partners/pa-huo.png",
  "./images/partners/pa-gate.png",
  "./images/partners/pa-kucoin.png",
  "./images/partners/pa-mexc.png",
  // "./images/partners/pa-hoo.png",
  "./images/partners/pa-lbank.png",
  "./images/partners/pa-bit.png",

  "./images/partners/pa-1ich.png",
  "./images/partners/pa-pancake.png",
  "./images/partners/pa-qick.png",
  "./images/partners/pa-bis.png",
];
let listImg7 = [
  "./images/partners/pa-1ich.png",
  "./images/partners/pa-pancake.png",
  "./images/partners/pa-qick.png",
  "./images/partners/pa-bis.png",
];
let listImg8 = [
  "./images/partners/pa-bsc-news.png",
  "./images/partners/pa-cry.png",
  "./images/partners/pa-cry-rank.png",
  "./images/partners/pa-top.png",
  "./images/partners/pa-daily.png",
];
let listImg9 = [
  "./images/partners/pa-certik.png",
  "./images/imgido/quil-2.png",
  "./images/partners/pa-peck.png",
  "./images/partners/pa-loss.png",
  "./images/partners/pa-solid.png",
];

const imagesLaunchpadTop = Array.from({ length: 7 }, (_, index) => `/images/new/apply/${index + 1}.svg`);
const imagesLaunchpadBottom = Array.from({ length: 7 }, (_, index) => `/images/new/apply/${index + 5}.svg`);
const imagesCapitalTop = Array.from({ length: 3 }, (_, index) => `/images/new/apply/${index + 12}.svg`);
const imagesCapitalBottom = Array.from({ length: 3 }, (_, index) => `/images/new/apply/${index + 17}.svg`);
const imagesExchanges = Array.from({ length: 3 }, (_, index) => `/images/new/apply/${index + 20}.svg`);
const imagesExchangesBottom = Array.from({ length: 3 }, (_, index) => `/images/new/apply/${index + 23}.svg`);
const imagesMedia = Array.from({ length: 3 }, (_, index) => `/images/new/apply/${index + 26}.svg`);
const imagesSecurity = Array.from({ length: 3 }, (_, index) => `/images/new/apply/${index + 29}.svg`);
const Partners = () => {
  return (
    <>
      <div className="partners">
        <div className="part-title">Ecosystem Partners</div>
        <div className="part-des">
          We’re supported by{" "}
          <span style={{ color: "#faad14", fontWeight: "600" }}>120+</span>{" "}
          partners in our ecosystem
        </div>
        <div className="launchpad-container">
          <div className="launchpad-marquee-container">
            <div className="flex">
              <p className="new-title">Launchpad</p><p className="new-value">20+</p>
            </div>
            <Marquee
              className="custom-marquee-partner"
              direction="right"
              gradient={!isMobile}
              pauseOnHover
              speed={20}
              loop={0}
              autoFill
              gradientWidth={150}
              gradientColor={'#F8F9FB'}
            >
              {imagesLaunchpadTop.map((item, index) => (
                <div className="marquee-item">
                  <img
                    key={index}
                    src={item}
                    alt=''
                  />
                </div>
              ))}
            </Marquee>
            <Marquee
              className="custom-marquee-partner bottom"
              direction="left"
              gradient={!isMobile}
              pauseOnHover
              speed={20}
              loop={0}
              autoFill
              gradientWidth={150}
              gradientColor={'#F8F9FB'}
            >
              {imagesLaunchpadBottom.map((item, index) => (
                <div className="marquee-item">
                  <img
                    key={index}
                    src={item}
                    alt=''
                  />
                </div>
              ))}
            </Marquee>
          </div>
          <div className="launchpad-row">
            <div className="launchpad-marquee-container new-item">
              <div className="flex">
                <p className="new-title">Capital Venture</p><p className="new-value">12+</p>
              </div>
              <Marquee
                className="custom-marquee-partner"
                direction="right"
                gradient={!isMobile}
                pauseOnHover
                speed={20}
                loop={0}
                autoFill
                gradientWidth={150}
                gradientColor={'#F8F9FB'}
              >
                {imagesCapitalTop.map((item, index) => (
                  <div className="marquee-item">
                    <img
                      key={index}
                      src={item}
                      alt=''
                    />
                  </div>
                ))}
              </Marquee>
              <Marquee
                className="custom-marquee-partner bottom"
                direction="left"
                gradient={!isMobile}
                pauseOnHover
                speed={20}
                loop={0}
                autoFill
                gradientWidth={150}
                gradientColor={'#F8F9FB'}
              >
                {imagesCapitalBottom.map((item, index) => (
                  <div className="marquee-item">
                    <img
                      key={index}
                      src={item}
                      alt=''
                    />
                  </div>
                ))}
              </Marquee>
            </div>
            <div className="launchpad-marquee-container new-item">
              <div className="flex">
                <p className="new-title">EXCHANGE</p><p className="new-value">12+</p>
              </div>
              <Marquee
                className="custom-marquee-partner"
                direction="right"
                gradient={!isMobile}
                pauseOnHover
                speed={20}
                loop={0}
                autoFill
                gradientWidth={150}
                gradientColor={'#F8F9FB'}
              >
                {imagesExchanges.map((item, index) => (
                  <div className="marquee-item">
                    <img
                      key={index}
                      src={item}
                      alt=''
                    />
                  </div>
                ))}
              </Marquee>
              <Marquee
                className="custom-marquee-partner bottom"
                direction="left"
                gradient={!isMobile}
                pauseOnHover
                speed={20}
                loop={0}
                autoFill
                gradientWidth={150}
                gradientColor={'#F8F9FB'}
              >
                {imagesExchangesBottom.map((item, index) => (
                  <div className="marquee-item">
                    <img
                      key={index}
                      src={item}
                      alt=''
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
          <div className="launchpad-row">
            <div className="launchpad-marquee-container new-item">
              <div className="flex">
                <p className="new-title">Media</p><p className="new-value">12+</p>
              </div>
              <Marquee
                className="custom-marquee-partner"
                direction="right"
                gradient={!isMobile}
                pauseOnHover
                speed={20}
                loop={0}
                autoFill
                gradientWidth={150}
                gradientColor={'#F8F9FB'}
              >
                {imagesMedia.map((item, index) => (
                  <div className="marquee-item">
                    <img
                      key={index}
                      src={item}
                      alt=''
                    />
                  </div>
                ))}
              </Marquee>
            </div>
            <div className="launchpad-marquee-container new-item">
              <div className="flex">
                <p className="new-title">Security</p><p className="new-value">12+</p>
              </div>
              <Marquee
                className="custom-marquee-partner bottom"
                direction="left"
                gradient={!isMobile}
                pauseOnHover
                speed={20}
                loop={0}
                autoFill
                gradientWidth={150}
                gradientColor={'#F8F9FB'}
              >
                {imagesSecurity.map((item, index) => (
                  <div className="marquee-item">
                    <img
                      key={index}
                      src={item}
                      alt=''
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
          {/* <div className="game-list-item">
            {listImg2.map((item: any, i: any) => (
              <div className="game-item item1">
                <img src={item} alt="" />
              </div>
            ))}
            <div className="game-item item1">
              <img
                style={{ width: "50px", height: "50px" }}
                src="./images/partners/pa-m.png"
                alt=""
              />
            </div>
            <div className="game-item item1">
              <img src="./images/partners/pa-launchzone.png" alt="" />
            </div>
          </div> */}
          {/* <div className="bscs-gamefi">Capital Venture</div>
          <div className="game-list-item">
            {listImg3.map((item: any, i: any) => (
              <div className="game-item item1">
                <img src={item} alt="" />
              </div>
            ))}
          </div> */}
          {/* <div className="game-list-item">
            {listImg4.map((item: any, i: any) => (
              <div className="game-item item1">
                <img src={item} alt="" />
              </div>
            ))}
          </div>
          <div className="game-list-item">
            {listImg5.map((item: any, i: any) => (
              <div className="game-item item1">
                <img src={item} alt="" />
              </div>
            ))}
          </div> */}

          {/* <div className="bscs-gamefi">EXCHANGE</div>
          <div className="game-list-item">
            {listImg6.map((item: any, i: any) => (
              <div className="game-item item1">
                <img src={item} alt="" />
              </div>
            ))}
          </div> */}
          {/* <div className="game-list-item">
            {listImg7.map((item: any, i: any) => (
              <div className="game-item item1">
                <img src={item} alt="" />
              </div>
            ))}
          </div> */}

          {/* <div className="bscs-gamefi">Media</div>
          <div className="game-list-item">
            {listImg8.map((item: any, i: any) => (
              <div className="game-item item1">
                <img src={item} alt="" />
              </div>
            ))}
          </div>

          <div className="bscs-gamefi">Security</div>
          <div className="game-list-item">
            {listImg9.map((item: any, i: any) => (
              <div className="game-item item1">
                <img src={item} alt="" />
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Partners;
