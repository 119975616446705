import "./style.css";

const ATH = () => {
  return (
    <>
      <div className="container">
        <div className="top-ath">
          <div className="top-title">TOP 10 Highest ATH IDO Projects</div>
          <div className="chart-ath">
            <div className="bet-icon">
              <img src="./images/bscs-line.png" alt="" />
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/10.svg" alt="" />
              </div>
              <div className="chart-name">Hero TD</div>
              <div className="chart-process item1"></div>
              <div className="num-ath">X200</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/11.svg" alt="" />
              </div>
              <div className="chart-name">Vameon</div>
              <div className="chart-process item2"></div>
              <div className="num-ath">X150</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/12.svg" alt="" />
              </div>
              <div className="chart-name">Propbase</div>
              <div className="chart-process item3"></div>
              <div className="num-ath">X85</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/13.svg" alt="" />
              </div>
              <div className="chart-name">Luna Rush</div>
              <div className="chart-process item4"></div>
              <div className="num-ath">X82</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/14.svg" alt="" />
              </div>
              <div className="chart-name">Sidus Heros</div>
              <div className="chart-process item5"></div>
              <div className="num-ath">X32</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/15.svg" alt="" />
              </div>
              <div className="chart-name">Mey</div>
              <div className="chart-process item6"></div>
              <div className="num-ath">X31</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/16.svg" alt="" />
              </div>
              <div className="chart-name">Dare NFT</div>
              <div className="chart-process item7"></div>
              <div className="num-ath">X20.31</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/17.svg" alt="" />
              </div>
              <div className="chart-name">Green Bell</div>
              <div className="chart-process item8"></div>
              <div className="num-ath">X20.31</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/18.svg" alt="" />
              </div>
              <div className="chart-name">9DNFT</div>
              <div className="chart-process item9"></div>
              <div className="num-ath">X15</div>
            </div>
            <div className="chart1">
              <div className="icon-chart">
                <img src="./images/new/apply/logo/19.svg" alt="" />
              </div>
              <div className="chart-name">Vinu chain</div>
              <div className="chart-process item10"></div>
              <div className="num-ath">X9</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ATH;
