/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { message, Input, Select, Spin } from "antd";
import { useHookAirdrop } from "./StoreAirdrop";
import Countdown from "react-countdown";
import { LoadingOutlined } from "@ant-design/icons";
import switchNetwork from "../../utils/wallet";
import Button from "../../components/Button";
import "./style.css";
import { MAPPING_CHAINID, MAPPING_CHAINID_AIR } from "../../constants";
import { STATUS, NETWORK } from "./index.d";
import { useContract, useIdoTokenClaimContract } from "../../hooks/useContract";
import abiClaimAirdropNew from "./Abi/abiClaimAirdropNew.json"
import abiClaimAirdrop from "./Abi/abiClaimAirdrop.json";
import { _claimTokensOld, _claimTokensNew, _isClaimed, _userClaims, _userClaimId } from "./utils";
import { rendererCountDown } from '../Ido/utilsIDO'
import moment from 'moment';

import { useAccount, useChainId, useSwitchChain } from "wagmi";

declare const window: Window & typeof globalThis & { ethereum: any };

const ListEnded = (props: any) => {
  const [state, actions]: any = useHookAirdrop();
  const [enableLoadMore, setEnableLoadMore] = useState(true);
  const { address } = useAccount();

  let account = address

  let accountAddress: any = account;
  if (accountAddress === undefined) {
    accountAddress = localStorage.getItem("currentAddress");
  }

  const [isLoadMore, setIsLoadMore] = useState(false);

  const antIconLoad = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [searchChain, setSearchChain] = useState("bep");
  const handleChangeSort = (value: any) => {
    setSearchChain(value);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleChangeSearchQuery = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const paramsEndedMore = {
    ownerAddress: accountAddress,
    lastDate: state.airdropListEnded.length > 0
      ? state.airdropListEnded[state.airdropListEnded.length - 1].lastTime
      : null,
    tabId: 3
  };


  const handleLoadMore = () => {
    setIsLoadMore(true);
    if (state.airdropListEndedMore.length > 0) {
      paramsEndedMore.lastDate =
        state.airdropListEndedMore[state.airdropListEndedMore.length - 1].lastTime;
    }
    actions.getInfoAirdropEndedMore(paramsEndedMore).then((data: any) => {
      setIsLoadMore(false);
      if (data.length === 0) setEnableLoadMore(false);
    });
    // setLoadingMore(false);
  };
  const chainId = useChainId()

  let chain = localStorage.getItem("chain");
  const paramsEnded = {
    ownerAddress: accountAddress,
    lastDate: null,
    tabId: 3,
    network: '',
  };


  useEffect(() => {
    actions.getInfoAirdropEnded(paramsEnded);
  }, [accountAddress]);
  // }, [accountAddress, chainId, chain]);


  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no projects</p>
    </>
  );
  const ether = (
    <>
      <img src="/images/ether.png" alt="" />
    </>
  );
  const poly = (
    <>
      <img src="/images/poly.png" alt="" />
    </>
  );
  const bsc = (
    <>
      <img src="/images/bnb-bnb-logo.png" alt="" />
    </>
  );
  const arb = (
    <>
      <img src="/images/arb.png" alt="" />
    </>
  );
  const base = (
    <>
      <img src="/images/base.png" alt="" />
    </>
  );
  const avax = (
    <>
      <img src="/images/avax.png" alt="" />
    </>
  );

  const fillIconChain = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return bsc;
        break;
      case NETWORK.Poly:
        return poly;
        break;
      case NETWORK.Arbitrum:
        return arb;
        break;
      case NETWORK.BASE:
        return base;
        break;
      case NETWORK.Ethereum:
        return ether;
        break;
      case NETWORK.AVAX:
        return avax;
        break;
    }
  };

  const fillNetwork = (network: any) => {
    switch (network) {
      case NETWORK.BSC:
        return "Binance Smart Chain";
        break;
      case NETWORK.Arbitrum:
        return "Arbitrum";
        break;
      case NETWORK.Poly:
        return "Polygon";
        break;
      case NETWORK.Ethereum:
        return "Ethereum";
        break;
      case NETWORK.AVAX:
        return "Avalanche C Chain";
        break;
      case NETWORK.BASE:
        return "Base";
        break;
    }
  };

  const ItemEnded = ({ item }: any) => {
    const scClaimAddress = item.contractAddress;
    const whitelist = item?.whitelist;
    const tokenDecimal = item?.whitelist?.tokenDecimal;
    const claimContract = useContract(scClaimAddress, abiClaimAirdrop);
    const claimContractNew = useContract(scClaimAddress, abiClaimAirdropNew);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSwith, setIsLoadingSwith] = useState(false);
    const idClaim = item?.id
    const [isClaimed, setIsClaimed] = useState(false);
    const [isClaimedNew, setIsClaimedNew] = useState(false);
    const oldClamed = idClaim <= 15
    const [isClaimedId, setIsClaimedId] = useState(false);
    const [currenNetwork, setCurrentNetwork] = useState<any>()
    let timeClaimEnd = moment(item?.endClaimTime * 1000).format('MMM DD YYYY h:mm A');

    // useEffect(() => {
    //   window.ethereum?.request({ method: "eth_chainId" }).then((res: any) => {
    //     if (res === "0xa4b1") {
    //       setCurrentNetwork("arb")
    //     } else if (res == "0x2105") {
    //       setCurrentNetwork("base")
    //     } else if (res == "0x38") {
    //       setCurrentNetwork("bnb")
    //     } else if (res == "0xa86a") {
    //       setCurrentNetwork("avax")
    //     }
    //   });
    // }, []);

    const _chainId = MAPPING_CHAINID_AIR[item?.network];
    const { switchChain, switchChainAsync } = useSwitchChain();
    // const useSwitchNetwork = () => {
    const switchToChain = async (currentChainId: number, targetChainId: number) => {
      setIsLoadingSwith(true)
      if (currentChainId !== targetChainId) {
        try {
          await switchChainAsync({ chainId: targetChainId }).then((res: any) => {
            setIsLoadingSwith(false)
            return true;
          })
            .catch((err: any) => {
              setIsLoadingSwith(false)
              message.error({
                type: "error",
                content: "Failed to switch network",
                className: "custom-class",
                duration: 2,
              });
              return false;
            })
        } catch (error) {
          setIsLoadingSwith(false)
          message.error({
            type: "error",
            content: "Failed to switch network",
            className: "custom-class",
            duration: 2,
          });
          return false;
        }
      }
      setIsLoadingSwith(false)
      return true;
    };
    //   return { switchToChain };
    // };


    const handleClaim = async () => {
      setIsLoading(true);
      try {
        if (_chainId !== chainId) {
          const switched = await switchToChain(chainId, _chainId);
          if (!switched) {
            setIsLoading(false);
            message.error({
              type: "error",
              content: "Failed to switch network",
              className: "custom-class",
              duration: 2,
            });
            return;
          }
        }
        if (oldClamed) {
          await _claimTokensOld(claimContract, item.whitelist, item?.whitelist?.tokenDecimal)
            .then(
              (res) => {
                res.wait()
                  .then((res1: any) => {
                    if (res1?.status === 1) {
                      message.success({
                        type: "success",
                        content: "Claim successfully",
                        className: "custom-class",
                        duration: 3,
                      });
                      setIsLoading(false);
                      setIsClaimed(true);
                    } else {
                      message.error({
                        type: "error",
                        content: "Claim error (status hash fail)",
                        className: "custom-class",
                        duration: 3,
                      });
                      setIsLoading(false);
                      setIsClaimed(false);
                    }
                  })
                  .catch((err: any) => {
                    message.error({
                      type: "error",
                      content: "Unknown error",
                      className: "custom-class",
                      duration: 3,
                    });
                    setIsLoading(false);
                    setIsClaimed(false);
                  })
              }
            )
            .catch((err: any) => {
              console.log('err', err)
              message.error({
                type: "error",
                content: err.reason || err?.message || err,
                className: "custom-class",
                duration: 3,
              });
              setIsLoading(false);
              setIsClaimed(false);
            })

        } else {
          await _claimTokensNew(claimContractNew, item.whitelist?.reward, item.whitelist?.keyId, item.whitelist?.rewardSign, item.whitelist.tokenDecimal)
            .then(
              (res) => {
                res.wait()
                  .then((res1: any) => {
                    if (res1?.status === 1) {
                      message.success({
                        type: "success",
                        content: "Claim successfully",
                        className: "custom-class",
                        duration: 3,
                      });
                      setIsLoading(false);
                      setIsClaimedNew(true);
                    } else {
                      message.error({
                        type: "error",
                        content: "Claim error (status hash fail)",
                        className: "custom-class",
                        duration: 3,
                      });
                      setIsLoading(false);
                      setIsClaimedNew(false);
                    }
                  })
                  .catch((err: any) => {
                    message.error({
                      type: "error",
                      content: "Unknown error",
                      className: "custom-class",
                      duration: 3,
                    });
                    setIsLoading(false);
                    setIsClaimedNew(false);
                  })
              }
            )
            .catch((err: any) => {
              console.log('err', err)
              message.error({
                type: "error",
                content: err.reason || err?.message || err,
                className: "custom-class",
                duration: 3,
              });
              setIsLoading(false);
              setIsClaimedNew(false);
            })
        }
      } catch (error: any) {
        setIsLoading(false);
        message.error({
          type: "error",
          content: error,
          className: "custom-class",
          duration: 3,
        });
      }
    };

    useEffect(() => {
      if (accountAddress) {
        if (claimContract !== null && idClaim <= 15) {
          _isClaimed(claimContract, accountAddress).then((res: any) => {
            setIsClaimed(res);
          });
        } else if (claimContractNew !== null) {
          _userClaims(claimContractNew, accountAddress, item.whitelist?.keyId).then((res: any) => {
            setIsClaimedNew(res);
          });
        }
      }
    }, [accountAddress, claimContract, claimContractNew]);

    useEffect(() => {
      if (claimContract !== null) {
        _userClaimId(claimContract, accountAddress, item?.whitelist?.keyId).then((res: any) => {
          // console.log("res" , res)
          setIsClaimedId(res);
        });
      }
    }, [accountAddress, claimContract, isClaimedId]);


    return (
      <>
        <div className="colum w-33 airdrop-page">
          <div className="content-up">
            <div className="box-content-top-air">
              <div className="box-img">
                <img src={item?.bannerUrl} alt="" />
              </div>
            </div>
            <div className="box-content-bottom">
              <div className="guide-swap">
                <div className="name-token ellipsis">
                  {/* {shortenString(item.name)} */}
                  {item.name}
                </div>
                <p className="desc airdrop">{item.decs}</p>
                {item?.reward !== null && item?.reward !== "" ? (
                  <div className="times-claim-air">
                    <span className="times-air-text">Reward:</span>
                    {item?.reward}
                  </div>
                ) : (
                  <div className="times-claim-air">
                    <span className="times-air-text">Reward:</span>
                    N/A
                  </div>
                )}
                <div className="list-socail">
                  {/* {item?.whitelist === null ? (
                    <>
                      <div className="text-item">
                        <div className="text-l">Duration start:</div>
                        <div className="text-r">{item.openDate}</div>
                      </div>
                      <div className="text-item">
                        <div className="text-l">Duration ended:</div>
                        <div className="text-r">{item?.endDate}</div>
                      </div>
                    </>
                  ) : ('')} */}
                  <div className="text-item">
                    <div className="text-l">Network:</div>
                    <div className="text-r">
                      <span className="icon-symbol">
                        {fillIconChain(item?.network)}
                      </span>
                      {fillNetwork(item?.network)}
                    </div>
                  </div>
                </div>

                {!accountAddress ? (
                  <>
                    <div className="button-view">
                      <button
                        className="btn btn-view air-drop disable"
                        type="button"
                      >
                        Please connect wallet
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {item?.whitelist !== null ? (
                      <>
                        <div className="times-claim-air">
                          <span className="times-air-text">End claim in:</span>
                          <Countdown
                            date={timeClaimEnd}
                            renderer={rendererCountDown}
                          />
                        </div>
                        {item?.whitelist?.rewardSign !== null ? (
                          <>
                            {idClaim >= 15 ?
                              <>
                                <div className="button-view">
                                  {idClaim === 21 || idClaim === 22 || idClaim === 20 ?
                                    <>
                                      {isLoading ?
                                        <>
                                          <Button
                                            className="btn btn-view air-drop"
                                            disabled={!accountAddress || isClaimedId}
                                            loading={isLoading}
                                            text={
                                              isClaimedId
                                                ? "Claimed"
                                                : isLoading
                                                  ? "Waiting"
                                                  : "Claim"
                                            }
                                          />
                                        </>
                                        :
                                        <>
                                          {_chainId !== chainId ? (
                                            <Button
                                              className="btn btn-view air-drop"
                                              loading={isLoadingSwith}
                                              disabled={isLoadingSwith}
                                              text={
                                                isLoadingSwith
                                                  ? "Waiting"
                                                  : "Switch Network to Claim"
                                              }
                                              click={() => switchToChain(chainId, _chainId)}
                                            />
                                          ) : (
                                            <>
                                              <Button
                                                className="btn btn-view air-drop"
                                                disabled={!accountAddress || isClaimedId}
                                                loading={isLoading}
                                                text={
                                                  isClaimedId
                                                    ? "Claimed"
                                                    : isLoading
                                                      ? "Waiting"
                                                      : "Claim"
                                                }
                                                click={handleClaim}
                                              />
                                            </>
                                          )}

                                        </>
                                      }
                                    </>
                                    :
                                    <>
                                      {isLoading ?
                                        <>
                                          <Button
                                            className="btn btn-view air-drop"
                                            disabled={!accountAddress || isClaimedNew}
                                            loading={isLoading}
                                            text={
                                              isClaimedNew
                                                ? "Claimed"
                                                : isLoading
                                                  ? "Waiting"
                                                  : "Claim"
                                            }
                                          />
                                        </>
                                        :
                                        <>

                                          {_chainId !== chainId ? (
                                            <Button
                                              className="btn btn-view air-drop"
                                              loading={isLoadingSwith}
                                              disabled={isLoadingSwith}
                                              text={
                                                isLoadingSwith
                                                  ? "Waiting"
                                                  : "Switch Network to Claim"
                                              }
                                              click={() => switchToChain(chainId, _chainId)}
                                            />
                                          ) : (
                                            <>
                                              <Button
                                                className="btn btn-view air-drop"
                                                disabled={!accountAddress || isClaimedNew}
                                                loading={isLoading}
                                                text={
                                                  isClaimedNew
                                                    ? "Claimed"
                                                    : isLoading
                                                      ? "Waiting"
                                                      : "Claim"
                                                }
                                                click={handleClaim}
                                              />
                                            </>
                                          )}
                                        </>
                                      }
                                    </>
                                  }
                                </div>
                              </>
                              :
                              <>
                                <div className="button-view">
                                  {isLoading ?
                                    <>
                                      <Button
                                        className="btn btn-view air-drop"
                                        disabled={!accountAddress || isClaimed}
                                        loading={isLoading}
                                        text={
                                          isClaimed
                                            ? "Claimed"
                                            : isLoading
                                              ? "Waiting"
                                              : "Claim"
                                        }
                                      />
                                    </>
                                    :
                                    <>
                                      {_chainId !== chainId ? (
                                        <Button
                                          className="btn btn-view air-drop"
                                          loading={isLoadingSwith}
                                          disabled={isLoadingSwith}
                                          text={
                                            isLoadingSwith
                                              ? "Waiting"
                                              : "Switch Network to Claim"
                                          }
                                          click={() => switchToChain(chainId, _chainId)}
                                        />
                                      ) : (
                                        <>
                                          <Button
                                            className="btn btn-view air-drop"
                                            disabled={!accountAddress || isClaimed}
                                            loading={isLoading}
                                            text={
                                              isClaimed
                                                ? "Claimed"
                                                : isLoading
                                                  ? "Waiting"
                                                  : "Claim"
                                            }
                                            click={handleClaim}
                                          />
                                        </>
                                      )}

                                    </>
                                  }
                                </div>
                              </>
                            }
                          </>
                        ) : (
                          <Button
                            className="btn btn-view air-drop"
                            disabled={true}
                            // loading={isLoading}
                            text={
                              'Claim'
                            }
                          />
                        )}

                      </>
                    ) : (
                      <>
                        <div className="times-claim-air">
                          <span className="times-air-text">End claim in:</span>
                          N/A
                        </div>
                        <div className="button-view ">
                          <Button
                            className="btn btn-view air-drop"
                            disabled
                            text="You’re not whitelisted"
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="table-airdrop">
        <div className="columns">
          {state.airdropListEnded.length === 0 || state.airdropListEnded === false ? (
            <div className="empty pd-top-30">{noData}</div>
          ) : (
            state.airdropListEnded.map((item: any, i: any) => (
              <ItemEnded item={item} />
            ))
          )}
          {state.airdropListEndedMore.map((item: any, i: any) => (
            <ItemEnded item={item} />
          ))}
        </div>
        <div className="load-more-round">
          {!isLoadMore && state.airdropListEnded.length !== 0 && enableLoadMore ? (
            <button
              className="btn-loadmore cus"
              type="button"
              onClick={() => handleLoadMore()}
            >
              Load More
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default ListEnded;
