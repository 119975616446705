/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { BigNumber } from "ethers";
import { WalletContractV4, TonClient, fromNano } from "@ton/ton";
// import { useHookAptos } from "./Store-aptos";
// import { CHAIN_NETWORK_APTOS, TYPE_APT_TOKEN } from "../../../constants/index";
import "../Solana/style.css";
import { isMobile } from "react-device-detect";
import CopyToClipboard from "react-copy-to-clipboard";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useCounterContract } from './hooksTon/useTonContract';
import TonWeb from "tonweb"
import { useTonConnect } from './hooksTon/useTonConnect';
import { Address, Sender, SenderArguments, toNano } from '@ton/core';
import { useTonClient } from "./hooksTon/useTonClient";

const TON_API_KEY = "7689430e568be13d4b09794d2f3dca85ac40e1eaa348b822321d6a0ec3960e4a"
const TonWallet = () => {
    const [balanceTon, setBalanceTon]: any = useState(0)
    const [endpoint, setEndpoint]: any = useState()

    let navigate = useNavigate();
    const userFriendlyAddress: any = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();
    const { connected } = useTonConnect();

    let scClaim = "EQCEGrqgg2XwxQfklRtUwRwXyei_g9XFX3c185PyvUrEiveb"
    let scJoin = "EQCgDXagfdPH1iPKGOpyYSte7ea1nf8KNUctiL7zUxklLfJu"

    const tonweb = new TonWeb(new TonWeb.HttpProvider('https://toncenter.com/api/v2/jsonRPC', { apiKey: TON_API_KEY }));
    // const tonweb = new TonWeb(new TonWeb.HttpProvider('https://testnet.toncenter.com/api/v2/jsonRPC', { apiKey: TON_API_KEY }));

    function sleep(ms: any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    // Get user's last transaction hash using tonweb
    const waitTxh = async () => {
        const lastTx = await tonweb.getTransactions(userFriendlyAddress, 1)
        let lastTxHash = lastTx[0]?.transaction_id?.hash
        let txHash = lastTxHash
        while (txHash === lastTxHash) {
            await sleep(1500)
            let tx = (await tonweb.getTransactions(userFriendlyAddress, 1))[0]
            txHash = tx.transaction_id.hash
            console.log("txHash", txHash)
        }
        console.log('done')
    }

    async function getBalance(address: any) {
        // const options = { method: 'GET', headers: new Headers({ 'Authorization': `Bearer ${TON_API_KEY}` }) }
        // const options = { method: 'GET' }
        // const response = await fetch(`https://toncenter.com/api/v2/getAddressBalance?address=${address}`, options)
        // const userData = await response.json()
        // const balance = userData?.result / NNTN || 0
        const balance = await tonweb.getBalance(address);
        setBalanceTon(Number(TonWeb.utils.fromNano(balance) || 0))
    }




    useEffect(() => {
        if (userFriendlyAddress) {
            getBalance(userFriendlyAddress)
        }

    }, [userFriendlyAddress])

    const accountEllipsisRow = userFriendlyAddress
        ? `${userFriendlyAddress?.substring(0, 8)}...${userFriendlyAddress?.substring(
            userFriendlyAddress.length - 8
        )}`
        : "Connect Wallet ";

    const accountEllipsisTon = userFriendlyAddress
        ? `${userFriendlyAddress?.substring(0, 4)}...${userFriendlyAddress?.substring(
            userFriendlyAddress.length - 4
        )}`
        : "Connect Wallet ";

    const accountEllipsisRowMobie = userFriendlyAddress
        ? `${userFriendlyAddress?.substring(0, 8)}...${userFriendlyAddress?.substring(
            userFriendlyAddress.length - 8
        )}`
        : "Connect Wallet ";

    const accountEllipsisMobieTon = userFriendlyAddress
        ? `${userFriendlyAddress?.substring(0, 4)}...${userFriendlyAddress?.substring(
            userFriendlyAddress.length - 4
        )}`
        : "Connect Wallet ";

    const gotoIdoPool = () => {
        navigate("/IDOPools");
    };
    const gotoLaunchPool = () => {
        navigate("/launch-pool");
    };
    const gotoMyNft = () => {
        navigate("/MyArtwork");
    };
    const gotoMyAccount = () => {
        navigate("/my-account");
    };
    const disConnectTon = async () => {
        try {
            tonConnectUI.disconnect();

        } catch (error) {

        }
    }

    const x:any = useCounterContract(scClaim);
    const xJoin:any = useCounterContract(scJoin);
    const { sender } = useTonConnect();


    const handleClicktest = async () => {
        // sendIncrement();
        console.log('Address.parse(userFriendlyAddress)', Address.parse(userFriendlyAddress))
        console.log('x', x)
        console.log('x.claimContract', x.claimContract)
        // await x.claimContract?.getGetUserDetail(Address.parse(userFriendlyAddress))
        //     .then(data => {
        //         console.log("data", data)
        //     })
        //     .catch(e => {
        //         console.log('e', e)
        //     })
        // await x.claimContract?.send({
        //     send: async (args: SenderArguments) => {
        //         console.log('args', args)
        //         tonConnectUI.sendTransaction({
        //             messages: [
        //                 {
        //                     address: args.to.toString(),
        //                     amount: args.value.toString(),
        //                     payload: args.body?.toBoc().toString('base64'),
        //                 },
        //             ],
        //             validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
        //         })
        //             .then(async (res: any) => {
        //                 console.log('res1', res)
        //             })
        //             .catch((err: any) => {
        //                 console.log('err1', err)
        //             })

        //     },
        // },
        // {
        //     value: BigInt(100000),
        //     bounce: true
        // },
        // "claim")
        const msg = 'claim';
        await x.claimContract.send(sender, { value: toNano(0.05) }, msg)
        .then((data:any) => {
            console.log("data", data)
        })
        .catch((e:any) => {
            console.log('e', e)
        })
        await x.counterContract?.sendIncrement(
            // sender
            {
                send: async (args: SenderArguments) => {
                    tonConnectUI.sendTransaction({
                        messages: [
                            {
                                address: args.to.toString(),
                                amount: args.value.toString(),
                                payload: args.body?.toBoc().toString('base64'),
                            },
                        ],
                        validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
                    })
                        .then(async (res: any) => {
                            console.log('res1', res)
                        })
                        .catch((err: any) => {
                            console.log('err1', err)
                        })

                },
            }
        )
        waitTxh()
    }

    const handleClickJoin = async () => {
        console.log('xJoin', xJoin)
        const msg = 'joinpool';
        await xJoin.claimContract.send(sender, { value: toNano(0.01) }, msg)
        .then((data:any) => {
            console.log("data", data)
        })
        .catch((e:any) => {
            console.log('e', e)
        })
    }

    const menuTon = (
        <>
            <div className="main-dropdown-wl">
                <div className="top-dropdown">
                    <div className="box-address">
                        <div className="address-name">Wallet address :</div>
                        <div className="account-name">
                            {isMobile ? accountEllipsisRowMobie : accountEllipsisRow}

                            <CopyToClipboard
                                text={`${userFriendlyAddress}`}
                                onCopy={() => {
                                    message.success({
                                        type: "success",
                                        content: "Copied",
                                        className: "custom-class",
                                        duration: 0.5,
                                    });
                                }}
                            >
                                <span className="img-copy">
                                    <img src="/images/imgido/copy.png" alt="" />
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="bottom-dropdown">
                    <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoMyAccount()}>
                            <img src="/images/award.png" alt="" />{" "}
                            <span className="name-rs">My Account</span>
                        </div>
                    </div>
                    {/* <div className="item-rs">
                        <div className="icon-rs" onClick={() => gotoIdoPool()}>
                            <img src="/images/menu-board.png" alt="" />{" "}
                            <span className="name-rs">IDO Pool</span>
                        </div>
                    </div> */}
                    <div className="item-rs" onClick={() => gotoLaunchPool()}>
                        <div className="icon-rs">
                            <img src="/images/rl.png" alt="" />{" "}
                            <span className="name-rs">Launch Pool</span>
                        </div>
                    </div>
                    <div className="item-rs" onClick={() => gotoMyNft()}>
                        <div className="icon-rs">
                            <img src="/images/rs.png" alt="" />{" "}
                            <span className="name-rs">My NFT</span>
                        </div>
                    </div>
                </div>
                <div className="footer-dropdown">
                    <button type="button" className="btn-dis" onClick={disConnectTon}>
                        Disconnect Wallet
                    </button>
                </div>
            </div>
        </>
    );



    return {
        menuTon,
        userFriendlyAddress,
        accountEllipsisTon,
        accountEllipsisMobieTon,
        balanceTon
    };


};

export default TonWallet;
