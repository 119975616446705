import "./style.css";

const Average = () => {
    return (
        <>
            <div className="container">
                <div className="top-ath average">
                    <div className="top-title">
                        Average Returns (ROI) since IDO by Launchpad
                    </div>
                    <div className="chart-ath">
                        <div className="top-chart">
                            <div className="text-chart1 platform">
                                IDO Platforms
                            </div>
                            <div className="text-chart1 chart-ido">
                                IDOS
                            </div>
                            <div className="text-chart1 chart-price">
                                Price Return
                            </div>
                        </div>
                        <div className="chart1 chart2">
                            <div className="icon-chart">
                                <img src="./images/apply/ath-dk.png" alt="" />
                            </div>
                            <div className="chart-name">PancakeSwap</div>
                            <div className="chart-num">5</div>
                            <div className="chart-process item1 cus-chart-process chart-blu"></div>
                            <div className="num-ath">658,3%</div>
                        </div>
                        <div className="chart1 chart3">
                            <div className="icon-chart">
                                <img src="./images/new/apply/logo/1.svg" alt="" />
                            </div>
                            <div className="chart-name">BSCS</div>
                            <div className="chart-num">19</div>
                            <div className="chart-process item1 cus-chart-process chart-blu"></div>
                            <div className="num-ath">54,2%</div>
                        </div>
                        <div className="chart1 chart4">
                            <div className="icon-chart">
                                <img src="./images/new/apply/logo/2.svg" alt="" />
                            </div>
                            <div className="chart-name">KingdomStarter</div>
                            <div className="chart-num">60</div>
                            <div className="chart-process item1 cus-chart-process chart-blu"></div>
                            <div className="num-ath">52,1%</div>
                        </div>
                        <div className="chart1 chart5">
                            <div className="icon-chart">
                                <img src="./images/new/apply/logo/3.svg" alt="" />
                            </div>
                            <div className="chart-name">BRCStarter</div>
                            <div className="chart-num">21</div>
                            <div className="chart-process item1 cus-chart-process chart-blu"></div>
                            <div className="num-ath">25.9%</div>
                        </div>
                        <div className="chart1 chart6">
                            <div className="icon-chart">
                                <img src="./images/new/apply/logo/4.svg" alt="" />
                            </div>
                            <div className="chart-name">Polkastarter</div>
                            <div className="chart-num">22</div>
                            <div className="chart-process item1 cus-chart-process chart-red"></div>
                            <div className="num-ath">-6.7%</div>
                        </div>
                        <div className="chart1 chart7">
                            <div className="icon-chart">
                                <img src="./images/new/apply/logo/5.svg" alt="" />
                            </div>
                            <div className="chart-name">Poolz Finance</div>
                            <div className="chart-num">50</div>
                            <div className="chart-process item1 cus-chart-process chart-red"></div>
                            <div className="num-ath">-7,6%</div>
                        </div>
                        <div className="chart1 chart8">
                            <div className="icon-chart">
                                <img src="./images/new/apply/logo/6.svg" alt="" />
                            </div>
                            <div className="chart-name">ChanGPT Pad</div>
                            <div className="chart-num">31</div>
                            <div className="chart-process item1 cus-chart-process chart-red"></div>
                            <div className="num-ath">-16,5%</div>
                        </div>
                        <div className="chart1 chart9">
                            <div className="icon-chart">
                                <img src="./images/new/apply/logo/7.svg" alt="" />
                            </div>
                            <div className="chart-name">Fjord Foundry</div>
                            <div className="chart-num">14</div>
                            <div className="chart-process item1 cus-chart-process chart-red"></div>
                            <div className="num-ath">-22%</div>
                        </div>
                        <div className="chart1 chart10">
                            <div className="icon-chart">
                                <img src="./images/new/apply/logo/8.svg" alt="" />
                            </div>
                            <div className="chart-name">Coin Terminal</div>
                            <div className="chart-num">62</div>
                            <div className="chart-process item1 cus-chart-process chart-red"></div>
                            <div className="num-ath">-34,7%</div>
                        </div>
                        <div className="chart1 chart11">
                            <div className="icon-chart">
                                <img src="./images/new/apply/logo/9.svg" alt="" />
                            </div>
                            <div className="chart-name">Kommunitas</div>
                            <div className="chart-num">79</div>
                            <div className="chart-process item1 cus-chart-process chart-red"></div>
                            <div className="num-ath">-35,9%</div>
                        </div>



                    </div>
                </div>
            </div>
        </>
    );
};

export default Average;