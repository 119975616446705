import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { isMobile } from "react-device-detect";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { ExclamationCircleFilled, LoadingOutlined } from "@ant-design/icons";
import Value from "../../../components/Value";

import { useHookProjects } from "../Store";
import { ICON_BNB_URL, ICON_ETH_URL, ICON_POLY_URL } from "../../../constants";
import { CHAIN } from "pages/Ido/index.d";
import './style.scss';
import moment from "moment-timezone";
import { Input, Select, Collapse, Spin, Modal } from "antd";
import { useHookIDO } from "../../Ido/StoreIdo";
import { debounce } from "lodash";

const formatDate = (dateString: string | null) => {
  if (!dateString) return "TBA"; // Nếu null hoặc undefined → trả về "TBA"
  return moment(dateString).tz("Asia/Ho_Chi_Minh").format("MMMM Do YYYY");
};


const FundedProjects = () => {
  const [state, actions]: any = useHookIDO();
  const gotoINO = () => {
    navigate("/InoLaunchPad");
  };
  const gotoINODetail = (keywork: any) => {
    navigate(`${"/InoLaunchPad/InoDetails"}/${keywork}`);
  };

  // const gotoIDODetail = (keywork: any) => {
  //   navigate(`${"/Ido/IdoDetails"}?${keywork}`);
  // };

  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      className: "modal-not-sp",
      title: "Project not suport on Mobile",
      icon: <ExclamationCircleFilled />,
      content: "Please switch to another device.",
    });
  };

  const gotoIDODetail = (network: any, symbol: any, isOldIDo: any, idoTrust: any) => {
    if (network === "apt") {
      navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
    } else if (isOldIDo) {
      navigate(`${"/Ido/IdoDetails"}?${symbol}`);
    } else if (network === CHAIN.TON) {
      navigate(`${"/Ido/Detail"}/${network}?${symbol}`);
    } else if (network === CHAIN.SOLANA) {
      navigate(`${"/Ido/IdoDetail"}/${network}?${symbol}`);
    } else
      if (idoTrust === 'Vanguard' && symbol !== "MEY") {
        navigate(`${"/Ido/details-vanguard"}?${symbol}`);
      } else {
        navigate(`${"/Ido/Details"}?${symbol}`);
      }
  };

  const [enableLoadMore, setEnableLoadMore] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const antIconLoad = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { Search } = Input;
  const onSearch = (value: string) => {
    setEnableLoadMore(false);
    handleGetData(value);
  };
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      handleGetData(query)
    }, 500),
    []
  );
  const onChange = (e: any) => {
    if (e.target.value === "") {
      debouncedSearch("");
      setEnableLoadMore(true);
    } else {
      setEnableLoadMore(false);
      debouncedSearch(e.target.value);
    }
  };
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const { Option } = Select;
  const params = {
    category: 0,
    lastTime:
      state.listEnded.length > 0
        ? state.listEnded[state.listEnded.length - 1].created
        : null,
    pageSize: 5
  };

  // useEffect(() => {
  //   actions.getListEnded({ category: 0, symbol: "" });
  // }, []);

  const [data, setData] = useState<any>([]);

  const handleGetData = async (symbol: any) => {
    try {
      let res = await actions.getListEnded({ category: 0, symbol, pageSize: 5 });
      if (res) {
        setData(res);
      }
    } catch (error: any) {
      console.log('error: ', error);
    }
  }

  useEffect(() => {
    handleGetData("");
  }, []);

  const handleMore = () => {
    setLoadingMore(true);
    if (state.listEndedMore.length > 0) {
      params.lastTime =
        state.listEndedMore[state.listEndedMore.length - 1].created;
    }
    actions.getListEndedMore(params).then((res: any) => {
      setLoadingMore(false);
      setData([...data, ...res])
      if (res.length === 0) setEnableLoadMore(false);
    });
    setLoadingMore(false);
  };

  const handleLoadMoreMobie = () => {
    if (state.listEndedMore.length > 0) {
      params.lastTime =
        state.listEndedMore[state.listEndedMore.length - 1].created;
    }
    actions.getListEndedMore(params).then((data: any) => {
      try {
        setLoadingSpin(true);
        setTimeout(() => {
          setLoadingSpin(false);
        }, 500);
      } catch (error) {
        setLoadingSpin(false);
      }
    });
  };

  let navigate = useNavigate();
  const noData = (
    <>
      <img className="no-data" src="/images/empty.png" alt="" />
      <p>Currently there is no projects</p>
    </>
  );
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <div className="main-setion fund">
        <div className="container">
          <div className="main-title">
            <h3 className="title new">Funded Projects</h3>
            <p className="desc new normal-text">We bring new technologies to our community</p>
          </div>
          <div className="search-project new">
            <Search
              placeholder="Search for project"
              onSearch={onSearch}
              onChange={onChange}
              style={{ width: 380 }}
            />
            <div className="icon-search">
              <img src="/images/imgido/search-normal.png" alt="" />
            </div>
          </div>
          <div className="fund-table-container">
            <table className="fund-table">
              <thead>
                <tr className="fund-table-header-tr">
                  <th className="fund-table-header-th">Project Name</th>
                  <th className="fund-table-header-th">Total Raise</th>
                  <th className="fund-table-header-th">Date</th>
                  <th className="fund-table-header-th">All Time High</th>
                  <th className="fund-table-header-th" style={{ textAlign: 'end' }}>Exchanges</th>
                </tr>
              </thead>
              <tbody>
                {(data?.length > 0) && data.map((project: any, index: any) => (
                  <tr key={index} className="fund-table-body-tr">
                    <td
                      onClick={() => gotoIDODetail(project.network, project.symbol, project?.isOldIDo, project?.idoTrust)}
                      className="fund-table-body-td">
                      <div
                        className="td-name-container">
                        <img className="td-name-logo" alt='' src={project?.logoUrl} />
                        <div className="td-name">
                          <p className="name">{project?.name}</p>
                          <p className="symbol">{project?.symbol}</p>
                        </div>
                      </div>
                    </td>
                    <td className="fund-table-body-td">
                      <Value unit="$" decimals={0} value={project?.idoSupply * project?.pricePer} />
                      {/* <span>{project?.symbolUSD}</span> */}
                    </td>
                    <td className="fund-table-body-td">{formatDate(project?.idoDate)}</td>
                    <td className="fund-table-body-td">
                      <span
                        className={
                          Number(
                            (
                              (project.currentPrice / project.pricePer) *
                              100
                            ).toFixed(2)
                          ) < 100
                            ? "main-color-down"
                            : Number(
                              (
                                (project.currentPrice / project.pricePer) *
                                100
                              ).toFixed(2)
                            ) >= 100
                              ? "main-color-up"
                              : ""
                        }
                      >
                        {(
                          (project.currentPrice / project.pricePer) *
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </td>
                    <td className="fund-table-body-td exchange">
                      {project?.exchanges.length > 0 ?
                        project?.exchanges.map((item: any, index: any) => (
                          <a href={item.linkUrl} target="_blank"><img alt='' src={item.logoUrl} /></a>
                        ))
                        : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {
              !(data?.length > 0) &&
              <div>{noData}</div>
            }
            {loadingSpin ? (
              <div style={{ marginTop: "20px" }} className="loading-idoMore">
                <Spin indicator={antIcon} delay={1} spinning />
              </div>
            ) : (
              ""
            )}
          </div>
          {enableLoadMore && state.listEnded.length !== 0 && !loadingMore ? (
            <button
              className="btn-loadmore"
              style={{ marginTop: "20px" }}
              onClick={handleMore}
            >
              More
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default FundedProjects;
