import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import './style.scss';
import Marquee from "react-fast-marquee";

const Partner = () => {
    function load(img: any) {
        const url = img.getAttribute("lazy-part");
        img.setAttribute("src", url);
    }

    function lazy() {
        if ("IntersectionObserver" in window) {
            let lazyImgs = document.querySelectorAll("[lazy-part]");
            let obj = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        load(entry.target);
                    }
                });
            });

            lazyImgs.forEach((img) => {
                obj.observe(img);
            });
        }
    }

    const imagesTop = Array.from({ length: 12 }, (_, index) => `/images/new/partner/${index + 1}.png`);
    const imagesBottm = Array.from({ length: 12 }, (_, index) => `/images/new/partner/${index + 12}.png`);


    // function ready() {
    //   lazy();
    // }

    // document.addEventListener("DOMContentLoaded", ready);
    useEffect(() => {
        lazy();
    }, []);
    return (
        <>
            <div className="main-setion new-partner">
                {/* <div className="container"> */}
                <div className="main-title">
                    <p className="title-new">BSCS Partners</p>
                </div>
                {/* <div className="table-partner">
                        <div className="columns">
                            <>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://morningstar.ventures/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-1.png" alt="" />
                                            <img
                                                src="/images/par/n-1-1.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://sparkdigitalcapital.com/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-2.png" alt="" />
                                            <img
                                                src="/images/par/n-2-2.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://ngc.fund/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-3.png" alt="" />
                                            <img
                                                src="/images/par/n-3-3.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://au21.capital/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-4.png" alt="" />
                                            <img
                                                src="/images/par/n-4-4.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://bitvaluecapital.com/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-5.png" alt="" />
                                            <img
                                                src="/images/par/n-5-5.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://x21digital.com/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-6.png" alt="" />
                                            <img
                                                src="/images/par/n-6-6.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://www.okx.com/okc" target="_blank">
                                            <img src="" lazy-part="/images/par/n-7.png" alt="" />
                                            <img
                                                src="/images/par/n-7-7.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://www.mexc.com/exchange/BSCS_USDT?_from=search_market" target="_blank">
                                            <img src="" lazy-part="/images/par/n-8.png" alt="" />
                                            <img
                                                src="/images/par/n-8-8.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://www.basics.capital/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-9.png" alt="" />
                                            <img
                                                src="/images/par/n-9-9.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://masterventures.com/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-10.png" alt="" />
                                            <img
                                                src="/images/par/n-10-10.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://momentum6.com/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-11.png" alt="" />
                                            <img
                                                src="/images/par/n-11-11.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>

                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://www.gains-associates.com/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-12.png" alt="" />
                                            <img
                                                src="/images/par/n-12-12.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://cryptorank.io/fundraising-platforms/bsc-station" target="_blank">
                                            <img
                                                src=""
                                                lazy-part="/images/par/n-13.png"
                                                alt=""
                                            />
                                            <img
                                                src="/images/par/n-13-13.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://www.dfg.group/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-14.png" alt="" />
                                            <img
                                                src="/images/par/n-14-14.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://www.binance.com/en/how-to-buy/bsc-station" target="_blank">
                                            <img src="" lazy-part="/images/par/n-15.png" alt="" />
                                            <img
                                                src="/images/par/n-15-15.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://bscpad.com/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-16.png" alt="" />
                                            <img
                                                src="/images/par/n-16-16.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://www.cocosbcx.io/" target="_blank">
                                            <img src="p-20-3.png" lazy-part="/images/par/n-17.png" alt="" />
                                            <img
                                                src="/images/par/n-17-17.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://skynet.certik.com/projects/bscs" target="_blank">
                                            <img
                                                src=""
                                                lazy-part="/images/par/n-18.png"
                                                alt=""
                                            />
                                            <img
                                                src="/images/par/n-18-18.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://www.gate.io/trade/BSCS_USDT" target="_blank">
                                            <img src="" lazy-part="/images/par/n-19.png" alt="" />
                                            <img
                                                src="/images/par/n-19-19.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://www.quillaudits.com/smart-contract-audit" target="_blank">
                                            <img src="" lazy-part="/images/par/n-20.png" alt="" />
                                            <img
                                                src="/images/par/n-20-20.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://spield.ca/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-21.png" alt="" />
                                            <img
                                                src="/images/par/n-21-21.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://quarkchain.io/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-22.png" alt="" />
                                            <img
                                                src="/images/par/n-22-22.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://base.org/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-23.png" alt="" />
                                            <img
                                                src="/images/par/n-23-23.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="colum w-20">
                                    <div className="box-icon partner-custom">
                                        <a href="https://solidproof.io/" target="_blank">
                                            <img src="" lazy-part="/images/par/n-24.png" alt="" />
                                            <img
                                                src="/images/par/n-24-24.png"
                                                className="img-top"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div> */}
                <div className="custom-marquee-box">
                    <Marquee
                        className="custom-marquee-p top"
                        direction="right"
                        gradient
                        pauseOnHover
                        speed={20}
                        loop={0}
                        autoFill
                        gradientWidth={150}
                    >
                        {imagesTop.map((item, index) => (
                            <div className="marquee-item">
                                <img
                                    key={index}
                                    src={item}
                                    alt={`Image ${index + 1}`}
                                />
                            </div>
                        ))}
                    </Marquee>
                    <Marquee
                        className="custom-marquee-p"
                        direction="left"
                        gradient
                        pauseOnHover
                        speed={20}
                        loop={0}
                        autoFill
                        gradientWidth={150}
                    >
                        {imagesBottm.map((item, index) => (
                            <div className="marquee-item">
                                <img
                                    key={index}
                                    src={item}
                                    alt={`Image ${index + 1}`}
                                />
                            </div>
                        ))}
                    </Marquee>
                </div>
                {/* </div> */}
            </div>
        </>
    );
};
export default Partner;
