import React from 'react';
import './style.scss';
import { useHookProjects } from '../Store';

export const Features: React.FC = () => {
  const [state]: any = useHookProjects();

  return (
    <div className='features'>
      <p className='title-fea'>BSCS Features</p>

      <div className='flex'>
        <div className='feature-box'>
          <img alt='' src='/images/home/feature1.svg' />
          <p className='title'>BSCS Fund</p>
          <p className='content'>A supportive Venture Capital</p>
        </div>
        <div className='feature-box'>
          <img alt='' src='/images/home/feature2.svg' />
          <p className='title'>DeFAI</p>
          <p className='content'>Exchange NFTs and more</p>
        </div>
        <div className='feature-box'>
          <img alt='' src='/images/home/feature3.svg' />
          <p className='title'>Incubation Hub</p>
          <p className='content'>Nurture and propel your Project</p>
        </div>
      </div>
      <div className='flex'>
        <div className='feature-box big'>
          <div className='left'>
            <p className='amount'>{state?.marketInfo?.ideasFunded > 0 ? '+' : ''}{state?.marketInfo?.ideasFunded}</p>
            <p className='title'>Launchpad</p>
            <p className='content'>Bring thousands of users and holders to projects</p>
          </div>
          <div className='right'>
            <img alt='' src='/images/home/feature4.svg' />
          </div>
        </div>
        <div className='feature-box end'>
          <img alt='' src='/images/home/feature5.svg' />
          <p className='title'>Community</p>
          <p className='content'>Strong, supportive and loyal</p>
        </div>
      </div>
    </div>
  )
}