/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import MainBanner from "./MainBanner";
import MainChainSlider from "./MainChainSlider";
import MainStack from "./MainStack";
import Upcoming from "./Upcoming";
import ProjectRaise from "./ProjectRaise";
import FundedProjects from "./FundedProjects";
import Ecosystem from "./Ecosystem";
import Partner from "./Partner";
import MakesOur from "./MakesOur";
import { useHookProjects } from "./Store";
import "./style.scss";
import { Features } from "./Features";
import { Join } from "./Join";
import { LaunchPad } from "./LaunchPad";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
    const [state, actions]: any = useHookProjects();
    useEffect(() => {
        actions.getInoV2Home();
        actions.getUpcomingV2Home();
        actions.getIdoHome();
        actions.getListBanner();
        // actions.getMarketInfo();
    }, []);
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        document.title = "BSCS Launchpad | Incubator | DEFI Infrastructure";
        document
            .getElementsByTagName("meta")
            .item(1)
            ?.setAttribute(
                "content",
                "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
            );
    }, []);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: false,
            offset: 50,
        });
        AOS.refresh();
    }, []);



    return (
        <div className="homepage">
            <div data-aos="fade-left">
                <MainBanner />
            </div>
            <div data-aos="fade-up">
                <Upcoming />
            </div>
            <div data-aos="zoom-in">
                <ProjectRaise />
            </div>
            <div data-aos="fade-up">
                <FundedProjects />
            </div>
            <div data-aos="fade-up">
                <Features />
            </div>
            <div data-aos="zoom-in">
                <Partner />
            </div>
            <div data-aos="fade-left">
                <Join />
                <LaunchPad />
            </div>
            <div data-aos="zoom-in">
                <MakesOur />
            </div>
        </div>
    );
};

export default Home;
