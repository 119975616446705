import "./style.scss";

const Raise = () => {
  const gotoApply = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSetpx_GHq1x_F1ZbircVoDm8HlHoDNLvj58pzRH-FY6EzVO5Q/viewform"
    );
  };
  return (
    <>
      <div className="raise-apply">
        <div className="container">
          <div className="flex">
            <div className="raise-left">
              <div className="raise-text">
                <span className="text-custom">How to raise</span>
                {' '}on BSCS
              </div>
              <div onClick={gotoApply} className="btn-apply">
                Apply to launch
              </div>
              <img alt='' src='/images/new/apply/unicorn.svg' />
            </div>
            <div className="raise-right">
              <div className="step-new">
                <div className="left">
                  01
                </div>
                <div className="right">
                  <div className="title">Apply</div>
                  <div className="content">Submit your project proposal with the full information requested on our Form</div>
                </div>
              </div>
              <div className="step-new">
                <div className="left">
                  02
                </div>
                <div className="right">
                  <div className="title">Interview</div>
                  <div className="content">After due diligence, we will have a team interview to discuss the project's details and Q&A.</div>
                </div>
              </div>
              <div className="step-new">
                <div className="left">
                  03
                </div>
                <div className="right">
                  <div className="title">Preparation</div>
                  <div className="content">Once approved, projects get the full BSCS team support leading up to the sale.</div>
                </div>
              </div>
              <div className="step-new">
                <div className="left">
                  04
                </div>
                <div className="right">
                  <div className="title">Launch</div>
                  <div className="content">Launch the Public Sale on the official BSCS Launchpad website.</div>
                </div>
              </div>
              <div className="step-new">
                <div className="left">
                  05
                </div>
                <div className="right">
                  <div className="title">Scale</div>
                  <div className="content">Scale up the project value to its highest potential with the help of BSCS.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Raise;
