/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Component } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isMobile } from "react-device-detect";
import { message, Modal, Progress } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import moment from 'moment'
import ReactPlayer from "react-player";
import "video-react/dist/video-react.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHookIDO } from "../StoreIdo";
import { CHAIN } from "../index.d";
import { APPLY } from "../../../constants/index";
import { ICON_BNB_URL, ICON_ETH_URL, ICON_POLY_URL } from "../../../constants";
import switchNetwork from "../../../utils/wallet";
import { MAPPING_CHAINID, CHAINID_CONVERT } from "../index.d";
import { fillNetwork, fillIconChain } from "../utilsIDO";
import { useAccount, useChainId } from "wagmi";
import './style.scss';
import Countdown from "react-countdown"; import {
  getProgressTime,
  rendererCountDown,
} from "../utilsIDO";
import { LaunchPad } from "pages/Home/LaunchPad";

const FullSearchProject = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [state, actions]: any = useHookIDO();
  const [isOtherChain, setOtherChain] = useState(false);
  const { address } = useAccount();

  const chainId = useChainId()
  let account = address
  const arr = state.listResearch?.imageUrls;
  const setting = {
    asNavFor: nav2,
    slidesToShow: 1,
    dots: false,
    focusOnSelect: true,
    arrows: false,
    vertical: false,
  };
  const settings = {
    asNavFor: nav1,
    slidesToShow:
      state.listResearch?.imageUrls?.length >= 4
        ? 4
        : state.listResearch?.imageUrls?.length,
    dots: false,
    focusOnSelect: true,
    arrows: false,
    vertical: false,
  };

  const location = useLocation();
  const pathSymbol = location.search.split("?");
  const pathHash = pathSymbol[1];
  useEffect(() => {
    actions.getListResearch({ symbol: pathHash });
  }, []);

  let navigate = useNavigate();
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      className: "modal-not-sp",
      title: "Project not suport on Mobile",
      icon: <ExclamationCircleFilled />,
      content: "Please switch to another device.",
    });
  };

  const gotoApply = () => {
    navigate("/apply-launch");
  };
  const handleCallClick = (network: any, symbol: any, isOldIDo: any, idoTrust: any) => {
    if (network === CHAIN.APTOS) {
      navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
    } else if (network === CHAIN.SOLANA) {
      navigate(`${"/Ido/IdoDetail"}/${network}?${symbol}`);
    } else if (network === CHAIN.TON) {
      navigate(`${"/Ido/Detail"}/${network}?${symbol}`);
    }
    else if (isOldIDo) {
      navigate(`${"/Ido/IdoDetails"}?${symbol}`);
    } else
      if (idoTrust === 'Vanguard' && symbol !== "MEY") {
        navigate(`${"/Ido/details-vanguard"}?${symbol}`);
      } else {
        navigate(`${"/Ido/Details"}?${symbol}`);
      }
  };

  const itemSliderBig = (
    <>
      <div className="item-slider-big">
        <ReactPlayer url={state.listResearch.youtube} />
      </div>
    </>
  );
  const ItemSliderSmall = (item: any) => {
    return (
      <>
        <div className="item-slider-small">
          <img src={item.item} alt="" />
        </div>
      </>
    );
  };
  const network = state.listResearch?.idoInfo?.network;
  const network_token = state.listResearch?.idoInfo?.tokenNetwork;
  const network_url =
    network === "bep"
      ? ICON_BNB_URL
      : network === "eth"
        ? ICON_ETH_URL
        : ICON_POLY_URL;

  const network_url_token =
    network_token === "bep"
      ? ICON_BNB_URL
      : network === "eth"
        ? ICON_ETH_URL
        : ICON_POLY_URL;

  useEffect(() => {
    if (state.listResearch?.idoInfo?.network) {
      if (isMobile && chainId) {
        if (
          state.listResearch &&
          state.listResearch?.idoInfo?.network !== CHAINID_CONVERT[chainId]
        ) {
          setOtherChain(true);
        }
      } else {
        switchNetwork(MAPPING_CHAINID[state.listResearch?.idoInfo?.network]);
      }
    }
    return () => {
      // TODO
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.listResearch?.idoInfo?.network, account, chainId]);

  const formatNumber = (x: any, max: any) => {
    if (x) {
      return x.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: max,
      });
    } else return 0;
  };

  // const useCountdown = (targetDate: string) => {
  //   const targetTime = new Date(targetDate).getTime(); // Ensure this is in local time
  //   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  //   function calculateTimeLeft() {
  //     const now = new Date().getTime();
  //     const difference = targetTime - now;

  //     return {
  //       days: Math.floor(difference / (1000 * 60 * 60 * 24)),
  //       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
  //       minutes: Math.floor((difference / (1000 * 60)) % 60),
  //       seconds: Math.floor((difference / 1000) % 60),
  //     };
  //   }

  //   useEffect(() => {
  //     const timer = setInterval(() => {
  //       setTimeLeft(calculateTimeLeft());
  //     }, 1000);

  //     return () => clearInterval(timer);
  //   }, []);

  //   return timeLeft;
  // };

  // const countdown = useCountdown('2025-03-06T09:00:00')
  // console.log(countdown.days)

  let _percentProcess = 0;
  // if (totalRaise > 0) {
  //     if (!ignoreFullSlot && totalJoined >= totalRaise) isFullSlot = true;
  //     const _percent = (amounJoinNotConnect / totalRaise) * 100;
  //     if (_percent > item.percentProcess) {
  //         _percentProcess = _percent;
  //     } else {
  //         _percentProcess = item.percentProcess;
  //     }
  // }

  let percentProcess: any = formatNumber(_percentProcess, 1)

  const itemContentSearch = (
    <>
      <div className="content-swap-search-ido">
        {/* <div className="box-img">
          <span className="img">
            <img src={state.listResearch?.idoInfo?.logoUrl} alt="" />
          </span>
        </div> */}
        <div className="flex-title">
          <div className="logo-img">
            <img src={state.listResearch?.idoInfo?.logoUrl} alt="" />
          </div>
          <div>
            {state.listResearch?.idoInfo?.idoTrust !== "" ?
              <>
                {state.listResearch?.idoInfo?.idoTrust === "S" ? (
                  <div className="bage-s">Shielded</div>
                ) : state.listResearch?.idoInfo?.idoTrust === "R" ? (
                  <div className="bage-r">Riskless</div>
                ) : state.listResearch?.idoInfo?.idoTrust === "Vanguard" ? (
                  <div className="bage-v">{state.listResearch?.idoInfo?.idoTrust}</div>
                ) : (
                  <div className="bage-d">{state.listResearch?.idoInfo?.idoTrust}</div>
                )}
              </>
              :
              <>
                {""}
              </>
            }
          </div>
        </div>
        <div className="name-project-s">
          {state.listResearch?.idoInfo?.name}{" "}
          <span className="token-name">
            {state.listResearch?.idoInfo?.unit}
          </span>
        </div>
        <div className="desc">{state.listResearch?.idoInfo?.description}</div>
        <div className="social-address">
          <div className="list-social">
            <div className="box-icon-sc">
              <a
                className="linkMedia new"
                target="blank"
                href={state.listResearch?.idoInfo?.socical?.website}
              >
                <img src="/images/imgido/web.png" alt="" />
              </a>

              <a
                className="linkMedia new"
                target="blank"
                href={state.listResearch?.idoInfo?.socical?.telegram}
              >
                <img src="/images/imgido/tele-1.png" alt="" />
              </a>

              <a
                className="linkMedia new"
                target="blank"
                href={state.listResearch?.idoInfo?.socical?.twitter}
              >
                <img src="/images/imgido/twi-1.png" alt="" />
              </a>

              <a
                className="linkMedia new"
                target="blank"
                href={state.listResearch?.idoInfo?.socical?.medium}
              >
                <img src="/images/imgido/me-1.png" alt="" />
              </a>
            </div>
          </div>
          <div className="box-address">
            {state.listResearch?.idoInfo?.network === "apt" ? (
              <>
                {state.listResearch?.idoInfo?.idoContract === "TBA"
                  ? "TBA"
                  : state.listResearch?.idoInfo?.idoContract &&
                  `${state.listResearch?.idoInfo?.idoContract.substring(
                    0,
                    5
                  )}...${state.listResearch?.idoInfo?.idoContract.substring(
                    81,
                    state.listResearch?.idoInfo?.idoContract.length
                  )}`}
              </>
            ) : (
              <>
                {state.listResearch?.idoInfo?.idoContract === "TBA"
                  ? "TBA"
                  : state.listResearch?.idoInfo?.idoContract &&
                  `${state.listResearch?.idoInfo?.idoContract.substring(
                    0,
                    5
                  )}...${state.listResearch?.idoInfo?.idoContract.substring(
                    37,
                    state.listResearch?.idoInfo?.idoContract.length
                  )}`}
              </>
            )}

            {state.listResearch?.idoInfo?.idoContract === "TBA" ? (
              ""
            ) : (
              <CopyToClipboard
                text={state.listResearch?.idoInfo?.idoContract}
                onCopy={() => {
                  message.success({
                    type: "success",
                    content: "Copied",
                    className: "custom-class",
                    duration: 1,
                  });
                }}
              >
                <span className="img-copy">
                  <img className="coppy" src="/images/imgido/copy.png" alt="" />
                </span>
              </CopyToClipboard>
            )}
          </div>
        </div>
        {
          state.listResearch?.idoInfo?.idoCloseTime * 1000 - new Date().getTime() <= 0
          &&
          <div className="end-pool-new">
            <img alt='' src="/images/new/end2.svg" />
            <p className="title-new">Sale Ended</p>
            <p className="contend-new">The token sale has ended.</p>
          </div>
        }
        <div className="list-info-search">
          <ul className="list-s">
            <li>
              <span className="txt-l">Join Network:</span>
              <span className="txt-r icon-img">
                {fillIconChain(state.listResearch?.idoInfo?.network)}
                {fillNetwork(state.listResearch?.idoInfo?.network)}

                {/* <img src={network_url} alt="" /> */}
              </span>
            </li>
            <li>
              <span className="txt-l">Token Network:</span>
              <span className="txt-r icon-img">
                {fillIconChain(state.listResearch?.idoInfo?.tokenNetwork)}
                {fillNetwork(state.listResearch?.idoInfo?.tokenNetwork)}
                {/* <img src={network_url_token} alt="" /> */}
              </span>
            </li>
            <li>
              <span className="txt-l">Swap Rate:</span>
              <span className="txt-r">
                {state.listResearch?.idoInfo?.swapAmount}
              </span>
            </li>
            <li>
              <span className="txt-l">IDO Supply:</span>
              <span className="txt-r">
                {formatNumber(state.listResearch?.idoInfo?.idoSupply, 1)}{" "}
                {state.listResearch?.idoInfo?.symbol}
              </span>
            </li>
            <li>
              <span className="txt-l">Total Supply:</span>
              <span className="txt-r">
                {formatNumber(state.listResearch?.idoInfo?.totalSupply, 1)}
                {/* {state.listResearch?.idoInfo?.totalSupply.toLocaleString()} */}{" "}
                {state.listResearch?.idoInfo?.symbol}
              </span>
            </li>
          </ul>
          <ul className="list-s">
            {state.listResearch?.idoInfo &&
              state.listResearch?.idoInfo?.schedules.map(
                (item: any, index: any) => (
                  <>
                    <li>
                      <span className="txt-l">{item.round}</span>
                      <span className="txt-r">{item.startDate}</span>
                    </li>
                  </>
                )
              )}
          </ul>
        </div>
        <div className="button-view">
          <button
            className="btn btn-view"
            type="button"
            onClick={() =>
              handleCallClick(
                state.listResearch?.idoInfo?.network,
                state.listResearch?.idoInfo?.symbol,
                state.listResearch?.idoInfo?.isOldIDo,
                state.listResearch?.idoInfo?.idoTrust
              )
            }
          >
            View Pool
          </button>
        </div>
      </div>
    </>
  );

  const description = (item: any) => {
    return <div dangerouslySetInnerHTML={{ __html: item }} />;
  };

  const [tab, setTab] = useState(1);
  const [headings, setHeadings] = useState([]);
  const [htmlWithId, setHtmlWithId] = useState('');
  const [active, setActive] = useState<any>();

  const addIdsToHeadings = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const headings = doc.querySelectorAll("h2");

    headings.forEach((h, index) => {
      h.id = `heading-${index}`;
    });

    return doc.body.innerHTML;
  }

  const extractHeadings = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const headings = doc.querySelectorAll("h2");

    return Array.from(headings)
      .filter((h) => {
        const text = h.textContent?.trim() || "";
        const hasImageOnly = h.children.length === 1 && h.children[0].tagName.toLowerCase() === "img";
        return text !== "" && !hasImageOnly;
      })
      .map((h, index) => ({
        id: `heading-${index}`,
        text: h.textContent?.trim() || "",
      }));
  };

  useEffect(() => {
    if (state.listResearch.description) {
      let doc = addIdsToHeadings(state.listResearch.description);
      let arr: any = extractHeadings(state.listResearch.description);
      setHeadings(arr);
      setHtmlWithId(doc);
    }
  }, [state.listResearch]);

  const handleScrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      setActive(id);
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      let closestHeading: string | null = null;
      let minDistance = Number.MAX_VALUE;

      headings.forEach(({ id }) => {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          const distance = Math.abs(rect.top); // Distance from viewport top

          if (distance < minDistance && rect.top >= 0) {
            minDistance = distance;
            closestHeading = id;
          }
        }
      });

      if (closestHeading) {
        setActive(closestHeading);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [headings]);


  return (
    <>
      <div className="main-body">
        <div className="full-width">
          <div className="container full-research new">
            <div className="main-full-project">
              <div className="breadcrum-swap">
                <ul className="list-breadcrum">
                  <li>
                    <Link to={"/Ido"}>Project</Link>
                  </li>
                  <li>{">"}</li>
                  <li>{state.listResearch?.idoInfo?.name}</li>
                </ul>
              </div>
              <div className="flex-box full-research">
                <div className="new-box">
                  <img className="logo" alt='' src='/images/new/connect.png' />
                  <div className="text">
                    <p className="name">1. Connect Wallet</p>
                    {/* <p className="time">27th Dec. 12:00 UTC - 21:00 UTC</p> */}
                  </div>
                </div>
                <img className="arrow" alt='' src='/images/new/arrow.png' />
                <div className="new-box">
                  <img className="logo" alt='' src='/images/new/commit.png' />
                  <div className="text">
                    <p className="name">2. Commit fund</p>
                    {/* <p className="time">27th Dec. 12:00 UTC - 21:00 UTC</p> */}
                  </div>
                </div>
                <img className="arrow" alt='' src='/images/new/arrow.png' />
                <div className="new-box">
                  <img className="logo" alt='' src='/images/new/join.png' />
                  <div className="text">
                    <p className="name">3. Join IDO</p>
                    {/* <p className="time">27th Dec. 12:00 UTC - 21:00 UTC</p> */}
                  </div>
                </div>
                <img className="arrow" alt='' src='/images/new/arrow.png' />
                <div className="new-box">
                  <img className="logo" alt='' src='/images/new/claim2.png' />
                  <div className="text">
                    <p className="name">4. Claim Token</p>
                    {/* <p className="time">27th Dec. 12:00 UTC - 21:00 UTC</p> */}
                  </div>
                </div>
              </div>
              <div className="main-full-project">
                <div className="table-swap">
                  <div className="columns">
                    <div className="colum w-70">
                      <div className="content-description">
                        <div className="new-intro">
                          <h3 className="title-name">
                            {state.listResearch?.idoInfo?.name}
                          </h3>
                          <p className="new-desc">
                            {state.listResearch?.idoInfo?.description}
                          </p>
                          <img className="banner" alt='' src={state.listResearch?.idoInfo?.backgroundUrl} />
                        </div>
                        <div className="tabs">
                          <div onClick={() => setTab(1)} className={`tab ${tab === 1 ? 'active' : ''}`}>Project Details</div>
                          <div onClick={() => setTab(2)} className={`tab ${tab === 2 ? 'active' : ''}`}>Token Sale</div>
                          {/* <div onClick={() => setTab(3)} className={`tab ${tab === 3 ? 'active' : ''}`}>Vesting Information</div> */}
                        </div>
                        {state.listResearch.youtube !== null ||
                          arr?.length > 0 ? (
                          <div className="slider-search-project">
                            {state.listResearch.youtube !== null ? (
                              <div className="slider-big">
                                <Slider
                                  {...setting}
                                  ref={(slider1: any) => setNav1(slider1)}
                                  asNavFor={nav2}
                                >
                                  {itemSliderBig}
                                </Slider>
                              </div>
                            ) : (
                              ""
                            )}
                            {arr?.length > 0 ? (
                              <div className="slider-small">
                                <Slider
                                  {...settings}
                                  ref={(slider2: any) => setNav2(slider2)}
                                  asNavFor={nav1}
                                >
                                  {arr &&
                                    arr.map((item: any, index: any) => (
                                      <ItemSliderSmall item={item} />
                                    ))}
                                </Slider>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {tab === 1 &&
                          <div className="new-flex">
                            <div className="new-menu-list w-30">
                              {headings?.length > 0 && headings.map((item: any, index: any) =>
                                <div onClick={() => handleScrollTo(item?.id)} className={`head ${active === item?.id ? 'active' : ''}`}><img className="arrow" alt='' src='/images/new/arrow.png' /> <p>{item?.text}</p></div>
                              )}
                            </div>
                            <div className="content-desc new w-70">
                              <div className="item-desc">
                                <div className="desc-s">
                                  {description(
                                    htmlWithId &&
                                    htmlWithId
                                  )}
                                </div>
                              </div>
                              {/* <div className="box-apply-launch">
                                <p className="desc">Never want to miss a sale?</p>
                                <h3 className="txt">
                                  Want to launch your project on BSCS
                                </h3>
                                <div className="btn-apply-launch button-view">
                                  <button
                                    onClick={gotoApply}
                                    className="btn btn-view"
                                  >
                                    Apply to Launchpad
                                  </button>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        }
                        {tab === 2 &&
                          <div className="tab-2">
                            <div className="tab-2-flex">
                              <div className="tab-2-box">
                                <p className="name">Total raised</p>
                                <p className="value">
                                  {formatNumber(state.listResearch?.idoInfo?.idoSupply, 1)}{" "}
                                  {state.listResearch?.idoInfo?.symbol}
                                </p>
                              </div>
                              <div className="tab-2-box">
                                <p className="name">Swap Rate</p>
                                <p className="value">
                                  {state.listResearch?.idoInfo?.swapAmount}
                                </p>
                              </div>
                              <div className="tab-2-box">
                                <p className="name">Initial Market Cap</p>
                                <p className="value">
                                  ${formatNumber(state.listResearch?.idoInfo?.totalSupply * state.listResearch?.idoInfo?.pricePer, 1)}
                                </p>
                              </div>
                              {/* <div className="tab-2-box">
                                <p className="name">Initial Circulation Supply</p>
                                <p className="value">TBA</p>
                              </div> */}
                              <div className="tab-2-box">
                                <p className="name">Total Supply</p>
                                <p className="value">
                                  {formatNumber(state.listResearch?.idoInfo?.totalSupply, 1)}
                                  {/* {state.listResearch?.idoInfo?.totalSupply.toLocaleString()} */}{" "}
                                  {state.listResearch?.idoInfo?.symbol}
                                </p>
                              </div>
                            </div>
                            <div className="tab-2-flex-bottom">
                              <div className="tab-2-box-left">
                                <div className="flex"><p className="name">Start time</p><p className="value">
                                  {state.listResearch?.idoInfo?.idoStartTime === 0 ? ('TBA') : (
                                    <>
                                      {moment(state.listResearch?.idoInfo?.idoStartTime * 1000).format('MMM DD YYYY h:mm A')}
                                    </>
                                  )}

                                </p></div>
                                <div className="flex"><p className="name">End time</p><p className="value">
                                  {state.listResearch?.idoInfo?.idoStartTime === 0 ? ('TBA') : (
                                    <>
                                      {moment(state.listResearch?.idoInfo?.idoCloseTime * 1000).format('MMM DD YYYY h:mm A')}
                                    </>
                                  )}
                                </p></div>
                              </div>
                              {/* <div className="tab-2-box-right">
                                <p className="name">Participate</p>
                                <p className="value">459,975,897</p>
                              </div> */}
                            </div>
                          </div>
                        }
                        {/* {tab === 3 &&
                          <div className="tab-3">
                            <div className="tab-3-flex-bottom">
                              <div className="tab-3-box-left">
                                <div className="flex"><p className="name">Vesting Type</p><p className="value">TBA</p></div>
                                <div className="flex"><p className="name">TGE Date</p><p className="value">TBA</p></div>
                                <div className="flex"><p className="name">TGE Unlock</p><p className="value">TBA</p></div>
                                <div className="flex"><p className="name">Vesting Start</p><p className="value">TBA</p></div>
                                <div className="flex"><p className="name">Vesting End</p><p className="value">TBA</p></div>
                                <div className="flex"><p className="name">Remaining Unlock</p><p className="value">TBA</p></div>
                              </div>
                            </div>
                          </div>
                        } */}
                      </div>
                    </div>
                    <div className="colum w-30">{itemContentSearch}</div>
                  </div>
                  <LaunchPad />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FullSearchProject;
