import React, { useState } from 'react';
import './style.scss';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

export const Join: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadScript, setisLoadScript] = useState(true);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const openLink = (num: any) => {
    setIsModalVisible(false);
    switch (num) {
      case 1: {
        window
          .open(
            "https://pancakeswap.finance/swap?outputCurrency=0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D",
            "_blank"
          )
          ?.focus();
        break;
      }
      case 2: {
        window.open("https://www.gate.io/trade/BSCS_USDT", "_blank")?.focus();
        break;
      }
      case 3: {
        window
          .open("https://www.mexc.com/exchange/BSCS_USDT", "_blank")
          ?.focus();
        break;
      }
      case 4: {
        window.open("https://hoo.com/innovation/bscs-usdt", "_blank")?.focus();
        break;
      }
    }
  };
  const navigate = useNavigate()
  return (
    <div className='join'>
      <p className='title-new'>How to join Top Tier Web 3 IDOs</p>
      <p className='new-desc'>Only 3 steps are needed for you to start enjoying all the advantages of BSCS</p>

      <div className='flex'>
        <div className='new-box'>
          <div className='top'>
            <img alt='' src='/images/home/bscs_arrow.png' />
            <p className='box-title'>1. Connect wallet</p>
            <p className='box-content'>$BSCS is BSCS’s Token that enable its holders to participate in IDOs</p>
          </div>
          <div onClick={showModal} className='box-btn'>
            Buy $BSCS
          </div>
        </div>
        <div className='new-box'>
          <div className='top'>
            <img alt='' src='/images/home/stake_arrow.png' />
            <p className='box-title'>2. Participate in stake</p>
            <p className='box-content'>Add your $BSCS to one of our Launchpool</p>
          </div>
          <div onClick={() => navigate('/launch-pool')} className='box-btn'>
            Stake Now
          </div>
        </div>
        <div className='new-box'>
          <div className='top'>
            <img alt='' src='/images/home/all_set.png' />
            <p className='box-title'>3. You’re all set!</p>
            <p className='box-content'>Now you can participate in the IDOs of the best blockchain projects</p>
          </div>
        </div>
      </div>
      {/* @ts-ignore this lib is incompatible with react18 */}
      <Modal
        title="Buy BSCS"
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        className="modal-link"
        width={650}
      >
        <div className="link-buy-bscs" onClick={() => openLink(1)}>
          <img className="img-link" alt="" src="/images/pancake.png" />
          <p>Pancakeswap.finance </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(2)}>
          <img className="img-link" alt="" src="/images/gate.png" />
          <p>
            SCS BSCS Price|Live Chart|Crypto Exchange Platform-Spot
            Trading-Gate.io
          </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(3)}>
          <img className="img-link" alt="" src="/images/mexc.png" />
          <p>
            MEXC | Bitcoin Trading Platform_Ethereum_Litecoin Price Real-time
            Quotes{" "}
          </p>
        </div>
      </Modal>
    </div>
  )
}