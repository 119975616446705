import { isMobile } from "react-device-detect";
import "./style.scss";

const Community = () => {
  return (
    <div className="community-support">
      <div className="container">
        <p className="new-title">
          Extensive marketing and Community Support
        </p>
        <p className="new-content">Utilize BSCStation's knowledge at every stage of the process.</p>
        <div className="flex">
        <div className="flex-item">
          <p className="title">Pre-launch</p>
          <img className="banner" src='/images/new/apply/logo/sp-1.svg' alt='' />
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Announcement Campaign</p>
          </div>
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Marketing Strategy</p>
          </div>
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Video Production</p>
          </div>
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Live AMA Sessions</p>
          </div>
        </div>
        <div className="flex-item">
          <p className="title">Launch</p>
          <img className="banner" src='/images/new/apply/logo/sp-2.svg' alt='' />
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Hands-on Support</p>
          </div>
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Allowlist Engagement</p>
          </div>
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Share Launch Results</p>
          </div>
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Campaign Review</p>
          </div>
        </div>
        <div className="flex-item">
          <p className="title">Post-launch</p>
          <img className="banner" src='/images/new/apply/logo/sp-3.svg' alt='' />
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Performance Review</p>
          </div>
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Strategy Review</p>
          </div>
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Success Promo Campaign</p>
          </div>
          <div className="flex-sentence">
            <img alt='' src='/images/new/apply/logo/check.svg' />
            <p>Live Poolside Podcast</p>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Community;
