import { createContext, useState, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
}
interface ModalContextProps {
  showModal: boolean;
  onOpenModal: any;
  onCloseModal: any;
  setShowModal: any;
}

export const ModalContext = createContext<ModalContextProps | undefined>(
  undefined
);

export const ModalProvider: React.FC<Props> = ({ children }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [ModalElm, setModalElm] = useState<ReactNode>(<></>);
  const onOpenModal = (Modal: ReactNode, props?: any) => {
    setShowModal(true);
    setModalElm(Modal);
  };
  const onCloseModal = () => {
    setShowModal(false);
    setModalElm(<></>);
  };

  const ModalContextValue: ModalContextProps = {
    showModal,
    onOpenModal,
    onCloseModal,
    setShowModal,
  };

  return (
    <ModalContext.Provider value={ModalContextValue}>
      <Wrapper showModal={showModal}>
        <ModalContainer showModal={showModal} onClick={onCloseModal}>
          <WrapperModal
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            {ModalElm}
          </WrapperModal>
        </ModalContainer>

        {children}
      </Wrapper>
    </ModalContext.Provider>
  );
};

export const ModalProviderSticky: React.FC<Props> = ({ children }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [ModalElm, setModalElm] = useState<ReactNode>(<></>);
  const onOpenModal = (Modal: ReactNode, props?: any) => {
    setShowModal(true);
    setModalElm(Modal);
  };
  const onCloseModal = () => {
    setShowModal(false);
    setModalElm(<></>);
  };

  const ModalContextValue: ModalContextProps = {
    showModal,
    onOpenModal,
    onCloseModal,
    setShowModal,
  };

  return (
    <ModalContext.Provider value={ModalContextValue}>
      <WrapperSticky showModal={showModal}>
        <ModalContainer showModal={showModal} onClick={onCloseModal}>
          <WrapperModal
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            {ModalElm}
          </WrapperModal>
        </ModalContainer>

        {children}
      </WrapperSticky>
    </ModalContext.Provider>
  );
};


const WrapperModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 20px;
  @media screen and (max-width: 743px) {
    width: 100%;
    height: 70%;
    overflow: auto;
    padding: 0 10px;
  }
`;

export const ModalContainer = styled.div<{ showModal?: boolean }>`
  position: fixed;
  background-color: rgba(17, 25, 39, 0.5);
  top: 0px;
  left: 0px;
  height: 100vh;
  transition: opacity 0.25s linear;
  z-index: 10000;
  overflow: hidden;
  font-family: "Sofia Sans", sans-serif;
`;
export const Wrapper = styled.div<{
  showModal?: boolean;
}>`
  overflow: visible;
  width: 100%;
  height: ${({ showModal }) => (showModal ? "100vh" : "fit-content")};
  ${ModalContainer} {
    width: ${({ showModal }) => (showModal ? "100vw" : "auto")};
    opacity: ${({ showModal }) => (showModal ? "1" : "0")};
  }
`;

export const WrapperSticky = styled.div<{
  showModal?: boolean;
}>`
  overflow: visible;
  width: 100%;
  height: ${({ showModal }) => (showModal ? "100vh" : "fit-content")};
  ${ModalContainer} {
    width: ${({ showModal }) => (showModal ? "100vw" : "auto")};
    opacity: ${({ showModal }) => (showModal ? "1" : "0")};
  }
`;
