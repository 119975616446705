/* eslint-disable jsx-a11y/img-redundant-alt */
import "../../Home/MainBanner/style.scss";
import "./style.css";

import Marquee from "react-fast-marquee";

const Network = () => {
    const images = Array.from({ length: 14 }, (_, index) => `/images/home/network/${index + 1}.png`);

    return (
        <div className="main-banner-new networks-mobie">
            <div className="networks">
                <div className="content">
                    <img alt='' src='/images/home/left-net.png' />
                    <p className="text text95">RAISE CAPITAL ACROSS ALL MAIN BLOCKCHAIN NETWORKS</p>
                    <img alt='' src='/images/home/right-net.png' />
                </div>
                <Marquee
                    className="custom-marquee"
                    direction="right"
                    gradient
                    pauseOnHover
                    speed={100}
                    loop={0}
                    autoFill
                    gradientWidth={150}
                >
                    {images.map((item, index) => (
                        <div className="marquee-item">
                            <img
                                key={index}
                                src={item}
                                alt={`Image ${index + 1}`}
                            />
                        </div>
                    ))}
                </Marquee>
            </div>
        </div>
    );
};

export default Network;
