import React, { useState } from "react";
import { Modal } from "antd";
import "antd/dist/antd.min.css";
import bgMainheader from "./images/bg-main-banner.png";
import { useNavigate } from "react-router-dom";
import { APPLY } from "../../../constants";
import Marquee from "react-fast-marquee";
import './style.scss'
import { useHookProjects } from "../Store";

const MainBanner = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state]: any = useHookProjects();
  let navigate = useNavigate();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const images = Array.from({ length: 14 }, (_, index) => `/images/home/network/${index + 1}.png`);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const openLink = (num: any) => {
    setIsModalVisible(false);
    switch (num) {
      case 1: {
        window
          .open(
            "https://pancakeswap.finance/swap?outputCurrency=0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D",
            "_blank"
          )
          ?.focus();
        break;
      }
      case 2: {
        window.open("https://www.gate.io/trade/BSCS_USDT", "_blank")?.focus();
        break;
      }
      case 3: {
        window
          .open("https://www.mexc.com/exchange/BSCS_USDT", "_blank")
          ?.focus();
        break;
      }
      case 4: {
        window.open("https://hoo.com/innovation/bscs-usdt", "_blank")?.focus();
        break;
      }
    }
  };

  const gotoLaunchpad = () => {
    navigate(`${"/Ido"}`);
  };
  const gotoApply = () => {
    navigate(`${"/apply-launch"}`);
    // window.open(
    //   "https://docs.google.com/forms/d/e/1FAIpQLSetpx_GHq1x_F1ZbircVoDm8HlHoDNLvj58pzRH-FY6EzVO5Q/viewform"
    // );
  };
  return (
    <>
      <div className="main-banner-new">
        <div className="flex">
          <div className="content">
            <h3 className="title">
              The fully decentralized protocol for launching new ideas
            </h3>
            <p className="desc">
              An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks
            </p>
            <div className="btn-group">
              <div
                className="banner-btn"
                onClick={gotoLaunchpad}
              >
                <span className="icon"><img alt='' src='/images/home/arrow_circle_right.png' /></span> <span className="text">Upcoming sales</span>
              </div>
              <div className="btn btn-apply" onClick={() => gotoApply()}>
                Apply to Launch
              </div>
            </div>
          </div>
          <img className="banner" alt='' src='/images/home/banner.svg' />
        </div>
        <div className="cards-group">
          <div className="card card-1">
            <p className="amount">{state?.marketInfo?.ideasFunded || 0}</p>
            <p className="title">Funded Projects</p>
          </div>
          <div className="card card-2">
            <p className="amount">{state?.marketInfo?.uniqueParticipans || 0}</p>
            <p className="title">Unique Participants</p>
          </div>
          <div className="card card-3">
            <p className="amount">${state?.marketInfo?.raisedCapital || 0}M</p>
            <p className="title">Raised Capital</p>
          </div>
        </div>
        <div className="networks">
          <div className="content">
            <img alt='' src='/images/home/left-net.png' />
            <p className="text">RAISE CAPITAL ACROSS ALL MAIN BLOCKCHAIN NETWORKS</p>
            <img alt='' src='/images/home/right-net.png' />
          </div>
          <Marquee
            className="custom-marquee"
            direction="right"
            gradient
            pauseOnHover
            speed={100}
            loop={0}
            autoFill
            gradientWidth={150}
          >
            {images.map((item, index) => (
              <div className="marquee-item">
                <img
                  key={index}
                  src={item}
                  alt={`Image ${index + 1}`}
                />
              </div>
            ))}
          </Marquee>
        </div>
      </div>
      {/* @ts-ignore this lib is incompatible with react18 */}
      <Modal
        title="Buy BSCS"
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        className="modal-link"
        width={650}
      >
        <div className="link-buy-bscs" onClick={() => openLink(1)}>
          {/* <img className="img-link" alt="" src="/images/coins/BSCS.png?v=152" /> */}
          <p> 🥞Pancakeswap.finance </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(2)}>
          <img className="img-link" alt="" src="/images/coins/gate.png?v=152" />
          <p>
            SCS BSCS Price|Live Chart|Crypto Exchange Platform-Spot
            Trading-Gate.io
          </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(3)}>
          <img className="img-link" alt="" src="/images/coins/mxc.png?v=152" />
          <p>
            MEXC | Bitcoin Trading Platform_Ethereum_Litecoin Price Real-time
            Quotes{" "}
          </p>
        </div>
      </Modal>
    </>
  );
};
export default MainBanner;
