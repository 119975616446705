import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useHookProjects } from "../Store";
import Value from "../../../components/Value";
import './style.scss'

const MakesOur = () => {
  const [state, actions]: any = useHookProjects();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadScript, setisLoadScript] = useState(true);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const openLink = (num: any) => {
    setIsModalVisible(false);
    switch (num) {
      case 1: {
        window
          .open(
            "https://pancakeswap.finance/swap?outputCurrency=0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D",
            "_blank"
          )
          ?.focus();
        break;
      }
      case 2: {
        window.open("https://www.gate.io/trade/BSCS_USDT", "_blank")?.focus();
        break;
      }
      case 3: {
        window
          .open("https://www.mexc.com/exchange/BSCS_USDT", "_blank")
          ?.focus();
        break;
      }
      case 4: {
        window.open("https://hoo.com/innovation/bscs-usdt", "_blank")?.focus();
        break;
      }
    }
  };

  let navigate = useNavigate();
  const gotoIDOPool = () => {
    navigate("/IDOPools");
  };

  // const addScript = (url: any) => {
  //   const script = document.createElement("script");
  //   script.src = url;
  //   script.defer = true;
  //   document.body.appendChild(script);
  // };

  function load(img: any) {
    const url = img.getAttribute("lazy-out");
    img.setAttribute("src", url);
  }

  function lazy() {
    if ("IntersectionObserver" in window) {
      let lazyImgs = document.querySelectorAll("[lazy-out]");
      let obj = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            load(entry.target);
          }
        });
      });

      lazyImgs.forEach((img) => {
        obj.observe(img);
      });
    }
  }

  // function ready() {
  //   lazy();
  // }

  // document.addEventListener("DOMContentLoaded", ready);
  useEffect(() => {
    lazy();
  }, []);


  return (
    <>
      <div className="main-setion">
        <div className="container">
          <div className="table-makeour">
            <div className="columns align-item">
              <div className="colum w-60">
                <div className="content-makeour">
                  <h3 className="title-makeour">
                    BSCS makes our world go
                    round.
                  </h3>
                  <p className="desc">
                    BSCS token is at the heart of the BSCS ecosystem. Buy
                    it, win it, farm it, spend it, stake it... heck, you can
                    even vote with it!
                  </p>
                  {/* <div className="res-button">
                    <button
                      type="button"
                      className="btn btn-upcoming btn-mar"
                      onClick={showModal}
                    >
                      Buy BSCS
                    </button>

                    <button
                      type="button"
                      className="btn-getstarted mar-l-20"
                      onClick={() => gotoIDOPool()}
                    >
                      Get Started &gt; &gt;
                    </button>
                  </div> */}
                  <ul className="list-make">
                    <li>
                      <div className="text-top">Total supply</div>
                      <div className="text-bottom">
                        {state.marketInfo?.total_supply}
                      </div>
                    </li>
                    <li>
                      <div className="text-top">Burned to date</div>
                      <div className="text-bottom">
                        {state.marketInfo?.burnedToDate}
                      </div>
                    </li>
                    <li>
                      <div className="text-top">Circulating Supply</div>
                      <div className="text-bottom">
                        {state.marketInfo?.circulating_supply}
                      </div>
                    </li>
                  </ul>
                  {/* {addScript("https://crypto.com/price/static/widget/index.js")}
                  <div
                    id="crypto-widget-CoinList"
                    data-design="classic"
                    data-coin-ids="4210"
                    className="custom-widget"
                    // lazy-script=""
                  ></div> */}
                </div>
              </div>
              <div className="colum w-40">
                <div className="box-img">
                  <img lazy-out="/images/home/banner2.svg" src="" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* @ts-ignore this lib is incompatible with react18 */}
      <Modal
        title="Buy BSCS"
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        className="modal-link"
        width={650}
      >
        <div className="link-buy-bscs" onClick={() => openLink(1)}>
          {/* <img className="img-link" alt="" src="/images/coins/BSCS.png?v=152" /> */}
          <p> 🥞Pancakeswap.finance </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(2)}>
          <img className="img-link" alt="" src="/images/coins/gate.png?v=152" />
          <p>
            SCS BSCS Price|Live Chart|Crypto Exchange Platform-Spot
            Trading-Gate.io
          </p>
        </div>
        <div className="link-buy-bscs" onClick={() => openLink(3)}>
          <img className="img-link" alt="" src="/images/coins/mxc.png?v=152" />
          <p>
            MEXC | Bitcoin Trading Platform_Ethereum_Litecoin Price Real-time
            Quotes{" "}
          </p>
        </div>
      </Modal>
    </>
  );
};
export default MakesOur;
