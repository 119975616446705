/* eslint-disable react-hooks/exhaustive-deps */
import idoTokenClaimDailyAbi from "../pages/Ido/Abi/idoTokenClaimDaily.json";
import idoTokenClaimAbi from "../pages/Ido/Abi/idoTokenClaim.json";
import abiBoxMarket from "../pages/Ino/Abi/abiBoxMarket.json";
import abiOpenMyBox from "../pages/Ino/Abi/abiOpenMyBox.json";
import abiMyBox from "../pages/Ino/Abi/abiMyBox.json";
import NFT_ABI from "../constants/abis/nft.json";
import ERC20_ABI from "../constants/abis/erc20.json";
import NFTMARKET_ABI from "../constants/abis/nftmarket.json";
import abiSwap from "../abi/abiSwap.json";
import { CONTRACT_NFT, CONTRACT_BID, CONTRACT_BID_VERSION } from "../constants";

import { useMemo } from "react";
import { useAccount, Config, useConnectorClient, useChainId } from "wagmi";
import { getContract } from "../utils";

import { providers } from 'ethers'
import type { Account, Chain, Client, Transport } from 'viem'


export function clientToSigner(client: Client<Transport, Chain, Account>) {
    const { chain, transport }: any = client
    const network = {
        chainId: chain?.id,
        name: chain?.name,
        ensAddress: chain?.contracts?.ensRegistry?.address,
    }
  if(chain){
const provider = new providers.Web3Provider(transport, network)
    return provider 
  }else{
 return  undefined
  }

    
}

// returns null on errors
export function useContract(addresss: any, ABI: any, withSignerIfPossible = true) {
    const { address } = useAccount();
    const chainId = useChainId()
    let account = address
    const { data: client } = useConnectorClient<Config>({ chainId })
    let library: any = client ? clientToSigner(client) : undefined

    return useMemo(() => {
        if (!addresss || !ABI || !library) return null;
        try {
            return getContract(
                addresss,
                ABI,
                library,
                withSignerIfPossible && account ? account : undefined
            );
        } catch (error) {
            console.error("Failed to get contract", error);
            return null;
        }
    }, [addresss, ABI, library,chainId, withSignerIfPossible, account]);
}

export function useIdoTokenClaimContract(scAddr: any, item: any) {
    return useContract(
        scAddr,
        item && item.claimRound === 100 ? idoTokenClaimDailyAbi : idoTokenClaimAbi
    );
}

export function useBoxMarketContract(addresss: any) {
    return useContract(addresss, abiBoxMarket, true);
}

export function useOpenMyBoxContract(addresss: any) {
    return useContract(addresss, abiOpenMyBox, true);
}

export function useMyBoxContract(addresss: any) {
    return useContract(addresss, abiMyBox, true);
}
export function useNftContract() {
    return useContract(CONTRACT_NFT, NFT_ABI, true);
}
export function useTokenContract(tokenAddress: any, withSignerIfPossible: any) {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}
export function useTokenNFTContract(tokenAddress: any, withSignerIfPossible: any) {
    return useContract(tokenAddress, NFT_ABI, withSignerIfPossible);
}
export function UseNftMarketContractV2(ver: any) {
    return useContract(CONTRACT_BID_VERSION[ver ? 1 : 0], NFTMARKET_ABI, true);
}
export function useNftMarketContract(tokenAddress: any, withSignerIfPossible: any) {
    return useContract(CONTRACT_BID, NFTMARKET_ABI, true);
}
export function useSwapContract(addresss: any) {
    return useContract(addresss, abiSwap, true);
}
